import React from 'react';
import { Button } from '@components/shared/Button/Button.component';
import { Typography } from '@components/shared/Typography/Typography.component';
import { PRIMARY_TEXT_COLOR } from '@theme/theme';
import { useStyles } from './CartEmpty.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  onButtonClick: () => void;
};

export const CartEmptyView: React.FC<Props> = ({ onButtonClick }) => {
  const { classes } = useStyles();
  const { t } = Localizator.translate();

  return (
    <div className={classes.emptyCartWrapper}>
      <Typography
        fontSize={24}
        fontWeight={700}
        lineHeight="28px"
        color={PRIMARY_TEXT_COLOR}
      >
        {t('Cart.NoProducts')}
      </Typography>

      <div className={classes.buttonWrapper}>
        <Button onClick={onButtonClick}>{t('Cart.GoToCatalog')}</Button>
      </div>
    </div>
  );
};
