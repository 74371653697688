import { makeStyles } from 'tss-react/mui';
import {
  BLUE_HOVER_COLOR,
  GRAY_LIGHT_TYPE_6,
  PRIMARY_TEXT_COLOR,
} from '@theme/theme';

type Props = {
  isFullWidth?: boolean;
  titlePointer?: boolean;
  withImage?: boolean;
};

const FULL_IMAGE_WIDTH = 88;
const SHORT_IMAGE_WIDTH = 40;
const MOBILE_IMAGE_WIDTH = 56;

const FULL_TEXT_WIDTH = 676;
const SHORT_TEXT_WIDTH = 628;

export const useStyles = makeStyles<Props>()(
  // eslint-disable-next-line complexity
  (theme, { isFullWidth, titlePointer, withImage }) => ({
    productRowCardWrapper: {
      display: 'flex',
      paddingBottom: 16,
      marginBottom: 16,
      width: isFullWidth ? '100%' : 780,
      borderBottom: `1px solid ${GRAY_LIGHT_TYPE_6}`,

      '@media (max-width: 1279px)': {
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '100vw',
      },
    },

    mobileWidthImage: {
      '@media (max-width: 1279px)': {
        minWidth: MOBILE_IMAGE_WIDTH,
        minHeight: MOBILE_IMAGE_WIDTH,
        maxWidth: MOBILE_IMAGE_WIDTH,
        maxHeight: MOBILE_IMAGE_WIDTH,

        '& img': {
          minWidth: MOBILE_IMAGE_WIDTH,
          minHeight: MOBILE_IMAGE_WIDTH,
          maxWidth: MOBILE_IMAGE_WIDTH,
          maxHeight: MOBILE_IMAGE_WIDTH,
        },
      },
    },

    fullWidthImage: {
      minWidth: SHORT_IMAGE_WIDTH,
      minHeight: SHORT_IMAGE_WIDTH,
      maxWidth: SHORT_IMAGE_WIDTH,
      maxHeight: SHORT_IMAGE_WIDTH,

      '& img': {
        minWidth: SHORT_IMAGE_WIDTH,
        minHeight: SHORT_IMAGE_WIDTH,
        maxWidth: SHORT_IMAGE_WIDTH,
        maxHeight: SHORT_IMAGE_WIDTH,
      },
    },

    shortWidthImage: {
      minWidth: FULL_IMAGE_WIDTH,
      minHeight: FULL_IMAGE_WIDTH,
      maxWidth: FULL_IMAGE_WIDTH,
      maxHeight: FULL_IMAGE_WIDTH,

      '& img': {
        minWidth: FULL_IMAGE_WIDTH,
        minHeight: FULL_IMAGE_WIDTH,
        maxWidth: FULL_IMAGE_WIDTH,
        maxHeight: FULL_IMAGE_WIDTH,
      },
    },

    imageWrapper: {
      marginRight: 16,
      overflow: 'hidden',

      '& img': {
        display: 'block',
        objectFit: 'cover',
      },

      '&:hover': {
        cursor: withImage ? 'pointer' : 'default',
      },
    },

    rightSideBlock: {
      display: 'flex',
      flexDirection: isFullWidth ? 'row' : 'column',
      width: isFullWidth ? 'auto' : FULL_TEXT_WIDTH,
      flex: isFullWidth ? '0 1 100%' : 'auto',
      justifyContent: isFullWidth ? 'center' : 'flex-start',
      alignItems: 'flex-start',

      '@media (max-width: 1279px)': {
        flex: 'auto',
        width: 'auto',
        justifyContent: 'flex-start',
        // 72 = 56 (ширина карточки) + 16 (марджин справа)
        maxWidth: 'calc(100% - 72px)',
      },
    },

    nameAndArticleWrapper: {
      marginBottom: isFullWidth ? 0 : 9,
      width: isFullWidth ? SHORT_TEXT_WIDTH : '100%',
      minWidth: SHORT_TEXT_WIDTH,
      maxWidth: isFullWidth ? SHORT_TEXT_WIDTH : 'unset',
      marginRight: 0,
      height: isFullWidth ? 40 : 'auto',

      '& > p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: isFullWidth ? SHORT_TEXT_WIDTH : FULL_TEXT_WIDTH,
      },

      '@media (max-width: 1279px)': {
        width: '100%',
        minWidth: 'auto',
        maxWidth: '100%',

        '& > p': {
          maxWidth: '100%',
        },
      },
    },

    nameWrapper: {
      marginBottom: 4,
      transition: '0.25s all',
      display: 'inline-block',

      '& > p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: isFullWidth ? SHORT_TEXT_WIDTH : FULL_TEXT_WIDTH,
      },

      '&:hover': {
        '& > p': {
          color: isFullWidth ? BLUE_HOVER_COLOR : PRIMARY_TEXT_COLOR,
          cursor: titlePointer ? 'pointer' : 'default',
        },
      },

      '@media (max-width: 1279px)': {
        maxWidth: '100%',
        display: 'block',

        '& > p': {
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          lineClamp: '2',
          WebkitBoxOrient: 'vertical',
          maxHeight: 40,
          whiteSpace: 'pre-wrap',
        },
      },
    },

    articleAndCode: {
      width: 'max-content',
      maxWidth: '100%',

      '& > p': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  }),
);
