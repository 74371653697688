import { makeStyles } from 'tss-react/mui';
import { PRIMARY_BORDER_COLOR } from '@theme/theme';

type Props = {
  showDeleteButton?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { showDeleteButton }) => ({
  wrapper: {
    maxWidth: 176,
  },

  inputAndButtons: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    boxShadow: `inset 0 0 0 1pt ${PRIMARY_BORDER_COLOR}`,
    borderRadius: '8px',
    width: 144,
  },

  remains: {
    textAlign: 'center',
    marginTop: 4,
    paddingLeft: showDeleteButton ? 34 : 0,

    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },

  deleteAndInputAndButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
