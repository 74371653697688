import { useMemo } from 'react';
import { TUseCallbacks } from './SearchHistory.types';

export const useCallbacks = ({ searchHistory }: TUseCallbacks) => {
  const reversed = useMemo(() => {
    return [...searchHistory].reverse();
  }, [searchHistory]);

  return { reversed };
};
