import { useCallback, useState } from 'react';
import { TUseHandleBlur, TUseHandleFocus } from './ConfirmFormItem.types';

export const useCallbacks = () => {
  const [isFocused, setIsFocused] = useState(false);

  const { handleFocus } = useHandleFocus({ setIsFocused });
  const { handleBlur } = useHandleBlur({ setIsFocused });

  return {
    isFocused,
    handleFocus,
    handleBlur,
  };
};

const useHandleFocus = ({ setIsFocused }: TUseHandleFocus) => {
  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  return { handleFocus };
};

const useHandleBlur = ({ setIsFocused }: TUseHandleBlur) => {
  const handleBlur = useCallback(
    (onBlur: () => void) => {
      setIsFocused(false);
      onBlur();
    },
    [setIsFocused],
  );

  return { handleBlur };
};
