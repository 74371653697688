import React from 'react';
import { useStyles } from './MinusButton.style';
import { ReactComponent as MinusIcon } from './../assets/minus-icon.svg';

type Props = {
  onClick: () => void;
  testId?: string;
};

export const MinusButton: React.FC<Props> = React.memo(
  ({ onClick, testId = '' }) => {
    const { classes } = useStyles();

    return (
      <button
        data-testid={`${testId}-minus-button`}
        onClick={onClick}
        className={classes.wrapper}
      >
        <MinusIcon className={classes.icon} />
      </button>
    );
  },
);
