import React from 'react';
import { Container } from '@components/Container/Container.component';
import { useIsMobile } from '@hooks/useIsMobile';
import { CartButton } from './CartButton/CartButton.component';
import { CartCheckoutData } from './CartCheckoutData/CartCheckoutData.component';
import { CartTotal } from './CartTotal/CartTotal.component';
import { CartWarning } from './CartWarning/CartWarning.component';
import { useStyles } from './index.style';

type Props = {
  totalPrice: string;
  positionsCount: number;
  goodsCount: number;
  handleButtonClick: () => void;
  isLoading: boolean;
};

export const CartCheckout: React.FC<Props> = ({
  totalPrice,
  goodsCount,
  positionsCount,
  handleButtonClick,
  isLoading,
}) => {
  const { isMobile } = useIsMobile();

  const { classes } = useStyles();

  return (
    <div className={classes.checkout}>
      <Container onlyMobile className={isMobile ? 'newBootstrap' : ''}>
        <div className={classes.checkoutResultWrapper}>
          <div className={classes.checkoutResult}>
            <div>
              <CartTotal price={totalPrice} />
              <CartCheckoutData
                goodsCount={goodsCount}
                positionsCount={positionsCount}
              />
            </div>
            <div>
              <CartButton
                handleClick={handleButtonClick}
                isLoading={isLoading}
              />
            </div>
            {!isMobile && <CartWarning />}
          </div>
        </div>
      </Container>
    </div>
  );
};
