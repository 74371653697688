import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'block',
    minWidth: 32,
    maxWidth: 32,
    minHeight: 32,
    maxHeight: 32,
    cursor: 'pointer',
  },

  icon: {
    '&:hover': {
      '& path': {
        stroke: '#091739',
      },

      '&:active': {
        '& path': {
          stroke: '#D1D6DF',
        },
      },
    },
  },
}));
