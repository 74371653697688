import { useState } from 'react';
import { TUseHandleOnMouseDown, TUseHandleOnMouseUp } from './AddButton.types';

export const useAddButton = () => {
  const [isClicked, setIsClicked] = useState(false);

  const { handleOnMouseDown } = useHandleOnMouseDown({ setIsClicked });
  const { handleOnMouseUp } = useHandleOnMouseUp({ setIsClicked });

  return {
    isClicked,
    handleOnMouseDown,
    handleOnMouseUp,
  };
};

export const useHandleOnMouseDown = ({
  setIsClicked,
}: TUseHandleOnMouseDown) => {
  const handleOnMouseDown = () => {
    setIsClicked(true);
  };

  return { handleOnMouseDown };
};

export const useHandleOnMouseUp = ({ setIsClicked }: TUseHandleOnMouseUp) => {
  const handleOnMouseUp = () => {
    setIsClicked(false);
  };

  return { handleOnMouseUp };
};
