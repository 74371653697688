import { Skeleton } from '@mui/material';
import React from 'react';
import { TProduct } from '@apptypes/index';
import { useStyles } from './ImageGallery.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  imageIsLoading?: boolean;
  imageUrl?: string;
  product: TProduct;
  isFullWidth?: boolean;
  useImageGallery?: boolean;
  onGalleryClick?: () => void;
  testId?: string;
};

export const ImageGallery: React.FC<Props> = ({
  imageUrl,
  imageIsLoading,
  isFullWidth,
  product,
  useImageGallery,
  onGalleryClick,
  testId = '',
}) => {
  const { classes } = useStyles({ isFullWidth });
  const { t } = Localizator.translate();

  return (
    <>
      {!imageIsLoading && imageUrl && !product.isKit && (
        <img
          data-testid={`${testId}-productRowCard-image`}
          src={imageUrl}
          alt={t('Other.ProductImageAlt')}
          onClick={useImageGallery ? onGalleryClick : undefined}
        />
      )}
      {imageIsLoading && (
        <div data-testid={`${testId}-productRowCard-skeleton`}>
          <Skeleton variant="rectangular" width={88} height={88} />
        </div>
      )}
      {!imageIsLoading && (!imageUrl || product.isKit) && (
        <div
          data-testid={`${testId}-productRowCard-imageplaceholder`}
          className={classes.imagePlaceholder}
        />
      )}
    </>
  );
};
