import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  productGroupNameWrapper: {
    marginBottom: 16,
    display: 'inline-block',
  },

  image: {
    '@media (max-width: 1279px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
