import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { useCreateOrder } from '@hooks/useApi.hooks';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { TAppEvents, TAppState } from '@store/index';
import { SET_CART_USER_INFO_RESET } from '@store/cartUserInfo.store';
import { generateSchema } from './Confirm.schema';
import { TUseGetParsedError, TUseOnSubmit } from './Confirm.types';
import { getDataForCreateOrder } from './ConfirmForm/ConfirmForm.utils';
import { Localizator } from '@utils/Localizator';
import { CLEAR_CART } from '@store/cart.store';

export const useConfirm = () => {
  const form = useGetForm();
  const { getValues, handleSubmit } = form;
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const navigate = useNavigate();

  const query = useCreateOrder({
    getDataForCreateOrder: () => getDataForCreateOrder(getValues),
    onSuccess: (response) => {
      dispatch(CLEAR_CART, undefined);

      navigate('../success', {
        state: {
          name: response?.name,
          sum: response?.sum,
        },
      });
    },
  });

  const { mutate, error } = query;

  const { onSubmit } = useOnSubmit({ reqFn: mutate, handleSubmit });

  const { breadcrumbs } = useBreadcrumbs();

  const { message } = useGetParsedError({ error });

  return { breadcrumbs, form, onSubmit, message, ...query };
};

const useBreadcrumbs = () => {
  const { root } = useLinkPath();
  const { t } = Localizator.translate();

  const generateBreadcrumbs = useCallback(() => {
    const result: { link: string; label: string }[] = [];

    result.push({
      label: t('Confirmation.Breadcrumbs.AllProducts'),
      link: `${root}/catalog` ?? '',
    });

    result.push({
      label: t('Confirmation.Breadcrumbs.Cart'),
      link: `${root}/cart`,
    });

    result.push({
      label: t('Confirmation.Breadcrumbs.PlaceOrder'),
      link: '',
    });

    return result;
  }, [root, t]);

  const breadcrumbs = useMemo(() => {
    return generateBreadcrumbs();
  }, [generateBreadcrumbs]);

  return { breadcrumbs };
};

export const useGetForm = () => {
  const { isPublicLink } = useLinkPath();

  const { comment, companyName, phone, locale } = useStoreon<
    TAppState,
    TAppEvents
  >('comment', 'phone', 'companyName', 'locale');

  const form = useForm({
    mode: 'onSubmit',
    criteriaMode: 'all',
    resolver: yupResolver(generateSchema({ isPublicLink, locale })),
    shouldFocusError: false,
    defaultValues: {
      phone: phone ?? '',
      companyName: companyName ?? '',
      comment: comment ?? '',
    },
  });

  return { ...form };
};

const useOnSubmit = ({ reqFn, handleSubmit }: TUseOnSubmit) => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      // Отключаем обновление страницы при сабмите формы
      e.preventDefault();
      handleSubmit(() => {
        reqFn();
        dispatch(SET_CART_USER_INFO_RESET);
      })(e);
    },
    [reqFn, handleSubmit, dispatch],
  );

  return { onSubmit };
};

const useGetParsedError = ({ error }: TUseGetParsedError) => {
  const { t } = Localizator.translate();

  const preparedError =
    (error as string) ??
    JSON.stringify({
      errorCode: 500,
      name: t('Errors.CommonErrorName'),
      description: t('Errors.CommonErrorDescription'),
    });

  const parsedError = JSON.parse(preparedError) as {
    errorCode: string;
    name: string;
    description: string;
  };

  const message = navigator.onLine
    ? `${parsedError.name}. ${parsedError.description}.`
    : t('Errors.CheckYourInternetConnection');

  return { message };
};
