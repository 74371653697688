import { makeStyles } from 'tss-react/mui';
import {
  BLUE_DEFAULT,
  WHITE_DEFAULT,
  WHITE_TYPE_1,
} from '../../../../theme/theme';

type Props = {
  isVisible?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isVisible }) => ({
  button: {
    background: WHITE_DEFAULT,
    border: `2px solid ${BLUE_DEFAULT}`,
    borderRadius: '8px',
    padding: '11px 18px',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 16,
    userSelect: 'none',
    zIndex: 99999999,
    transition: '0.25s all',
    width: 133,
    minWidth: 133,
    maxWidth: 133,
    height: 44,
    display: isVisible ? 'flex' : 'none',

    '&:hover': {
      background: WHITE_TYPE_1,
    },

    '@media (max-width: 1279px)': {
      display: 'flex',
      height: 'auto',
      width: 'auto',
      maxWidth: 'unset',
      minWidth: 'unset',
      padding: 0,
      marginLeft: 0,
      border: 'none',
      order: '1',
      visibility: isVisible ? 'visible' : 'hidden',

      '&:hover': {
        background: WHITE_DEFAULT,
      },
    },
  },

  imgWrapper: {
    width: 24,
    height: 24,

    '@media (max-width: 1279px)': {
      display: 'flex',
      justifyContent: 'center',

      '& img': {
        objectFit: 'none',
      },
    },
  },

  icon: {
    fill: BLUE_DEFAULT,

    '@media (max-width: 1279px)': {
      verticalAlign: 'baseline',
    },
  },

  label: {
    marginLeft: 8,
    color: BLUE_DEFAULT,
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',

    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },
}));
