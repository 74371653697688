import React from 'react';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from '../Categories.component';
import { useStyles } from './CategoriesMenuContent.style';
import { AllGoodMenuItemView } from './MenuItems/AllGoodMenuItem.view';
import { CurrentGoodMenuItemView } from './MenuItems/CurrentGoodMenuItem.view';
import { MenuItemView } from './MenuItems/MenuItem.view';

type Props = {
  listWrapperRef: React.RefObject<HTMLDivElement>;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
};

export const CategoriesMenuContentView: React.FC<Props> = ({
  listWrapperRef,
  onMenuItemClick,
  currentCategory,
  categories,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <div ref={listWrapperRef} className={classes.listWrapper}>
        <CurrentGoodMenuItemView
          onMenuItemClick={onMenuItemClick}
          currentCategory={currentCategory}
        />

        {currentCategory && (
          <AllGoodMenuItemView
            onMenuItemClick={onMenuItemClick}
            currentCategory={currentCategory}
          />
        )}

        {categories?.map((category, index) => {
          const currentId = currentCategory?.id || '';

          const correspondsCurrentCategory = currentId === category.parentId;

          if (
            correspondsCurrentCategory &&
            category.id !== '00000000-0000-0000-0000-000000000000'
          ) {
            return (
              <MenuItemView
                onMenuItemClick={onMenuItemClick}
                category={category}
                key={index}
              />
            );
          }

          return null;
        })}
      </div>
    </>
  );
};
