import { Localizator } from '@utils/Localizator';
import React, { FC } from 'react';
import { Label } from '../../../../components/shared/Label/Label.component';
import { useStyles } from './CartWarning.style';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

export const CartWarning: FC = () => {
  const { classes } = useStyles();
  const { locale } = useStoreon<TAppState, TAppEvents>('locale');

  const { t } = Localizator.translate();

  return (
    <div className={classes.cartWarningWrapper}>
      {locale !== 'ru-RU' && (
        <div className={classes.gst}>
          <Label fontSize={12} lineHeight="16px">
            {t('Cart.GST')}
          </Label>
        </div>
      )}

      <Label fontSize={12} lineHeight="16px">
        {t('Cart.StockAdvice')}
      </Label>
    </div>
  );
};
