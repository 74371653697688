import React, { FC } from 'react';
import { Button } from '@components/shared/Button/Button.component';
import { useStyles } from './CartButton.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  isLoading?: boolean;
  handleClick: () => void;
};

export const CartButton: FC<Props> = (props) => {
  const { isLoading, handleClick } = props;
  const { t } = Localizator.translate();

  const { classes } = useStyles();

  return (
    <div className={classes.cartButtonWrapper}>
      <Button isLoading={isLoading} onClick={handleClick}>
        {t('Cart.CheckoutButton')}
      </Button>
    </div>
  );
};
