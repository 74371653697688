import { Menu } from '@mui/material';
import React from 'react';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from '../../Categories.component';
import { CategoriesMenuContent } from '../../CategoriesMenuItems/CategoriesMenuContent.component';
import { useStyles } from './CategoriesDesktopContent.style';

type Props = {
  open: boolean;
  handleCloseMenu: () => void;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  isLoading: boolean;
  isError: boolean;
  anchorEl: HTMLElement | null;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
  listWrapperRef: React.RefObject<HTMLDivElement>;
};

export const CategoriesDesktopContent: React.FC<Props> = ({
  open,
  isLoading,
  isError,
  anchorEl,
  handleCloseMenu,
  listWrapperRef,
  onMenuItemClick,
  currentCategory,
  categories,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Menu
      className={cx(classes.menu, {
        [classes.menuLoading]: isLoading,
        [classes.menuWithError]: isError,
      })}
      disableAutoFocusItem
      disablePortal
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
    >
      <CategoriesMenuContent
        isError={isError}
        isLoading={isLoading}
        listWrapperRef={listWrapperRef}
        onMenuItemClick={onMenuItemClick}
        currentCategory={currentCategory}
        categories={categories}
      />
    </Menu>
  );
};
