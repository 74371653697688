import React, { HTMLAttributes } from 'react';
import { PRIMARY_ERROR_COLOR, SECONDARY_TEXT_COLOR } from '@theme/theme';
import { Label } from '../Label/Label.component';
import { useStyles } from './TextInput.style';

interface Props extends HTMLAttributes<HTMLInputElement> {
  label?: {
    id: string;
    text: string;
    required?: boolean;
  };
  bottomText?: {
    error?: boolean;
    text?: string;
  };
  className?: string;
  testId?: string;
}

// eslint-disable-next-line react/no-multi-comp
export const TextInput = React.memo(
  React.forwardRef<HTMLInputElement, Props>(
    ({ label, bottomText, className, testId = '', ...rest }, ref) => {
      const { onBlur, onFocus } = { ...rest };

      const { classes, cx } = useStyles({
        withError: Boolean(bottomText?.error),
      });

      return (
        <div className={classes.wrapper}>
          {label && (
            <label
              data-testid={`${testId}-text-input-label`}
              className={classes.label}
            >
              <span data-testid={`${testId}-text-input-label-text`}>
                {label.text}
              </span>
              {label.required && (
                <span
                  data-testid={`${testId}-text-input-label-asterisk`}
                  className={classes.asterisk}
                >
                  *
                </span>
              )}
            </label>
          )}

          <input
            data-testid={`${testId}-text-input`}
            className={cx(classes.input, className)}
            id={label ? label.id : undefined}
            ref={ref}
            {...rest}
            onBlur={onBlur}
            onFocus={onFocus}
          />

          {Boolean(bottomText?.text) && (
            <div className={classes.errorWrapper}>
              <Label
                fontSize={12}
                fontWeight={400}
                lineHeight="16px"
                color={
                  bottomText?.error ? PRIMARY_ERROR_COLOR : SECONDARY_TEXT_COLOR
                }
                testId={testId}
              >
                <span data-testid={`${testId}-text-input-bottom-text`}>
                  {bottomText?.text}
                </span>
              </Label>
            </div>
          )}
        </div>
      );
    },
  ),
);
