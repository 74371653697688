import { TProduct } from '@apptypes/index';
import { Label } from '@components/shared/Label/Label.component';
import React from 'react';

type Props = {
  product: TProduct;
};

export const ProductCardCode: React.FC<Props> = ({ product }) => {
  return (
    <Label>
      <span data-testid="product-card-page-code">{product?.code}</span>
    </Label>
  );
};
