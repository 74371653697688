import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { useCallbacks } from './Search.hooks';
import { SearchView } from './Search.view';
import { useHandleCloseProductCardPage } from '@hooks/useHandleCloseProductCardPage';

type Props = {
  findByCallback?: () => void;
  refetchOnSearchCallback?: () => void;
};

export const Search: FC<Props> = (props) => {
  const { findByCallback, refetchOnSearchCallback } = props;

  const [searchParams] = useSearchParams();

  const searchBy = searchParams.get('searchBy');

  const { allCategories, searchHistory } = useStoreon<TAppState, TAppEvents>(
    'allCategories',
    'searchHistory',
  );

  const { handleCloseProductCardPage } = useHandleCloseProductCardPage();

  const {
    isSearchHistoryOpened,
    withSearchIcon,

    isModalSearchOpened,
    setIsModalSearchOpened,

    inputValue,
    setInputValue,

    inputRef,

    isInputFocused,
    isInputHovered,

    handleBlur,
    handleFocus,
    handleInputChange,
    handleSearch,
    handleEnterPress,
    handleClear,
    handleMouseOver,
    handleMouseOut,
    handleSearchItemClick,
    handleSearchItemIconClick,
    handleClick,
    handleClose,
  } = useCallbacks({ refetchOnSearchCallback, findByCallback });

  useEffect(() => {
    setInputValue(searchBy ?? '');
  }, [searchBy, setInputValue]);

  return (
    <SearchView
      handleCloseProductCardPage={handleCloseProductCardPage}
      inputValue={inputValue}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleInputChange={handleInputChange}
      handleEnterPress={handleEnterPress}
      handleMouseOver={handleMouseOver}
      handleMouseOut={handleMouseOut}
      handleSearchItemIconClick={handleSearchItemIconClick}
      handleSearchItemClick={handleSearchItemClick}
      handleClear={handleClear}
      handleSearch={handleSearch}
      inputRef={inputRef}
      isSearchHistoryOpened={isSearchHistoryOpened}
      searchHistory={searchHistory}
      isInputFocused={isInputFocused}
      isInputHovered={isInputHovered}
      categoriesCount={allCategories.length}
      withSearchIcon={withSearchIcon}
      isModalSearchOpened={isModalSearchOpened}
      handleClick={handleClick}
      handleClose={handleClose}
      setIsModalSearchOpened={setIsModalSearchOpened}
    />
  );
};
