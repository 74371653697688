import React, { useMemo, useState } from 'react';
import { RegexCollectionMethods } from '../../../utils/RegexCollectionMethods';
import {
  TUseCallbacks,
  TUseHandleBlur,
  TUseHandleFocus,
  TUseShowFullControls,
} from './NumberInput.types';

export const useCallbacks = ({ onBlur, onFocus, value }: TUseCallbacks) => {
  const [isFocused, setIsFocused] = useState(false);

  const { handleBlur } = useHandleBlur({ setIsFocused, onBlur });
  const { handleFocus } = useHandleFocus({ setIsFocused, onFocus });

  const { showFullControls } = useShowFullControls({ value });

  return {
    handleBlur,
    handleFocus,
    showFullControls,
    isFocused,
  };
};

const useHandleBlur = ({ onBlur, setIsFocused }: TUseHandleBlur) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    onBlur && onBlur(e);
    setIsFocused(false);
  };

  return { handleBlur };
};

const useHandleFocus = ({ onFocus, setIsFocused }: TUseHandleFocus) => {
  const handleFocus = () => {
    onFocus && onFocus();
    setIsFocused(true);
  };

  return { handleFocus };
};

const useShowFullControls = ({ value }: TUseShowFullControls) => {
  const showFullControls = useMemo(() => {
    if (value) {
      const parsed = RegexCollectionMethods.replaceAllCommasForDots(value);

      return !isNaN(parseFloat(parsed)) && parseFloat(parsed) > 0;
    }

    return false;
  }, [value]);

  return { showFullControls };
};
