import { makeStyles } from 'tss-react/mui';
import { PRIMARY_TEXT_COLOR } from '@theme/theme';

type Props = {
  fontWeight?: number;
  color?: string;
  fontSize?: number;
  lineHeight?: string;
};

export const useStyles = makeStyles<Props>()(
  (
    theme,
    {
      fontSize = 24,
      fontWeight = 400,
      color = PRIMARY_TEXT_COLOR,
      lineHeight = '24px',
    },
  ) => ({
    text: {
      lineHeight,
      color,
      fontSize,
      fontFamily: 'ALSHauss,sans-serif',
      fontWeight,
      fontStyle: 'normal',
      fontFeatureSettings: '"tnum" on, "lnum" on',
      width: '100%',
      wordWrap: 'break-word',
    },
  }),
);
