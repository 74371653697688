import { useMemo } from 'react';
import { TProduct } from '@apptypes/index';

type TUseShowRemainsData = {
  withRemains: boolean;
  preparedProduct: TProduct | undefined;
  inputValue: string;
};

export const useShowRemainsData = ({
  withRemains,
  preparedProduct,
  inputValue,
}: TUseShowRemainsData) => {
  const showRemainsData = useMemo(() => {
    const floated = parseFloat(inputValue);
    const needShowRemains = Boolean(withRemains && inputValue);

    const isInputValueGreaterThanStock =
      preparedProduct !== null &&
      typeof preparedProduct?.stock === 'number' &&
      !isNaN(floated) &&
      floated >= preparedProduct?.stock;

    return needShowRemains && isInputValueGreaterThanStock;
  }, [inputValue, preparedProduct, withRemains]);

  return { showRemainsData };
};
