import { makeStyles } from 'tss-react/mui';
import { SECONDARY_TEXT_COLOR } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  catalogWrapper: {
    '& .header': {
      border: 'none',

      '@media (max-width: 1279px)': {
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 75,
          width: '100%',
          height: '1px',
          background: '#e7e8ec',
          zIndex: 1,
        },
      },
    },
  },

  pageResult: {
    color: SECONDARY_TEXT_COLOR,
    lineHeight: '20px',
    marginBottom: 26,
    marginTop: 10,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'ALSHauss,sans-serif',
  },

  catalog: {
    paddingBottom: 20,
  },

  empty: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,

    '& p': {
      textAlign: 'center',
    },
  },
}));
