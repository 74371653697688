import { makeStyles } from 'tss-react/mui';
import { BLUE_HOVER_COLOR, BLUE_ONCLICK_COLOR } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    height: 40,
    width: 20,
    position: 'relative',
    cursor: 'pointer',
    marginRight: 12,
    userSelect: 'none',

    '&:hover': {
      '& path': {
        transition: '0.25s all',
        stroke: BLUE_HOVER_COLOR,
      },
    },
    '&:active': {
      '& path': {
        stroke: BLUE_ONCLICK_COLOR,
      },
    },
  },

  icon: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
