import React from 'react';
import { useStyles } from './Back.style';

type Props = {
  onClick: () => void;
};

export const BackView: React.FC<Props> = ({ onClick }) => {
  const { classes } = useStyles();

  return (
    <div onClick={onClick} className={classes.wrapper}>
      <button
        data-testid="header-nav-button-left_arrow"
        className={classes.button}
      />
    </div>
  );
};
