import React from 'react';
import { Portal } from 'react-portal';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from '../../Categories.component';
import { CategoriesCatalogButton } from '../../CategoriesCatalogButton/CategoriesCatalogButton.component';
import { CategoriesDesktopContent } from '../../CategoriesContent/CategoriesDesktopContent/CategoriesDesktopContent.component';
import { useStyles } from './CategoriesInnerViewDesktop.style';

type Props = {
  open: boolean;
  handleOpenMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleCloseMenu: () => void;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  isLoading: boolean;
  isError: boolean;
  anchorEl: HTMLElement | null;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
  listWrapperRef: React.RefObject<HTMLDivElement>;
};

export const CategoriesInnerViewDesktop: React.FC<Props> = ({
  open,
  handleOpenMenu,
  handleCloseMenu,
  isLoading,
  isError,
  anchorEl,
  listWrapperRef,
  onMenuItemClick,
  currentCategory,
  categories,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {/* Прозрачность по дизайну */}
      <Portal>{open && <div className={classes.opacityBackground} />}</Portal>

      <CategoriesCatalogButton handleOpenMenu={handleOpenMenu} open={open} />

      <CategoriesDesktopContent
        open={open}
        isLoading={isLoading}
        isError={isError}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        listWrapperRef={listWrapperRef}
        onMenuItemClick={onMenuItemClick}
        currentCategory={currentCategory}
        categories={categories}
      />
    </>
  );
};
