import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { useHandleCloseProductCardPage } from '@hooks/useHandleCloseProductCardPage';
import { useIsMobile } from '@hooks/useIsMobile';
import { TAppEvents, TAppState } from '@store/index';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from './Categories.component';
import {
  TUseCallbacks,
  TUseHandleAllProductsClick,
  TUseHandleCategoryWithChildrenClick,
  TUseHandleCategoryWithoutChildrenClick,
  TUseHandleCloseMenu,
  TUseHandleNavigateBackClick,
  TUseHandleOpenMenu,
  TUseOnMenuItemClick,
} from './Categories.types';
import { getAllChildCategoriesByParentId } from './Categories.utils';

export const useCallbacks = ({ setAnchorEl, open }: TUseCallbacks) => {
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');
  const queryClient = useQueryClient();
  const { isMobile } = useIsMobile();

  const listWrapperRef = useRef<HTMLDivElement>(null);

  const [sortedCategories, setSortedCategories] = useState<TProductFolder[]>(
    [],
  );
  const [currentCategory, setCurrentCateogry] = useState<
    TProductFolder | null | undefined
  >(null);

  const categories =
    currentCategory && sortedCategories.length > 0
      ? sortedCategories
      : allCategories;

  const { handleOpenMenu } = useHandleOpenMenu({ setAnchorEl, open });
  const { handleCloseMenu } = useHandleCloseMenu({ setAnchorEl });

  const { onMenuItemClick } = useOnMenuItemClick({
    setAnchorEl,
    setCurrentCateogry,
    setSortedCategories,
    listWrapperRef,
  });

  const categoriesQueryState = queryClient.getQueryState('categories');
  const error = Boolean(categoriesQueryState?.error);
  const isLoading = categoriesQueryState?.isFetching;

  useEffect(() => {
    if (!open) {
      // Таймаут нужен для незаметного подмены категорий
      setTimeout(() => {
        setCurrentCateogry(null);
        setSortedCategories([]);
      }, 250);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      setCurrentCateogry(null);
      setSortedCategories([]);
      setAnchorEl(null);
    };
  }, [setAnchorEl]);

  useEffect(() => {
    handleCloseMenu();
  }, [handleCloseMenu, isMobile]);

  return {
    open,
    handleOpenMenu,
    handleCloseMenu,
    onMenuItemClick,
    isLoading: Boolean(isLoading),
    error,
    currentCategory,
    categories,
    listWrapperRef,
  };
};

const useHandleOpenMenu = ({ setAnchorEl, open }: TUseHandleOpenMenu) => {
  const { handleCloseProductCardPage } = useHandleCloseProductCardPage();

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      handleCloseProductCardPage();

      if (open) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [open, handleCloseProductCardPage, setAnchorEl],
  );

  return { handleOpenMenu };
};

const useHandleCloseMenu = ({ setAnchorEl }: TUseHandleCloseMenu) => {
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return { handleCloseMenu };
};

const useHandleCategoryWithChildrenClick = ({
  setSortedCategories,
  setCurrentCateogry,
}: TUseHandleCategoryWithChildrenClick) => {
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const handleCategoryWithChildrenClick = useCallback(
    (item: TMenuItem) => {
      const sortedCategories = getAllChildCategoriesByParentId(
        allCategories,
        item.id,
      );

      setSortedCategories(sortedCategories);

      const category = allCategories.find((cat) => cat.id === item.id);

      setCurrentCateogry(category);
    },
    [allCategories, setCurrentCateogry, setSortedCategories],
  );

  return { handleCategoryWithChildrenClick };
};

const useHandleCategoryWithoutChildrenClick = ({
  setAnchorEl,
  setCurrentCateogry,
}: TUseHandleCategoryWithoutChildrenClick) => {
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const { handleCloseMenu } = useHandleCloseMenu({ setAnchorEl });

  const handleCategoryWithoutChildrenClick = useCallback(
    (item?: TMenuItem | null) => {
      const category = allCategories.find((cat) => cat.id === item?.id);

      if (category?.id && category?.name) {
        searchParams.set('categoryId', category?.id);
      }

      queryClient.removeQueries('price-list');
      queryClient.invalidateQueries('price-list');

      searchParams.delete('searchBy');

      setCurrentCateogry(null);

      setSearchParams(searchParams);

      handleCloseMenu();
    },
    [
      allCategories,
      handleCloseMenu,
      searchParams,
      setSearchParams,
      setCurrentCateogry,
      queryClient,
    ],
  );

  return { handleCategoryWithoutChildrenClick };
};

export const useHandleAllProductsClick = ({
  setCurrentCateogry,
  setAnchorEl,
}: TUseHandleAllProductsClick) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleCloseMenu } = useHandleCloseMenu({ setAnchorEl });
  const queryClient = useQueryClient();

  const handleAllProductsClick = useCallback(() => {
    setCurrentCateogry(null);
    handleCloseMenu();

    queryClient.removeQueries('price-list');
    searchParams.delete('categoryId');
    searchParams.delete('searchBy');
    setSearchParams(searchParams);
  }, [
    handleCloseMenu,
    searchParams,
    setSearchParams,
    setCurrentCateogry,
    queryClient,
  ]);

  return { handleAllProductsClick };
};

const useHandleNavigateBackClick = ({
  setCurrentCateogry,
  setSortedCategories,
}: TUseHandleNavigateBackClick) => {
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const handleNavigateBackClick = useCallback(
    (item: TMenuItem) => {
      const sortedCategories = getAllChildCategoriesByParentId(
        allCategories,
        item?.id,
      );

      const category = allCategories.find((cat) => cat.id === item?.id);

      if (Boolean(category?.id)) {
        setCurrentCateogry(category);
      } else {
        setCurrentCateogry(null);
      }

      setSortedCategories(sortedCategories);
    },
    [allCategories, setCurrentCateogry, setSortedCategories],
  );

  return { handleNavigateBackClick };
};

const useOnMenuItemClick = ({
  listWrapperRef,
  setCurrentCateogry,
  setSortedCategories,
  setAnchorEl,
}: TUseOnMenuItemClick) => {
  const { isMobile } = useIsMobile();

  const { handleCategoryWithChildrenClick } =
    useHandleCategoryWithChildrenClick({
      setCurrentCateogry,
      setSortedCategories,
    });

  const { handleAllProductsClick } = useHandleAllProductsClick({
    setAnchorEl,
    setCurrentCateogry,
  });

  const { handleNavigateBackClick } = useHandleNavigateBackClick({
    setCurrentCateogry,
    setSortedCategories,
  });

  const { handleCategoryWithoutChildrenClick } =
    useHandleCategoryWithoutChildrenClick({
      setAnchorEl,
      setCurrentCateogry,
    });

  const onMenuItemClick = useCallback(
    (
      _: React.MouseEvent<HTMLLIElement, MouseEvent>,
      item?: TMenuItem | null,
    ) => {
      const scrollableUlList = listWrapperRef?.current
        ?.parentElement as HTMLUListElement;

      if (isMobile) {
        scrollableUlList?.scrollIntoView();
      } else {
        scrollableUlList?.scroll(0, 0);
      }

      scrollableUlList?.scrollIntoView();

      if (item?.isWithSubcategory) {
        // Нажали на какую-то категорию
        handleCategoryWithChildrenClick(item);
      } else {
        if (item?.toBack) {
          // Нажали на "Все товары"
          if (item.id === undefined) {
            handleAllProductsClick();
          } else {
            // Просто навигация назад по стрелочке
            handleNavigateBackClick(item);
          }
        } else {
          // Наконец выбрали конечную категорию
          handleCategoryWithoutChildrenClick(item);
        }
      }
    },
    [
      handleCategoryWithChildrenClick,
      handleAllProductsClick,
      handleNavigateBackClick,
      handleCategoryWithoutChildrenClick,
      listWrapperRef,
      isMobile,
    ],
  );

  return { onMenuItemClick };
};
