import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    minHeight: '100vh',
  },

  innerWrapper: {
    position: 'absolute',
    top: '33%',
    left: '50%',
    transform: 'translate(-50%, -33%)',
  },

  titleWrapper: {
    marginTop: 24,
  },

  titleDescription: {
    marginTop: 8,
  },

  buttonWrapper: {
    marginTop: 24,
  },

  centerBlock: {
    display: 'flex',
    justifyContent: 'center',
  },

  centerText: {
    textAlign: 'center',
  },

  button: {
    width: 'max-content',
  },
}));
