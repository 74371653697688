import { AxiosError } from 'axios';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import {
  TPostValidateStocks,
  TUseCreateOrder,
  TUseGetCategories,
  TUseGetCurrency,
  TUseGetFullImages,
  TUseGetLinkInfo,
  TUseGetLocale,
  TUseGetProduct,
  TUseGetProductCardCategoriesBreadcrumbs,
  TUseGetProducts,
  TUseSendLinkToProductCopyEventMutation,
} from '@apptypes/useApi.types';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { apiService } from '@api/index';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { TProduct, ValidateStocksProductsReqestObject, ValidateStocksProductsResponseObject } from '@apptypes/index';
import { useCallback } from 'react';
import ym from 'react-yandex-metrika';
import { getSymbolFromCurrency } from '@utils/CurrencySymbolMap';

export const useGetLinkInfo = (props: TUseGetLinkInfo) => {
  const { enabled, onError } = props;

  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: 'link-info',
    queryFn: async () => {
      const response = await apiService.getLinkInfo(isPublicLink, linkPath);

      return response;
    },
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (err) => {
      onError && onError(err);
    },
    retry: false,
    enabled,
  });

  return { query };
};

export const useGetProducts = (props: TUseGetProducts) => {
  const {
    enabled,
    getOffset,
    limit,
    searchString,
    onError,
    onSuccess,
    categoryId,
  } = props;

  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: [
      'products',
      searchString ? searchString : 'no-search-string',
      categoryId ? categoryId : 'no-category-id',
    ],
    queryFn: async () => {
      const offset = getOffset();

      const categoryName =
        allCategories.find((item) => item.id === categoryId)?.name ?? '';

      const response = await apiService.getPriceList(
        isPublicLink,
        linkPath,
        offset,
        limit,
        searchString,
        categoryName,
        categoryId ?? '',
      );

      return response;
    },
    onSuccess: (response) => {
      onSuccess && onSuccess(response);
    },
    onError: (err) => {
      onError && onError(err);
    },
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  });

  return { query };
};

export const useGetProduct = (props: TUseGetProduct) => {
  const { enabled, productId, onError, onSuccess } = props;

  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: ['product', productId],
    queryFn: async () => {
      if (productId) {
        const response = await apiService.getProduct(
          productId,
          isPublicLink,
          linkPath,
        );

        return response;
      }

      return null;
    },
    onSuccess: (response) => {
      onSuccess && response && onSuccess(response);
    },
    onError: (err) => {
      onError && onError(err);
    },
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  });

  return { query };
};

export const useGetCategories = (props: TUseGetCategories) => {
  const { enabled, onError, onSuccess } = props;

  const { linkPathPublic } = useParams();
  const { linkPathCounterparty } = useParams();

  const linkPath =
    linkPathPublic === undefined ? linkPathCounterparty : linkPathPublic;

  const isPublicLink = linkPathPublic !== undefined;

  const query = useQuery({
    queryKey: 'categories',
    queryFn: () => {
      return apiService.getCategories(isPublicLink, linkPath);
    },

    onError: (err) => {
      onError && onError(err);
    },

    onSuccess: (response) => {
      onSuccess && onSuccess(response);
    },

    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  });

  return { query };
};

export const useCreateOrder = (props: TUseCreateOrder) => {
  const { getDataForCreateOrder, onSuccess, onSettled } = props;

  const { isPublicLink, linkPath } = useLinkPath();

  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const request = useCallback(
    (
      products: TProduct[],
      phone: string,
      companyName: string,
      comment: string,
    ) =>
      apiService.createOrder(
        isPublicLink,
        linkPath ?? '',
        products,
        phone,
        companyName,
        comment,
      ),
    [isPublicLink, linkPath],
  );

  const mutation = useMutation({
    mutationKey: 'create-order',
    mutationFn: () => {
      const products: TProduct[] = [];

      cartItems.forEach((item) => {
        products.push(item);
      });

      const { phone, companyName, comment } = getDataForCreateOrder();

      return request(products, phone, companyName, comment);
    },
    onSuccess: (response) => {
      if (process.env.NODE_ENV === 'production') {
        ym('reachGoal', 'order_sent');
      }

      onSuccess && onSuccess(response);
    },
    onSettled: () => {
      onSettled && onSettled();
    },
  });

  return mutation;
};

export const usePostValidateStocks = (props: TPostValidateStocks) => {
  const { products, onSuccess, onError, onMutate } = props;

  const { isPublicLink, linkPath } = useLinkPath();

  const prepared: ValidateStocksProductsReqestObject = products.map(
    (product) => {
      return {
        productId: product.id,
        quantity: product.quantity,
      };
    },
  );

  const request = useCallback(
    () =>
      apiService.validateStocks({
        isPublicLink,
        link: linkPath,
        products: prepared,
      }),
    [isPublicLink, linkPath, prepared],
  );

  const mutation = useMutation({
    mutationKey: 'validate-stocks',
    mutationFn: () => request(),
    onMutate: () => {
      onMutate && onMutate();
    },
    onSuccess: (s) => {
      onSuccess();
    },
    onError: (err: AxiosError<ValidateStocksProductsResponseObject>) => {
      onError(err);
    },
  });

  return { mutation };
};

export const useGetFullImages = (props: TUseGetFullImages) => {
  enum EQueryKey {
    Key = 'get-full-images',
  }

  const { enabled, onError, onSuccess, id, product, staleTime, cacheTime } =
    props;

  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: [EQueryKey.Key, id, props.unique],
    queryFn: () => {
      return apiService.getFullImages(isPublicLink, linkPath, product);
    },
    enabled,
    onSuccess: (response) => {
      onSuccess && onSuccess(response);
    },
    onError: (err) => {
      onError && onError(err);
    },
    cacheTime: cacheTime || 0,
    staleTime: staleTime || 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { query };
};

export const useGetLocale = (props: TUseGetLocale) => {
  const { enabled, onSuccess, onError } = props;

  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: 'locale',
    queryFn: () => {
      return apiService.getLocale(isPublicLink, linkPath);
    },
    onSuccess: (resp) => {
      onSuccess && onSuccess(resp);
    },
    onError: (err) => {
      onError && onError(err);
    },
    enabled,
    refetchOnMount: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });

  return { query };
};

export const useGetProductCardCategoriesBreadcrumbs = (
  props: TUseGetProductCardCategoriesBreadcrumbs,
) => {
  const { enabled, onSuccess, onError, productId } = props;

  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: ['get-product-card-categories-breadcrumbs', productId],
    queryFn: () => {
      return apiService.getProductCardCategoriesBreadcrumbs(
        productId,
        isPublicLink,
        linkPath,
      );
    },
    onSuccess: (resp) => {
      onSuccess && onSuccess(resp);
    },
    onError: (err) => {
      onError && onError(err);
    },
    enabled: enabled && Boolean(productId),
    refetchOnMount: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });

  return { query };
};

export const useSendLinkToProductCopyEventMutation = ({
  isPublic,
  link,
  productId,
  onError,
}: TUseSendLinkToProductCopyEventMutation) => {
  const mutation = useMutation({
    mutationKey: ['sendLinkToProductCopyEventMutation', link, productId],
    mutationFn: () => {
      return apiService.sendLinkToCopyProductEvent(productId, isPublic, link);
    },
    onError,
    retry: 0,
  });

  return { mutation };
};

export const useGetCurrency = (props: TUseGetCurrency) => {
  const { enabled, onSuccess, onError } = props;
  const { isPublicLink, linkPath } = useLinkPath();

  const query = useQuery({
    queryKey: 'currency',
    queryFn: async () => {
      const response = await apiService.getDefaultCurrency(
        isPublicLink,
        linkPath,
      );

      return {
        ...response,
        symbol: getSymbolFromCurrency(response?.isoCode),
      };
    },
    onSuccess: (response) => {
      onSuccess && onSuccess(response);
    },
    onError: (err) => {
      onError && onError(err);
    },
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  });

  return { query };
};
