import React from 'react';
import { useStyles } from './Referral.style';
import LogoIND from '@assets/Header/Referral/logo-ind.svg';
import LogoRU from '@assets/Header/Referral/logo-ru.svg';
import { Localizator } from '@utils/Localizator';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

type Props = {
  referralLink: string;
};

export const ReferralView: React.FC<Props> = ({ referralLink }) => {
  const { classes } = useStyles();
  const { t } = Localizator.translate();
  const { locale } = useStoreon<TAppState, TAppEvents>('locale');

  return (
    <div className={classes.logoWrapper}>
      <a href={referralLink} target="_blank" rel="noopener noreferrer">
        <img
          src={locale === 'ru-RU' ? LogoRU : LogoIND}
          className={classes.logo}
          alt={t('Other.LogoAlt')}
        />
        <div className={classes.logoTitle}>{t('Header.ReferralTitle')}</div>
      </a>
    </div>
  );
};
