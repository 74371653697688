import { TProduct } from '@apptypes/index';
import { Typography } from '@components/shared/Typography/Typography.component';
import React from 'react';
import { useStyles } from './ProductCardName.style';

type Props = {
  product: TProduct;
  imagesCount?: number;
};

export const ProductCardName: React.FC<Props> = ({ product, imagesCount }) => {
  const { classes } = useStyles({ imagesCount });

  return (
    <div id="assortment-sidebar-name-wrapper" className={classes.nameWrapper}>
      <Typography fontSize={20} fontWeight={700} lineHeight={'24px'}>
        <span data-testid="product-card-name">{product?.name}</span>
      </Typography>
    </div>
  );
};
