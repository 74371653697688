import { InfiniteData, useInfiniteQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { apiService } from '@api/index';
import { useAsyncError } from '@hooks/useAsyncError';
import { useGetCurrentCategory } from '@hooks/useGetCurrentCategory';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { TAppEvents, TAppState } from '@store/index';
import {
  IProductsResponse,
  TBreadcrumb,
  TProductFolder,
} from '@apptypes/index';
import { TUseOnCategoryClick, TUseShowTable } from './Catalog.types';
import { useTranslation } from 'react-i18next';

export const useCatalogPage = () => {
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const searchString = searchParams.get('searchBy');
  const productId = searchParams.get('productId');

  const {
    query: { isFetching, fetchNextPage, remove },
    buttonRemainCount,
    merged,
  } = useGetProductsQuery();

  const fetchNextPageMemorized = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const { breadcrumbs } = useGetBreadcrumbs();

  const { showTable } = useShowTable({ products: merged.products || [] });

  const { handleReset } = useReset();

  const { onCategoryClick } = useOnCategoryClick({
    handleReset,
    handleRemove: remove,
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries('products');
    };
  }, [queryClient]);

  return {
    allCategories,
    productId,
    searchString,
    products: merged.products,
    isProductsLoading: isFetching,
    breadcrumbs,
    showTable,
    buttonRemainCount,
    fetchNextPage: fetchNextPageMemorized,
    handleReset,
    onCategoryClick,
    totalSize: merged.size,
  };
};

const useGenerateBreadcrumbs = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation();

  const categoryId = searchParams.get('categoryId');

  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const { currentCategory } = useGetCurrentCategory();

  const generateBreadcrumbs = useCallback(() => {
    if (categoryId) {
      let last = currentCategory?.parentId;

      let searched: { name: string; id: string }[] = [];

      while (last !== '') {
        for (let i = 0; i < allCategories.length; i++) {
          const item = allCategories[i];

          if (item.id === last) {
            searched.push({
              name: item.name,
              id: item.id,
            });

            last = item.parentId;
          }
        }
      }

      searched = [
        { name: t('Showcase.Breadcrumbs.AllProducts'), id: '' },
        ...searched.reverse(),
      ];

      searched.push({
        name: currentCategory?.name as string,
        id: currentCategory?.id as string,
      });

      const result: TBreadcrumb[] = [];

      searched.forEach((item) => {
        let route: string = '';

        if (item.id) {
          route += `?categoryId=${item.id}`;
        }

        const link = location.pathname + route;

        const { name } = item;

        result.push({ link, label: name });
      });

      return result;
    }

    return [];
  }, [allCategories, categoryId, location.pathname, currentCategory, t]);

  return { generateBreadcrumbs };
};

const useGetBreadcrumbs = () => {
  const { generateBreadcrumbs } = useGenerateBreadcrumbs();
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const breadcrumbs = useMemo(() => {
    if (allCategories.length > 0) {
      return generateBreadcrumbs();
    }

    return [];
  }, [allCategories, generateBreadcrumbs]);

  return { breadcrumbs };
};

const useShowTable = ({ products }: TUseShowTable) => {
  const showTable = useMemo(() => {
    return Boolean(products?.length);
  }, [products]);

  return { showTable };
};

const useGetProductsQuery = () => {
  const [searchParams] = useSearchParams();

  const throwError = useAsyncError();
  const { isPublicLink, linkPath } = useLinkPath();
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const searchStringEncoded = searchParams.get('searchBy')
    ? encodeURIComponent(searchParams.get('searchBy') as string)
    : '';

  const searchString = searchParams.get('searchBy') ?? '';
  const categoryId = searchParams.get('categoryId') ?? '';

  const categoryName =
    allCategories.find((item) => item.id === categoryId)?.name ?? '';

  const batch = 100;

  const getOffset = useCallback((page: number) => {
    if (page === 0) {
      return 0;
    }

    if (page === 1) {
      return batch;
    }

    return page * (batch - 50);
  }, []);

  const query = useInfiniteQuery(
    ['price-list', `category=${categoryId}`, `search=${searchString}`],

    async ({ pageParam = 0 }) => {
      const res = await apiService.getPriceList(
        isPublicLink,
        linkPath,
        getOffset(pageParam),
        pageParam === 0 ? 100 : 50,
        searchStringEncoded,
        categoryName,
        categoryId,
      );

      return res;
    },
    {
      onError: throwError,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      getNextPageParam: (lastPage, pages) => {
        const alreadyLoaded = pages.reduce(
          (acc, current) => acc + current.products.length,
          0,
        );

        if (alreadyLoaded < lastPage.size) {
          return pages.length + 1;
        }

        return pages.length;
      },
    },
  );

  const mergePagesData: (
    data?: InfiniteData<IProductsResponse>,
  ) => IProductsResponse = useCallback((data) => {
    const result: IProductsResponse = {
      size: 0,
      products: [],
      defaultCurrency: { code: '', isoCode: '', name: '', fullName: '' },
    };

    if (!data) {
      return result;
    }

    if (data.pages.length > 0) {
      result.size = data.pages[0].size;
    }

    data.pages.forEach((page, index) => {
      page.products.forEach((product) => {
        result.products.push(product);
      });
    });

    return result;
  }, []);

  const mergedResponse = mergePagesData(query.data);

  const productsCount = mergedResponse.size;
  const productsLoaded = mergedResponse.products.length;
  const productsNotLoaded = productsCount - productsLoaded;
  const buttonRemainCount = productsNotLoaded >= 50 ? 50 : productsNotLoaded;

  return { query, buttonRemainCount, merged: mergedResponse };
};

const useReset = () => {
  const queryClient = useQueryClient();

  const handleReset = useCallback(() => {
    queryClient.removeQueries('price-list');
  }, [queryClient]);

  return { handleReset };
};

const useOnCategoryClick = ({
  handleReset,
  handleRemove,
}: TUseOnCategoryClick) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onCategoryClick = useCallback(
    (cat: TProductFolder | undefined) => {
      handleReset();
      handleRemove();

      if (cat && cat.id) {
        searchParams.set('categoryId', cat?.id);
      } else {
        searchParams.delete('categoryId');
      }

      setSearchParams(searchParams);
    },
    [handleRemove, handleReset, searchParams, setSearchParams],
  );

  return { onCategoryClick };
};
