import { makeStyles } from 'tss-react/mui';
import { WHITE_DEFAULT } from '@theme/theme';

type Props = {
  color?: string;
};

export const useStyles = makeStyles<Props>()(
  (theme, { color = WHITE_DEFAULT }) => ({
    ldsRing: {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      height: 30,
      width: 30,
      transform: 'translate(-50%, -50%)',

      '@keyframes lds-ring': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },

      '& div': {
        boxSizing: 'border-box',
        display: 'block',
        position: 'absolute',
        width: 25,
        height: 25,
        margin: 2,
        border: `2px solid ${color}`,
        borderRadius: '50%',
        animation: 'lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
        borderColor: `${
          color || WHITE_DEFAULT
        } transparent transparent transparent`,

        '&:nth-of-type(1n)': {
          animationDelay: '-0.45s',
        },

        '&:nth-of-type(2n)': {
          animationDelay: '-0.3s',
        },

        '&:nth-of-type(3n)': {
          animationDelay: '-0.15s',
        },
      },
    },
  }),
);
