export const getHostUrl = (isLocalBuild: boolean) => {
  if (isLocalBuild) {
    return 'http://localhost:9081'; // 8585 если используется nginx и docker, иначе 9081
  }

  return window.location.origin;
};

export const getBaseUrl = (
  isLocalBuild: boolean,
  isPublicLink?: boolean,
  link?: string,
) => {
  return `${getHostUrl(isLocalBuild)}/desktop-api${
    isPublicLink ? `/public/${link}` : `/${link}`
  }`;
};
