import React from 'react';
import { Label } from '@components/shared/Label/Label.component';
import { Typography } from '@components/shared/Typography/Typography.component';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '@theme/theme';
import { useStyles } from './CartMobileTitle.style';
import { Localizator } from '@utils/Localizator';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

type Props = {
  counterpartyText?: string;
};

export const CartMobileTitle: React.FC<Props> = ({ counterpartyText }) => {
  const { classes } = useStyles();
  const { t } = Localizator.translate();

  const { locale } = useStoreon<TAppState, TAppEvents>('locale');

  const {
    linkInfo: { organizationName },
  } = useLinkPath();

  return (
    <div>
      <Typography
        fontWeight={700}
        fontSize={24}
        lineHeight={'28px'}
        color={PRIMARY_TEXT_COLOR}
      >
        {t('Cart.TitlePrefix')} {organizationName}
      </Typography>
      {Boolean(counterpartyText) && (
        <div className={classes.counterpartyText}>
          <Typography fontSize={14} fontWeight={400} lineHeight="20px">
            {counterpartyText}
          </Typography>
        </div>
      )}

      {locale !== 'ru-RU' && (
        <div className={classes.gst}>
          <Label fontSize={12} lineHeight="16px">
            {t('Cart.GST')}
          </Label>
        </div>
      )}

      <div className={classes.secondaryText}>
        <Label
          fontWeight={400}
          fontSize={12}
          lineHeight="16px"
          color={SECONDARY_TEXT_COLOR}
        >
          {t('Cart.StockAdvice')}
        </Label>
      </div>
    </div>
  );
};
