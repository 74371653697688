import React from 'react';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from '../../Categories.component';
import { CategoriesCatalogButton } from '../../CategoriesCatalogButton/CategoriesCatalogButton.component';
import { CategoriesMobileContent } from '../../CategoriesContent/CategoriesMobileContent/CategoriesMobileContent.component';

type Props = {
  handleOpenMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  open: boolean;
  isError: boolean;
  isLoading: boolean;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
  listWrapperRef: React.RefObject<HTMLDivElement>;
};

export const CategoriesInnerViewMobile: React.FC<Props> = ({
  handleOpenMenu,
  onMenuItemClick,
  open,
  isError,
  isLoading,
  currentCategory,
  categories,
  listWrapperRef,
}) => {
  return (
    <>
      <CategoriesCatalogButton handleOpenMenu={handleOpenMenu} open={open} />

      <CategoriesMobileContent
        open={open}
        onMenuItemClick={onMenuItemClick}
        isError={isError}
        isLoading={isLoading}
        currentCategory={currentCategory}
        categories={categories}
        listWrapperRef={listWrapperRef}
      />
    </>
  );
};
