import React from 'react';
import { useCallbacks } from './Cart.hooks';
import { CartView } from './Cart.view';

type Props = {
  withCart?: boolean;
};

export const Cart: React.FC<Props> = ({ withCart }) => {
  const { totalPositionsCount, totalPrice, handleClick } = useCallbacks();

  return (
    <CartView
      onClick={handleClick}
      totalPositionsCount={totalPositionsCount}
      totalPrice={totalPrice}
      withCart={withCart}
    />
  );
};
