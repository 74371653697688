import React, { FC } from 'react';
import { TBreadcrumb } from '@apptypes/index';
import { useCallbacks } from './Breadcrumbs.hooks';
import { BreadcrumbsView } from './Breadcrumbs.view';

type Props = {
  breadcrumbs?: TBreadcrumb[] | null;
};

export const Breadcrumbs: FC<Props> = (props) => {
  const { breadcrumbs } = props;

  const { handleClick } = useCallbacks();

  if (!breadcrumbs) {
    return null;
  }

  if (breadcrumbs.length === 0) {
    return null;
  }

  return <BreadcrumbsView breadcrumbs={breadcrumbs} onClick={handleClick} />;
};
