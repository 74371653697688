import { StoreonModule } from 'storeon';

interface GallerySetItemsPayload {
  key: string;
  value: string[];
}

export interface IGalleryState {
  galleryOpenedId: string | null;
  galleryItems: Map<string, string[]>;
}

export const GALLERY_SET_ITEMS = 'gallery/setItems';
export const GALLERY_SET_OPENED_ID = 'gallery/setOpenedId';

export interface IGalleryEvents {
  [GALLERY_SET_ITEMS]: GallerySetItemsPayload;
  [GALLERY_SET_OPENED_ID]: string;
}

export const gallery: StoreonModule<IGalleryState, IGalleryEvents> = (
  store,
) => {
  store.on('@init', () => ({
    galleryItems: new Map(),
    galleryOpenedId: null,
  }));

  store.on(GALLERY_SET_OPENED_ID, (state, galleryOpenedId) => {
    return {
      galleryOpenedId,
    };
  });

  store.on(GALLERY_SET_ITEMS, (state, payload: GallerySetItemsPayload) => {
    return {
      galleryItems: state.galleryItems.set(payload.key, payload.value),
    };
  });
};
