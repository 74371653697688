import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetProduct } from '@hooks/useApi.hooks';
import { NumberUtils } from '@utils/NumberUtils';
import {
  TUseApplyShadow,
  TUseGetProductData,
  TUseOnTouchEnd,
  TUseOnTouchStart,
  TUseRemoveShadow,
  TUseSum,
} from './ProductCard.types';

export const useGetProductData = ({ productId }: TUseGetProductData) => {
  const { query } = useGetProduct({ productId: productId || '' });

  return { query };
};

export const useApplyShadow = ({ setWithShadow }: TUseApplyShadow) => {
  const applyShadow = useCallback(() => {
    setWithShadow(true);
  }, [setWithShadow]);

  return { applyShadow };
};

export const useRemoveShadow = ({ setWithShadow }: TUseRemoveShadow) => {
  const removeShadow = useCallback(() => {
    setWithShadow(false);
  }, [setWithShadow]);

  return { removeShadow };
};

export const useHandleIconClick = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleIconClick = useCallback(() => {
    searchParams.delete('productId');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return { handleIconClick };
};

export const useSum = ({ productFromStore }: TUseSum) => {
  const sum = useMemo(() => {
    if (productFromStore) {
      return NumberUtils.getNumberRepresentation(
        productFromStore.quantity * productFromStore.price,
        2,
        2,
      );
    }

    return '0';
  }, [productFromStore]);

  return { sum };
};

export const useOnTouchStart = ({ setIsSwiped }: TUseOnTouchStart) => {
  const onTouchStart = () => {
    setIsSwiped(true);
  };

  return { onTouchStart };
};

export const useOnTouchEnd = ({ setIsSwiped }: TUseOnTouchEnd) => {
  const onTouchEnd = () => {
    // iOS Safari hack
    setTimeout(() => {
      setIsSwiped(false);
    }, 500);
  };

  return { onTouchEnd };
};

export const useOnTouchStartCapture = () => {
  const onTouchStartCapture = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return { onTouchStartCapture };
};
