import { makeStyles } from 'tss-react/mui';
import { WHITE_DEFAULT } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    '@media (max-width: 1279px)': {
      marginBottom: 80,
    },
  },

  footer: {
    height: 'max-content',
    position: 'fixed',
    bottom: '0',
    width: '100vw',
    transform: 'translate3d(0,0,0)',

    '& > div': {
      transform: 'translate3d(0,0,0)',
    },
  },

  footerButtonWrapper: {
    height: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'max-content',
    margin: '0 auto',

    '& button': {
      width: 'max-content',
    },
  },

  footerContent: {
    padding: 0,
    background: WHITE_DEFAULT,
    boxShadow: '0px -4px 16px 0px #00000014',
  },

  errorMessage: {
    '@media (max-width: 1279px)': {
      display: 'flex',
      justifyContent: 'center',
    },

    '@media (max-width: 601px)': {
      justifyContent: 'flex-start',
    },
  },
}));
