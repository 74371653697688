import React from 'react';
import { useStyles } from './CategoriesCatalogButton.style';
import { useCallbacks } from './CategoriesCatalogButton.hooks';
import { Localizator } from '@utils/Localizator';

type Props = {
  handleOpenMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  open: boolean;
};

export const CategoriesCatalogButton: React.FC<Props> = ({
  handleOpenMenu,
  open,
}) => {
  const { getIcon, isVisible, onClick } = useCallbacks({
    open,
    handleOpenMenu,
  });

  const { classes } = useStyles({ isVisible });
  const { t } = Localizator.translate();

  return (
    <div onClick={onClick} className={classes.button}>
      <div className={classes.imgWrapper}>
        <img
          className={classes.icon}
          src={getIcon()}
          alt={t('Other.CategoriesAlt')}
        />
      </div>
      <div className={classes.label}>{t('Header.Catalog.CatalogButton')}</div>
    </div>
  );
};
