import { makeStyles } from 'tss-react/mui';

type Props = {
  isFullWidth?: boolean;
  shouldCenterPrice?: boolean;
};

const FULL_PRICE_BLOCK_WIDTH = 480;
const SHORT_PRICE_BLOCK_WIDTH = 275;

export const useStyles = makeStyles<Props>()(
  // eslint-disable-next-line complexity
  (theme, { isFullWidth, shouldCenterPrice }) => ({
    priceAndButtonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1 1 100%',
      width: '100%',
      alignItems: shouldCenterPrice ? 'center' : 'flex-start',

      '@media (max-width: 1279px)': {
        marginTop: 16,
        justifyContent: 'space-between',
        flex: '0 1 100%',
      },
    },

    priceInfoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: isFullWidth ? SHORT_PRICE_BLOCK_WIDTH : FULL_PRICE_BLOCK_WIDTH,
      marginRight: 19,
      marginLeft: isFullWidth ? 16 : 0,

      '@media (max-width: 1279px)': {
        marginRight: 0,
        marginLeft: 0,
        width: 'auto',
        maxWidth: '45%',
      },
    },

    priceWrapper: {
      display: 'flex',
      marginBottom: 2,
      alignItems: 'center',
      flexWrap: 'wrap',
      maxWidth: isFullWidth ? SHORT_PRICE_BLOCK_WIDTH : FULL_PRICE_BLOCK_WIDTH,
    },

    price: {
      marginRight: 4,
      maxWidth: '100%',

      '& > p': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },

    buttonWrapper: {
      display: 'auto',
      width: 'auto',
    },

    sum: {
      width: 'max-content',
      maxWidth: '100%',

      '& > p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    uom: {
      '& > p': {
        maxWidth: isFullWidth
          ? SHORT_PRICE_BLOCK_WIDTH
          : FULL_PRICE_BLOCK_WIDTH,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);
