import { makeStyles } from 'tss-react/mui';
import { SECONDARY_HOVER_COLOR } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: 'fit-content',
    minWidth: 32,
    minHeight: 32,
    cursor: 'pointer',
    position: 'relative',
    top: -8,
    left: -8,

    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },

  icon: {
    transition: '0.25s all',

    '& path': {
      transition: '0.25s all',
      fill: '#091739',
    },

    '&:hover': {
      '& path': {
        fill: SECONDARY_HOVER_COLOR,
      },
    },
  },
}));
