import { makeStyles } from 'tss-react/mui';
import { PRIMARY_LINK_COLOR, PRIMARY_TEXT_COLOR } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  title: {
    color: PRIMARY_TEXT_COLOR,
    fontFamily: 'ALSHauss,sans-serif',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '18px',
    cursor: 'pointer',
    transition: '0.25s all',

    paddingTop: 12,
    paddingBottom: 4,

    '&:hover': {
      color: PRIMARY_LINK_COLOR,
    },
  },
}));
