import { makeStyles } from 'tss-react/mui';
import { BLUE_DEFAULT, DARK_BLUE_TYPE_1, WHITE_DEFAULT } from '@theme/theme';
import BackIcon from '@assets/Header/Search/SearchModal/back.svg';
import CloseIcon from '@assets/Header/Search/SearchModal/close.svg';

export const useStyles = makeStyles()((theme) => ({
  root: {
    background: WHITE_DEFAULT,
    height: '100%',
    maxWidth: '100vw',
  },

  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 52,
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
  },

  searchHistoryWrapper: {
    height: 'auto',
  },

  backButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    minHeight: 32,
    padding: 0,
    backgroundColor: 'initial',
    backgroundImage: `url(${BackIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '32px 32px',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
  },

  input: {
    fontFamily: 'Montserrat,serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    width: '100%',
    margin: '0px 8px',
    color: DARK_BLUE_TYPE_1,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',

    '&::placeholder': {
      color: 'gray',
    },
  },

  close: {
    width: 15,
    height: 15,
    backgroundColor: 'initial',
    backgroundImage: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '15px 15px',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    marginRight: '17.5px',
  },

  button: {
    height: 40,
    width: 88,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '9px 20px',
    background: BLUE_DEFAULT,
    borderRadius: '8px',
    border: 'unset',
    fontFamily: 'Montserrat,serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: WHITE_DEFAULT,
  },
}));
