import { Localizator } from '@utils/Localizator';
import React from 'react';
import { SuccessDescription } from '../SuccessDescription/SuccessDescription.component';
import { useStyles } from './SuccessText.style';

type Props = {
  state: {
    name: string;
    sum: number;
  };
};

export const SuccessText: React.FC<Props> = ({ state }) => {
  const { classes } = useStyles();
  const { t } = Localizator.translate();

  return (
    <>
      <h2 className={classes.messageTitle}>{t('Success.Thanks')}</h2>
      <SuccessDescription state={state} />
    </>
  );
};
