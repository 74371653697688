import React from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import { Container } from '@components/Container/Container.component';
import { Back } from '../Back/Back.component';
import { Cart } from '../Cart/Cart.component';
import { Categories } from '../Categories/Categories.component';
import { useStyles } from '../Header.style';
import { Search } from '../Search/Search.component';
import { Title } from '../Title/Title.component';

type Props = {
  withSearch?: boolean;
  withTitle?: boolean;
  withLogo?: boolean;
  withCaregories?: boolean;
  withBackButton?: boolean;
  withCart?: boolean;
  isCart?: boolean;
  title: string;
  catetoriesAnchorEl: HTMLElement | null;
  isCategoriesOpened: boolean;
  setCategoriesAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  referralLink: string;
  findByCallback?: () => void;
  refetchOnSearchCallback?: () => void;
  __containerClassName?: string;
};

export const StickyHeader: React.FC<Props> = ({
  withSearch,
  withTitle,
  withLogo,
  withCaregories,
  withBackButton,
  withCart,
  isCart,
  title,
  catetoriesAnchorEl,
  isCategoriesOpened,
  setCategoriesAnchorEl,
  findByCallback,
  refetchOnSearchCallback,
  referralLink,
  __containerClassName,
}) => {
  const { cx, classes } = useStyles({ withSearch });

  const { isMobile } = useIsMobile();

  return (
    <header className={cx(classes.header, 'header')}>
      <Container className={__containerClassName}>
        <div className={cx(classes.headerContent, 'header-content')}>
          {withTitle && (
            <Title
              isCart={isCart}
              title={title}
              withLogo={withLogo}
              referralLink={referralLink}
            />
          )}
          {withCaregories && (
            <Categories
              anchorEl={catetoriesAnchorEl}
              setAnchorEl={setCategoriesAnchorEl}
              open={isCategoriesOpened}
            />
          )}
          {isMobile && withBackButton && <Back />}
          {withSearch && (
            <Search
              findByCallback={findByCallback}
              refetchOnSearchCallback={refetchOnSearchCallback}
            />
          )}
          <Cart withCart={withCart} />
        </div>
      </Container>
    </header>
  );
};
