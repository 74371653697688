import React from 'react';
import { useCallbacks } from './SearchHistoryItem.hooks';
import { SearchHistoryItemView } from './SearchHistoryItem.view';

type Props = {
  item: string;
  onIconClick: (item: string) => void;
  onItemClick: (item: string) => void;
};

export const SearchHistoryItem: React.FC<Props> = ({
  item,
  onIconClick,
  onItemClick,
}) => {
  const { handleIconClick, handleItemClick } = useCallbacks({
    item,
    onIconClick,
    onItemClick,
  });

  return (
    <SearchHistoryItemView
      item={item}
      handleIconClick={handleIconClick}
      handleItemClick={handleItemClick}
    />
  );
};
