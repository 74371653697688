import { UseFormGetValues } from 'react-hook-form';

export const getDataForCreateOrder = (
  getValues: UseFormGetValues<{
    phone: string;
    companyName: string;
    comment: string;
  }>,
) => {
  return {
    phone: getValues().phone,
    companyName: getValues().companyName,
    comment: getValues().comment,
  };
};
