import React from 'react';
import { useCallbacks } from './Categories.hooks';
import { CategoriesView } from './Categories.view';

export type TMenuItem = {
  id?: string | null;
  isWithSubcategory: boolean;
  toBack?: boolean;
};

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  open: boolean;
};

export const Categories: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  open,
}) => {
  const {
    handleOpenMenu,
    handleCloseMenu,
    onMenuItemClick,
    isLoading,
    error,
    currentCategory,
    categories,
    listWrapperRef,
  } = useCallbacks({ setAnchorEl, open });

  return (
    <CategoriesView
      open={open}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
      onMenuItemClick={onMenuItemClick}
      isLoading={Boolean(isLoading)}
      isError={error}
      anchorEl={anchorEl}
      currentCategory={currentCategory}
      categories={categories}
      listWrapperRef={listWrapperRef}
    />
  );
};
