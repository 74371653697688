import { makeStyles } from 'tss-react/mui';
import { PRIMARY_IMAGE_PLACEHOLDER_COLOR } from '@theme/theme';

type Props = {
  isFullWidth?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isFullWidth }) => ({
  imagePlaceholder: {
    width: isFullWidth ? 40 : 88,
    height: isFullWidth ? 40 : 88,
    background: PRIMARY_IMAGE_PLACEHOLDER_COLOR,

    '@media (max-width: 1279px)': {
      width: 56,
      height: 56,
    },
  },
}));
