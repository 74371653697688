import i18next from 'i18next';

type KV = { [key: string]: string | number | undefined };

export class Localizator {
  static translate() {
    const tr = (key: string, addArgs?: KV) => {
      // Implementation of translation function
      // It can be i18next, or something else

      const { t } = i18next;

      return t(key, { ...addArgs });
    };

    return { t: tr };
  }

  static getCurrency(locale: string | null) {
    let currency: string;

    switch (locale) {
      case 'en-IN': {
        currency = 'Rs.';
        break;
      }

      default: {
        currency = '₽';
        break;
      }
    }

    return currency;
  }
}
