import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  cartWarningWrapper: {
    marginTop: 4,
  },

  gst: {
    marginBottom: 8,
  },
}));
