import React from 'react';
import { ReferralView } from './Referral.view';

type Props = {
  referralLink: string;
};

export const Referral: React.FC<Props> = ({ referralLink }) => {
  return <ReferralView referralLink={referralLink} />;
};
