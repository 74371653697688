import React from 'react';
import { useAddButton } from './AddButton.hooks';
import { useStyles } from './AddButton.style';
import PlusIcon from './../assets/plus-icon.svg';
import { Localizator } from '@utils/Localizator';

type Props = {
  onClick: () => void;
  isDisabled?: boolean;
  testId?: string;
};

export const AddButton: React.FC<Props> = React.memo(
  ({ onClick, isDisabled, testId = '' }) => {
    const { isClicked, handleOnMouseDown, handleOnMouseUp } = useAddButton();

    const { t } = Localizator.translate();

    const { classes } = useStyles({ isClicked, isDisabled });

    return (
      <button
        onClick={onClick}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        className={classes.wrapper}
        data-testid={`${testId}-add-button`}
      >
        <img src={PlusIcon} alt={t('Other.AddAlt')} />
      </button>
    );
  },
);
