import React, { useEffect, useMemo } from 'react';
import { Header } from '@components/Header/Header.component';
import { Preloader } from '@components/Preloader/Preloader.component';
import { useCatalogPage } from './Catalog.hooks';
import EmptyCatalogImage from '@assets/empty_catalog.svg';
import { Container } from '@components/Container/Container.component';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { Button } from '@components/shared/Button/Button.component';
import { useStyles } from './Catalog.style';
import { ProductCardItemAndGroup } from './ProductCardItemAndGroup/ProductCardItemAndGroup.component';
import { ProductCardPage } from './ProductCardPage/ProductCard.page';
import { NumberUtils } from '@utils/NumberUtils';
import { Localizator } from '@utils/Localizator';
import { useIsMobile } from '@hooks/useIsMobile';

export const CatalogPage = () => {
  const {
    allCategories,
    productId,
    searchString,
    products,
    isProductsLoading,
    breadcrumbs,
    showTable,
    buttonRemainCount,
    fetchNextPage,
    onCategoryClick,
    handleReset,
    totalSize,
  } = useCatalogPage();

  useEffect(() => {
    dispatchEvent(new Event('scroll'));
  }, []);

  const { classes } = useStyles();
  const { t } = Localizator.translate();
  const { isMobile } = useIsMobile();

  const isEmptyCatalog = !Boolean(products?.length) && !isProductsLoading;

  const totalDeclination = useMemo(() => {
    return t('Showcase.ShowcasePlurals.ItemsPlural', { count: totalSize });
  }, [totalSize, t]);

  const loadMoreDeclination = useMemo(() => {
    return t('Showcase.ShowcasePlurals.ItemsPlural', {
      count: buttonRemainCount,
    });
  }, [buttonRemainCount, t]);

  return (
    <>
      <div className={classes.catalogWrapper}>
        <Header
          withReferral
          withCaregories
          withSearch
          withHeader
          withCart
          withTitle
          refetchOnSearchCallback={handleReset}
          findByCallback={handleReset}
          breadcrumbs={breadcrumbs}
          withBreadcrumbs
          // Удалить когда переедем на новую сетку
          __containerClassName={'newBootstrap'}
        />
        <main>
          <Container className={'newBootstrap'}>
            {isProductsLoading && !products.length && <Preloader />}

            {isEmptyCatalog && (
              <div className={classes.empty}>
                <div>
                  <img src={EmptyCatalogImage} alt={t('Other.NoItemsAlt')} />
                  <p>{t('Other.NoItemsAlt')}</p>
                </div>
              </div>
            )}
          </Container>

          {showTable && (
            <Container className={'newBootstrap'}>
              <div>
                {!Boolean(searchString) && (
                  <div className={classes.pageResult}>
                    {NumberUtils.getNumberRepresentation(totalSize || 0, 0)}{' '}
                    {totalDeclination}
                  </div>
                )}

                {Boolean(searchString) && (
                  <div className={classes.pageResult}>
                    {NumberUtils.getNumberRepresentation(totalSize || 0, 0)}{' '}
                    {t('Showcase.Found')}
                  </div>
                )}

                <div className={classes.catalog}>
                  {products.map((product, idx) => {
                    const needRenderCategory =
                      idx === 0 ||
                      (idx >= 1 &&
                        products[idx - 1].categoryId != product.categoryId);

                    const category = allCategories.find(
                      (c) => c.id === product.categoryId,
                    );

                    const categoryName =
                      category === undefined && needRenderCategory
                        ? t('Showcase.DefaultCategory')
                        : category?.name;

                    return (
                      <ProductCardItemAndGroup
                        key={product.id + category?.id}
                        product={product}
                        categoryName={categoryName}
                        onCategoryClick={() => onCategoryClick(category)}
                        needRenderCategory={needRenderCategory}
                      />
                    );
                  })}

                  {buttonRemainCount > 0 && (
                    <Button
                      onClick={fetchNextPage}
                      isLoading={isProductsLoading}
                      variant="secondary"
                    >
                      {t('Showcase.More')} {buttonRemainCount}{' '}
                      {loadMoreDeclination}
                    </Button>
                  )}
                </div>
              </div>
            </Container>
          )}
        </main>
      </div>

      <AnimatePresence exitBeforeEnter>
        {Boolean(productId) && (
          <>
            <div>
              {isMobile && <ProductCardPage open={Boolean(productId)} />}
              {!isMobile && (
                <ProductCardPage open={Boolean(productId)} disableScrollLock />
              )}
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
