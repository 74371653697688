import { useState, useCallback } from 'react';

// https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
// тут описано как это работает

export const useAsyncError = () => {
  const [, setError] = useState();

  return useCallback(
    (e: any) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};
