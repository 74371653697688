import { makeStyles } from 'tss-react/mui';
import { DARK_BLUE_TYPE_1 } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  messageDescription: {
    marginTop: 16,
    fontSize: 18,
    lineHeight: 1.33,
    wordBreak: 'break-word',

    '@media (max-width: 1279px)': {
      fontFamily: 'Montserrat,serif',
      fontSize: '16px',
      lineHeight: '24px',
      color: DARK_BLUE_TYPE_1,
      margin: '20px 0 0',
      fontWeight: '500',

      '& span': {
        fontWeight: 500,
      },
    },
  },

  strong: {
    fontWeight: 700,
  },

  customerCurrency: {
    fontSize: 18,

    '@media (max-width: 1279px)': {
      fontSize: 16,
      fontWeight: '700 !important',
    },
  },
}));
