import React, { useEffect, useMemo } from 'react';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useCallbacks } from './Cart.hooks';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { useStyles } from './Cart.style';
import { Header } from '@components/Header/Header.component';
import { CartEmpty } from './CartEmpty/CartEmpty.component';
import { Container } from '@components/Container/Container.component';
import { CartProductRow } from './CartProductRow/CartProductRow.component';
import { CartCheckout } from './CartCheckout/index.component';
import { CartShowcaseWarning } from './CartShowcaseWarning/CartShowcaseWarning.component';
import { CartMobileTitle } from './CartMobileTitle/CartMobileTitle.component';
import { Localizator } from '@utils/Localizator';

export const CartPage = () => {
  const { isMobile } = useIsMobile();

  const { cartItems, currency } = useStoreon<TAppState, TAppEvents>(
    'cartItems',
    'currency',
  );
  const {
    withRemains,
    linkInfo: { counterpartyName },
  } = useLinkPath();

  const {
    handleButtonClick,
    isLoading,
    showEmptyCart,
    breadcrumbs,
    goodsCount,
    positionsCount,
    totalPrice,
    isOverstock,
    buttonWasclicked,
    handleDelete,
    animatedClassName,
    isError,
  } = useCallbacks(withRemains);

  const { t } = Localizator.translate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showWarning = buttonWasclicked && (isOverstock || isError);

  const counterpartyText = useMemo(() => {
    if (Boolean(counterpartyName) && !showEmptyCart) {
      return `${t('Cart.Buyer')}${counterpartyName}`;
    }

    return '';
  }, [t, counterpartyName, showEmptyCart]);

  const { classes, cx } = useStyles({
    showEmptyCart,
    withWarning: showWarning,
  });

  return (
    <div className={classes.wrapper}>
      <div>
        <Header
          withBackButton
          withHeader={isMobile}
          withReferral
          withTitle
          withBreadcrumbs
          breadcrumbs={breadcrumbs}
          isCart
          titlePrefix={t('Cart.TitlePrefix') as string}
          subTitle={counterpartyText}
          // Удалить когда переедем на новую сетку
          __containerClassName={'newBootstrap'}
        />
        <main>
          {/* При переходе на новую сетку это надо выпилить и сделать класс дефолтным */}
          <Container className={cx('newBootstrap', classes.withMarginBottom)}>
            {!showEmptyCart && (
              <div className={classes.cartWrapper}>
                <div className={classes.cartShowcase}>
                  {isMobile && (
                    <CartMobileTitle counterpartyText={counterpartyText} />
                  )}

                  {showWarning && (
                    <CartShowcaseWarning
                      isError={isError}
                      isOverstock={isOverstock}
                      animatedClassName={animatedClassName}
                    />
                  )}

                  <div className={classes.productsWrapper}>
                    {cartItems.map((product) => (
                      <CartProductRow
                        key={product.id}
                        product={product}
                        onDelete={() => handleDelete(product.id)}
                        currency={currency.symbol ? currency.symbol : currency.name}
                      />
                    ))}
                  </div>
                </div>
                {!isMobile && (
                  <CartCheckout
                    totalPrice={totalPrice}
                    goodsCount={goodsCount}
                    positionsCount={positionsCount}
                    handleButtonClick={handleButtonClick}
                    isLoading={isLoading}
                  />
                )}
              </div>
            )}

            {showEmptyCart && <CartEmpty />}
          </Container>
        </main>
      </div>

      {isMobile && !showEmptyCart && (
        <div className={classes.footer}>
          <CartCheckout
            totalPrice={totalPrice}
            goodsCount={goodsCount}
            positionsCount={positionsCount}
            handleButtonClick={handleButtonClick}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};
