import { TProduct } from '@apptypes/index';
import { Label } from '@components/shared/Label/Label.component';
import React from 'react';

type Props = {
  product: TProduct;
};

export const ProductCardArticle: React.FC<Props> = ({ product }) => {
  return (
    <Label>
      <span data-testid="product-card-page-article">{product?.article}</span>
    </Label>
  );
};
