import React from 'react';
import { TMenuItem } from './Categories.component';
import { TProductFolder } from '@apptypes/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { CategoriesInnerViewMobile } from './CategoriesInnerView/CategoriesInnerViewMobile/CategoriesInnerViewMobile.component';
import { CategoriesInnerViewDesktop } from './CategoriesInnerView/CategoriesInnerViewDesktop/CategoriesInnerViewDesktop.component';

type Props = {
  open: boolean;
  handleOpenMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleCloseMenu: () => void;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  isLoading: boolean;
  isError: boolean;
  anchorEl: HTMLElement | null;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
  listWrapperRef: React.RefObject<HTMLDivElement>;
};

export const CategoriesView: React.FC<Props> = (props) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile && <CategoriesInnerViewMobile {...props} />}
      {!isMobile && <CategoriesInnerViewDesktop {...props} />}
    </>
  );
};
