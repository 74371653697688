import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCurrentCategory } from '@hooks/useGetCurrentCategory';
import { useIsMobile } from '@hooks/useIsMobile';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { TUseCallbacks, TUseTitle, TUseWithLogo } from './Header.types';
import { Localizator } from '@utils/Localizator';

export const useCallbacks = ({ titlePrefix }: TUseCallbacks) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { title } = useTitle({ open, titlePrefix });

  const { withLogo } = useWithLogo({ open });

  return { title, open, setAnchorEl, anchorEl, withLogo };
};

const useTitle = ({ open, titlePrefix }: TUseTitle) => {
  const { linkInfo } = useLinkPath();
  const { pathname } = useLocation();
  const { isMobile } = useIsMobile();

  const { t } = Localizator.translate();

  const { currentCategory } = useGetCurrentCategory();

  const title = useMemo(() => {
    if (open && isMobile) {
      return t('Pages.Catalog');
    }

    if (isMobile && currentCategory) {
      return currentCategory.name;
    }

    if (isMobile && pathname.includes('confirm')) {
      return t('Pages.OrderPlacement');
    }

    if (isMobile && pathname.includes('cart')) {
      return t('Pages.Cart');
    }

    if (titlePrefix) {
      return `${titlePrefix} ${linkInfo?.organizationName ?? ''}`;
    }

    return linkInfo?.organizationName;
  }, [linkInfo, open, pathname, isMobile, currentCategory, titlePrefix, t]);

  return { title };
};

const useWithLogo = ({ open }: TUseWithLogo) => {
  const { pathname } = useLocation();
  const { currentCategory } = useGetCurrentCategory();

  const withLogo = useMemo(() => {
    if (open) {
      return false;
    }

    if (pathname.includes('confirm')) {
      return false;
    }

    if (pathname.includes('cart')) {
      return false;
    }

    if (currentCategory) {
      return false;
    }

    return true;
  }, [open, pathname, currentCategory]);

  return { withLogo };
};
