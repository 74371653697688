import { useCallback } from 'react';
import { store as StoreonStore } from './../store/index';
import { SET_LOCALE } from '@store/locale.store';
import { useGetLocale } from './useApi.hooks';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguages } from '@utils/getLanguages';

export const usePrefetchLocale = <T extends typeof StoreonStore>(
  store: T,
  enabled: boolean,
) => {
  const handleSuccess = useCallback(
    async (locale: string) => {
      store.dispatch(SET_LOCALE, locale);

      await i18next.use(initReactI18next).init({
        resources: {
          'en-IN': { translation: getLanguages().IND },
          'ru-RU': { translation: getLanguages().CIS },
        },
        preload: ['en-IN', 'ru-RU'],
        lng: locale,
        fallbackLng: locale,

        interpolation: {
          escapeValue: false,
        },
      });
    },
    [store],
  );

  const handleError = useCallback(async () => {
    store.dispatch(SET_LOCALE, 'ru-RU');

    await i18next.use(initReactI18next).init({
      resources: {
        'en-IN': { translation: getLanguages().IND },
        'ru-RU': { translation: getLanguages().CIS },
      },
      preload: ['en-IN', 'ru-RU'],
      lng: 'ru-RU',
      fallbackLng: 'ru-RU',

      interpolation: {
        escapeValue: false,
      },
    });
  }, [store]);

  const query = useGetLocale({
    onSuccess: handleSuccess,
    onError: handleError,
    enabled,
  });

  return query;
};
