import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  tagsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginTop: 20,
  },

  tagsInnerWrapper: {
    maxWidth: 'calc(100% - 40px - 16px)',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: 8,
    flex: 1,
  },

  tagsItem: {
    maxWidth: '50%',

    '& > div': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      '& > p': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },

  toasterText: {
    color: '#FFF',
  },
}));
