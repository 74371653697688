import {
  TProduct,
  ValidateStocksProductsResponseObject,
} from '@apptypes/index';

export const handleError = ({
  products,
  response,
}: {
  response?: ValidateStocksProductsResponseObject;
  products?: TProduct[];
}) => {
  if (
    response === null ||
    response === undefined ||
    products === undefined ||
    products === null
  ) {
    return [];
  }

  const sorted: typeof products = [];

  if (response?.length && products?.length) {
    // С сервера приходит не массив продуктов с интерфейсом IProductState
    // А массив [{productId: string, stock: number}]
    // Ввиду этого нужно сопоставление уже имеющихся товаров в корзине
    // С productId товаров (уже отсортированных), которые пришли с бэкэнда
    // Следующий двойной цикл и делает это сопастовление

    for (let i = 0; i < response?.length; i++) {
      for (let j = 0; j < products?.length; j++) {
        if (response[i].productId === products[j].id) {
          sorted.push({ ...products[j], stock: response[i].stock });
        }
      }
    }
  }

  const final = response?.length ? sorted : products;

  return final;
};

export const mapValuesToArray = (map: TProduct[]) => {
  const res: TProduct[] = [];

  map.forEach((value) => {
    res.unshift(value);
  });

  return res;
};
