import React from 'react';
import { useStyles } from './Search.style';
import { ReactComponent as SearchIcon } from '@assets/Header/Search/search-icon.svg';
import CrossIcon from '@assets/Header/Search/cross-icon-gray.svg';
import { SearchHistory } from './SearchHistory/SearchHistory.component';
import { SearchModal } from './SeachModal/SearchModal.component';
import { useIsMobile } from '@hooks/useIsMobile';
import { Localizator } from '@utils/Localizator';

type Props = {
  inputValue: string;
  handleCloseProductCardPage: () => void;
  handleBlur: () => void;
  handleFocus: () => void;
  handleInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleMouseOver: () => void;
  handleMouseOut: () => void;
  handleSearchItemIconClick: (item: string) => void;
  handleSearchItemClick: (item: string) => void;
  handleClear: () => void;
  handleSearch: () => void;
  handleClick: () => void;
  handleClose: () => void;

  inputRef: React.RefObject<HTMLInputElement>;
  isSearchHistoryOpened: boolean;
  searchHistory: string[];
  isInputFocused: boolean;
  withSearchIcon: boolean;
  isInputHovered: boolean;
  categoriesCount: number;
  isModalSearchOpened: boolean;
  setIsModalSearchOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SearchView: React.FC<Props> = ({
  inputValue,
  handleBlur,
  handleFocus,
  handleInputChange,
  handleEnterPress,
  handleMouseOver,
  handleMouseOut,
  handleSearchItemIconClick,
  handleSearchItemClick,
  handleClear,
  handleSearch,
  inputRef,
  isSearchHistoryOpened,
  searchHistory,
  isInputFocused,
  isInputHovered,
  categoriesCount,
  withSearchIcon,
  handleCloseProductCardPage,
  isModalSearchOpened,
  handleClick,
  handleClose,
  setIsModalSearchOpened,
}) => {
  const { isMobile } = useIsMobile();

  const { t } = Localizator.translate();

  const { classes } = useStyles({
    withSearchIcon,
    isInputHovered,
    categoriesCount,
  });

  return (
    <div
      id="assortment-search"
      onClick={handleCloseProductCardPage}
      className={classes.searchWrapper}
    >
      <input
        value={inputValue}
        onClick={isMobile ? handleClick : undefined}
        onBlur={isMobile ? undefined : handleBlur}
        onFocus={isMobile ? undefined : handleFocus}
        // Запись () => {} нужна чтобы eslint не ругался
        onChange={isMobile ? () => {} : handleInputChange}
        onKeyDown={isMobile ? undefined : handleEnterPress}
        className={classes.input}
        type="text"
        placeholder={t('Header.SearchInputPlaceholder') as string}
        onMouseOver={isMobile ? undefined : handleMouseOver}
        onMouseOut={isMobile ? undefined : handleMouseOut}
        id="search-input"
        ref={inputRef}
        autoComplete="off"
      />

      <label htmlFor="search-input">
        {!isInputFocused && !Boolean(inputValue) && (
          <SearchIcon
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className={classes.searchIcon}
          />
        )}
      </label>

      {isModalSearchOpened && isMobile && (
        <SearchModal
          setIsModalSearchOpened={setIsModalSearchOpened}
          open={isModalSearchOpened}
          onClose={handleClose}
          inputValue={inputValue}
          onSearch={handleSearch}
          onChange={handleInputChange}
          onEnter={handleEnterPress}
        />
      )}

      {!isModalSearchOpened && (
        <>
          {!isMobile && (
            <SearchHistory
              isOpen={isSearchHistoryOpened}
              searchHistory={searchHistory}
              onIconClick={handleSearchItemIconClick}
              onItemClick={handleSearchItemClick}
            />
          )}

          <div className={classes.searchButtonWrapper}>
            {Boolean(inputValue) && (
              <img
                onClick={handleClear}
                className={classes.crossIcon}
                src={CrossIcon}
                alt={t('Other.CloseAlt')}
              />
            )}
            {(isInputFocused || Boolean(inputValue)) && (
              <button
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={handleSearch}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                className={classes.searchButton}
              >
                {t('Header.SearchInputButton')}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
