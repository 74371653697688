import { GRAY_TYPE_1, BREADCRUMB } from '@theme/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  list: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    paddingLeft: 0,
    paddingBottom: 12,
    margin: 0,
    overflow: 'auto',

    '& .swiper-slide': {
      // Не хочет без !important заводиться
      width: 'auto !important',
    },

    '& .swiper': {
      marginLeft: '0',
    },

    '::-webkit-scrollbar': {
      display: 'none',
    },

    '@media (max-width: 1279px)': {
      marginTop: 16,
    },
  },

  item: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: GRAY_TYPE_1,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },

  withTextDecoration: {
    textDecoration: 'underline',
    color: BREADCRUMB,

    '& span': {
      cursor: 'pointer',
    },
  },

  icon: {
    display: 'block',
    marginLeft: 8,
    marginRight: 8,
  },
}));
