import React from 'react';
import { Header } from '@components/Header/Header.component';
import { useStyles } from './Success.style';
import { Container } from '@components/Container/Container.component';
import { useCallbacks } from './Success.hooks';
import { SuccessText } from './SuccessText/SuccessText.component';
import { SuccessAction } from './SuccessAction/SuccessAction.component';
import { SuccessIcon } from './SuccessIcon/SuccessIcon.component';

export const SuccessPage = () => {
  const { classes } = useStyles();

  const { state } = useCallbacks();

  if (state === null) {
    return null;
  }

  return (
    <div>
      <Header withReferral />
      <main>
        <Container>
          <div className={classes.wrapper}>
            <div className={classes.message}>
              <SuccessIcon />
              <SuccessText state={state} />
              <SuccessAction />
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};
