import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { Container } from '@components/Container/Container.component';
import { Header } from '@components/Header/Header.component';
import { Button } from '@components/shared/Button/Button.component';
import { Label } from '@components/shared/Label/Label.component';
import { Typography } from '@components/shared/Typography/Typography.component';
import {
  SET_CART_USER_INFO_COMMENT,
  SET_CART_USER_INFO_COMPANY_NAME,
  SET_CART_USER_INFO_PHONE,
} from '@store/cartUserInfo.store';
import ErrorIcon from '@assets/Error/error-icon.svg';
import { useStyles } from './Error.style';
import { Localizator } from '@utils/Localizator';

const ERROR_ICON_WIDTH = 60;
const ERROR_ICON_HEIGHT = 60;

export const ErrorPage = (passedError: any) => {
  const { classes, cx } = useStyles();

  const { dispatch } = useStoreon();
  const { t } = Localizator.translate();
  const navigate = useNavigate();

  const [errObject, setErrObject] = useState<null | {
    errorCode: number;
    name: string;
    description: string;
    withButton?: boolean;
  }>(null);

  const handleButtonClick = useCallback(() => {
    navigate('catalog');
    passedError.resetErrorBoundary();
    dispatch(SET_CART_USER_INFO_PHONE, '');
    dispatch(SET_CART_USER_INFO_COMMENT, '');
    dispatch(SET_CART_USER_INFO_COMPANY_NAME, '');
  }, [navigate, passedError, dispatch]);

  useLayoutEffect(() => {
    const message = passedError?.error?.message;

    try {
      const err = JSON.parse(message);

      setErrObject(err);
    } catch (e) {
      console.log('Cannot parse passed error at ErrorPage', e);

      setErrObject({
        errorCode: -1,
        name: t('Errors.CommonErrorName'),
        description: t('Errors.CommonErrorDescription'),
      });
    }
    // t вставлять в зависимости нельзя - будет бесконечный ререндер
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedError]);

  return (
    <div>
      <Header withReferral />
      <main>
        <Container className="newBootstrap">
          <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
              <div className={classes.centerBlock}>
                <img
                  src={ErrorIcon}
                  width={ERROR_ICON_WIDTH}
                  height={ERROR_ICON_HEIGHT}
                  alt={t('Other.ErrorAlt') as string}
                />
              </div>

              {Boolean(errObject?.name) && (
                <div className={cx(classes.titleWrapper, classes.centerText)}>
                  <Typography fontWeight={700} fontSize={24} lineHeight="28px">
                    {errObject?.name || t('Errors.LinkNotFound')}
                  </Typography>
                </div>
              )}

              {Boolean(errObject?.description) && (
                <div
                  className={cx(classes.titleDescription, classes.centerText)}
                >
                  <Label fontWeight={400} fontSize={14} lineHeight="20px">
                    {errObject?.description || t('Errors.ContactYourSupplier')}
                  </Label>
                </div>
              )}

              {Boolean(true || errObject?.withButton) && (
                <div className={cx(classes.buttonWrapper, classes.centerBlock)}>
                  <Button
                    variant="primary"
                    onClick={handleButtonClick}
                    className={classes.button}
                  >
                    {t('Errors.GoBackToTheOrder')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};
