import React from 'react';
import { ProductRowCard } from '@components/ProductRowCard/ProductRowCard.component';
import { useGetFullImages } from '@hooks/useApi.hooks';
import { useNumberInputWithRemains } from '@hooks/useNumberInputsWithRemains';
import { TProduct } from '@apptypes/index';
import { useImageGallery } from '@hooks/useImageGallery';

type Props = {
  product: TProduct;
  onDelete: () => void;
  currency: string;
};

export const CartProductRow: React.FC<Props> = ({
  product,
  onDelete,
  currency,
}) => {
  const {
    inputValue,
    handleBlur,
    handleChange,
    handleDecrement,
    handleIncrement,
    handlePaste,
    handleFocus,
    borders,
    showRemainsData,
    isStocksOverflow,
    handleKeyDown,
  } = useNumberInputWithRemains({ product });

  const {
    query: { data, isFetching },
  } = useGetFullImages({
    id: product?.id,
    enabled: !product.isKit,
    product,
  });

  const { images } = useImageGallery({
    product,
    enabled: true,
  });

  const [imageUrl] = data ?? [];

  return (
    <ProductRowCard
      key={product.id}
      product={product}
      onDelete={onDelete}
      currency={currency}
      inputValue={inputValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleDecrement={handleDecrement}
      handleIncrement={handleIncrement}
      handlePaste={handlePaste}
      handleFocus={handleFocus}
      handleKeyDown={handleKeyDown}
      borders={borders}
      showRemainsData={showRemainsData}
      isStocksOverflow={isStocksOverflow}
      imageIsLoading={isFetching}
      imageUrl={imageUrl}
      images={images}
    />
  );
};
