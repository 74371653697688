import { Modal } from '@mui/material';
import React, { useState } from 'react';
import {
  useApplyShadow,
  useGetProductData,
  useHandleIconClick,
  useOnTouchEnd,
  useOnTouchStart,
  useOnTouchStartCapture,
  useRemoveShadow,
  useSum,
} from './ProductCard.hooks';
import { motion } from 'framer-motion/dist/framer-motion';
import { useStyles } from './ProductCard.style';
import { Preloader } from '@components/Preloader/Preloader.component';
import { Typography } from '@components/shared/Typography/Typography.component';
import { ProductCardError } from './ProductCardError/ProductCardError.component';
import { ProductCardTopIcon } from './ProductCardTopIcon/ProductCardTopIcon.component';
import { Container } from '@components/Container/Container.component';
import { useIsMobile } from '@hooks/useIsMobile';
import { ImageGallery } from '@components/shared/ImageGallery/ImageGallery.component';
import { useImageGallery } from '@hooks/useImageGallery';
import { ToastContainer, Slide } from 'react-toastify';
import { useGetProductFromStore } from '@hooks/useGetProductFromStore';
import { useSearchParams } from 'react-router-dom';
import { TProduct } from '@apptypes/index';
import { TAppEvents, TAppState } from '@store/index';
import { useStoreon } from 'storeon/react';
import { ProductCardFooter } from './ProductCardFooter/ProductCardFooter.component';
import { ProductCardName } from './ProductCardName/ProductCardName.component';
import { ProductCardArticle } from './ProductCardArticle/ProductCardArticle.component';
import { ProductCardCode } from './ProductCardCode/ProductCardCode.component';
import { ProductCardBreadcrumbs } from './ProductCardBreadcrumbs/ProductCardBreadcrumbs.component';

type Props = {
  open: boolean;
  disableScrollLock?: boolean;
};

// eslint-disable-next-line complexity
export const ProductCardPage: React.FC<Props> = ({
  open,
  disableScrollLock,
}) => {
  const { currency: defaultCurrency } = useStoreon<TAppState, TAppEvents>('currency');
  const currency = defaultCurrency.symbol ? defaultCurrency.symbol : defaultCurrency.name;

  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');

  const [isSwiped, setIsSwiped] = useState(false);
  const [withShadow, setWithShadow] = useState(false);

  const { onTouchStart } = useOnTouchStart({ setIsSwiped });
  const { onTouchEnd } = useOnTouchEnd({ setIsSwiped });
  const { onTouchStartCapture } = useOnTouchStartCapture();
  const { handleIconClick } = useHandleIconClick();

  const { removeShadow } = useRemoveShadow({ setWithShadow });
  const { applyShadow } = useApplyShadow({ setWithShadow });

  const {
    query: {
      isFetching: isProductDataFetching,
      data: productData,
      isError: productFetchingError,
    },
  } = useGetProductData({
    productId,
  });

  const { product } = useGetProductFromStore(
    productData?.id || '',
    productData as TProduct,
  );

  const { sum } = useSum({ productFromStore: product });

  const {
    handleClick,
    isFetching: isImagesFetching,
    images,
  } = useImageGallery({
    product,
    enabled: Boolean(product && !product.isKit),
  });

  const { classes } = useStyles({
    withShadow,
  });

  const { isMobile } = useIsMobile();

  const isLoading = isImagesFetching || isProductDataFetching;

  return (
    <div data-testid="product-card-page">
      <Modal
        id="assortment-sidebar"
        open={open}
        hideBackdrop
        className={classes.modalRoot}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        disableScrollLock={disableScrollLock}
        data-testid="product-card-page-modal"
      >
        <motion.div
          initial={{ x: 1000 }}
          animate={{ x: 0 }}
          exit={{ x: 1000 }}
          transition={{
            duration: isSwiped ? 0 : 0.3,
            bounce: 0,
          }}
          className={classes.wrapper}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onAnimationComplete={applyShadow}
          onAnimationStart={removeShadow}
          id="product-card-page-animated"
          data-testid="product-card-page-animated"
        >
          <ToastContainer
            position="top-center"
            autoClose={3000}
            limit={1}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            transition={Slide}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
            closeButton={false}
            draggableDirection="y"
            className={classes.toaster}
          />

          <div className={classes.innerWrapper}>
            {!productFetchingError && (
              <>
                <div>
                  <div className={classes.header}>
                    <Container
                      onlyMobile
                      className={isMobile ? 'newBootstrap' : ''}
                    >
                      <ProductCardTopIcon
                        onClick={handleIconClick}
                        // Нужно, иначе не будет работать анимация
                        onTouchStartCapture={onTouchStartCapture}
                      />
                    </Container>
                  </div>

                  <Container
                    onlyMobile
                    className={isMobile ? 'newBootstrap' : ''}
                  >
                    <div className={classes.content}>
                      {(isLoading || !product) && <Preloader />}
                      {!isLoading && product && (
                        <div>
                          <div
                            id="assortment-sidebar-img-wrapper"
                            className={classes.imgWrapper}
                          >
                            <ImageGallery
                              images={images}
                              onClick={handleClick}
                            />
                          </div>

                          <ProductCardBreadcrumbs product={product} />

                          <ProductCardName
                            imagesCount={images?.length}
                            product={product}
                          />

                          {Boolean(product?.article || product?.code) && (
                            <div
                              id="assortment-sidebar-article-wrapper"
                              className={classes.labelWrapper}
                            >
                              {Boolean(product?.article) && (
                                <ProductCardArticle product={product} />
                              )}

                              {Boolean(product?.code) && (
                                <ProductCardCode product={product} />
                              )}
                            </div>
                          )}

                          {product.description && (
                            <div className={classes.description}>
                              <Typography
                                fontSize={14}
                                fontWeight={400}
                                lineHeight="20px"
                              >
                                {product.description}
                              </Typography>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Container>
                </div>

                {!isLoading && (
                  <ProductCardFooter
                    product={product}
                    currency={currency}
                    sum={sum}
                  />
                )}
              </>
            )}

            {productFetchingError && (
              <ProductCardError handleIconClick={handleIconClick} />
            )}
          </div>
        </motion.div>
      </Modal>
    </div>
  );
};
