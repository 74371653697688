import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCallbacks = () => {
  const { handleButtonClick } = useHandleButtonClick();

  return { handleButtonClick };
};

const useHandleButtonClick = () => {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(() => {
    navigate('../catalog');
  }, [navigate]);

  return { handleButtonClick };
};
