import React, { HTMLAttributes } from 'react';
import { useStyles } from './ErrorDefault.style';
import ErrorIcon from './assets/error-icon.svg';
import { Localizator } from '@utils/Localizator';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  testId?: string;
  withIcon?: boolean;
  children: React.ReactNode;
}

// eslint-disable-next-line react/no-multi-comp
export const ErrorDefault = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ children, className, testId = '', withIcon, ...rest }, ref) => {
      const { classes, cx } = useStyles({ withIcon });
      const { t } = Localizator.translate();

      return (
        <div className={cx(classes.wrapper, className)} {...rest} ref={ref}>
          {withIcon && (
            <div
              data-testid={`${testId}-error-default-icon`}
              className={classes.iconWrapper}
            >
              <img src={ErrorIcon} alt={t('Other.ErrorAlt')} />
            </div>
          )}
          <div data-testid={`${testId}-error-default`} className={classes.text}>
            {children}
          </div>
        </div>
      );
    },
  ),
);
