import * as yup from 'yup';

type Props = {
  isPublicLink: boolean;
  locale?: string | null;
};

export const generateSchema = ({ isPublicLink, locale = 'ru-RU' }: Props) => {
  if (isPublicLink) {
    let regex;

    switch (locale) {
      case 'en-IN': {
        regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
        break;
      }

      default: {
        regex = /^(\+?([0-9])[- ]?)?\(?\d{3}\)?[- ]?(\d[- ]?)+$/;
        break;
      }
    }

    return yup.object().shape({
      // eslint-disable-next-line
      phone: yup.string().matches(regex).required(),
      // eslint-disable-next-line
      companyName: yup.string().min(1).required(),
      comment: yup.string(),
    });
  }

  return yup.object().shape({
    comment: yup.string(),
  });
};
