import React from 'react';
import { useStyles } from './Cart.styles';
import { CartBottomText } from './CartBottomText/CartBottomText.view';
import { CartIconWrapperView } from './CartIconWrapper/CartIconWrapper.view';
import { CartTextView } from './CartTopText/CartTopText.view';

type Props = {
  onClick: () => void;
  totalPositionsCount: number;
  totalPrice: string;
  withCart?: boolean;
};

export const CartView: React.FC<Props> = ({
  onClick,
  totalPositionsCount,
  totalPrice,
  withCart,
}) => {
  const { classes } = useStyles();

  return (
    <div id="basket-button" onClick={withCart ? onClick : undefined} className={classes.wrapper}>
      {withCart && (
        <div className={classes.innerWrapper}>
          <CartIconWrapperView totalPositionsCount={totalPositionsCount} />
          <div className={classes.text}>
            <CartTextView
              totalPositionsCount={totalPositionsCount}
              totalPrice={totalPrice}
            />
            <CartBottomText totalPositionsCount={totalPositionsCount} />
          </div>
        </div>
      )}
    </div>
  );
};
