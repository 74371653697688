import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  warningImage: {
    marginLeft: 16,
    marginRight: 8,
    minWidth: 20,
  },

  mobileContent: {
    display: 'flex',
    alignItems: 'center',
  },

  warning: {
    '@media (max-width: 1279px)': {
      marginTop: 24,
    },
  },
}));
