import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  textInputWrapper: {
    maxHeight: 84,
  },

  teatareaWrapper: {
    maxHeight: 116,
    marginBottom: 0,
  },

  inputWrapper: {
    marginBottom: 24,
  },
}));
