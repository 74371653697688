import React, { HTMLAttributes } from 'react';
import { useStyles } from './ProductGroupName.style';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

// eslint-disable-next-line react/no-multi-comp
export const ProdctGroupName = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ className, children, testId, ...rest }, ref) => {
      const { classes, cx } = useStyles();

      return (
        <div
          className={cx(classes.title, className)}
          ref={ref}
          data-testid={`${testId}-product-group-name`}
          {...rest}
        >
          {children}
        </div>
      );
    },
  ),
);
