import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { usePostValidateStocks } from '@hooks/useApi.hooks';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { TAppEvents, TAppState } from '@store/index';
import { handleError } from './Cart.utils';
import { AxiosError } from 'axios';
import {
  TProduct,
  ValidateStocksProductsResponseObject,
} from '@apptypes/index';
import { StoreonDispatch } from 'storeon';
import { SECONDARY_BACKGROUND_COLOR } from '@theme/theme';
import { useTotalPrice } from '@hooks/useTotalPrice';
import { FORCE_SET_CART_ITEMS, REMOVE_FROM_CART } from '@store/cart.store';
import { Localizator } from '@utils/Localizator';

export const useCallbacks = (withRemains: boolean) => {
  const { showEmptyCart } = useShowEmptyCart();
  const { breadcrumbs } = useHandleGenerateBreadcrumbs();
  const { goodsCount } = useGoodsCount();
  const { positionsCount } = usePositionCount();
  const { totalPrice } = useTotalPrice();
  const { isOverstock } = useOverstock();
  const { handleDelete } = useHandleDeleteItem();

  const [clicks, setClicks] = useState(0);

  const [animatedClassName, setAnimatedClassname] = useState('');

  const onMutate = useCallback(() => {
    setClicks((p) => ++p);
    setAnimatedClassname('');
  }, []);

  const [showError, setShowError] = useState(false);

  const onSuccess = useCallback(() => {
    setShowError(false);
  }, []);

  const onError = useCallback(() => {
    setShowError(true);
  }, []);

  const { handleButtonClick, isLoading, buttonWasclicked, isError } =
    useHandleButtonClick(withRemains, onMutate, onSuccess, onError);

  useEffect(() => {
    if (clicks > 1 && isOverstock) {
      setAnimatedClassname('animate__bounce');
    }

    if (clicks <= 1 && isOverstock) {
      setAnimatedClassname('animate__slideInDown');
    }

    if (clicks <= 1 && isError && !isOverstock) {
      setAnimatedClassname('animate__slideInDown');
    }

    if (clicks > 1 && isError && !isOverstock) {
      setAnimatedClassname('animate__bounce');
    }
  }, [clicks, isOverstock, isError]);

  useEffect(() => {
    if (!isOverstock) {
      setShowError(false);
    }

    if (isOverstock) {
      setShowError(true);
    }
  }, [isOverstock]);

  useEffect(() => {
    if (showEmptyCart) {
      document.body.style.backgroundColor = SECONDARY_BACKGROUND_COLOR;
    }

    return () => {
      document.body.style.backgroundColor = 'unset';
    };
  }, [showEmptyCart]);

  return {
    handleButtonClick,
    isLoading,
    showEmptyCart,
    breadcrumbs,
    goodsCount,
    positionsCount,
    totalPrice,
    isOverstock,
    isError: showError,
    buttonWasclicked,
    handleDelete,
    animatedClassName,
  };
};

const useHandleButtonClick = (
  withRemains: boolean,
  cb: () => void,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const navigate = useNavigate();

  const [buttonWasclicked, setButtonWasclicked] = useState(false);

  const { cartItems, dispatch } = useStoreon<TAppState, TAppEvents>(
    'cartItems',
  );

  const onErrorValidated = (
    err: AxiosError<ValidateStocksProductsResponseObject>,
    dispatch: StoreonDispatch<TAppEvents>,
  ) => {
    const response = err?.response?.data;

    if (response) {
      const newCorrectProductsOrder = handleError({
        response,
        products: cartItems,
      });
      const reversed = newCorrectProductsOrder.concat();
      const preparedMap: TProduct[] = [];

      for (let i = 0; i < reversed.length; i++) {
        preparedMap.push(reversed[i]);
      }

      dispatch(FORCE_SET_CART_ITEMS, preparedMap);
    }
  };

  const {
    mutation: { mutate: validateStocks, isLoading, isError, reset },
  } = usePostValidateStocks({
    products: cartItems,
    onSuccess: () => {
      onSuccess && onSuccess();
      navigate('../confirm');
    },
    onError: (err) => {
      onError && onError();
      setButtonWasclicked(true);
      onErrorValidated(err, dispatch);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    },
    onMutate: () => cb(),
  });

  const handleButtonClick = useCallback(() => {
    withRemains ? validateStocks() : navigate('../confirm');
  }, [navigate, validateStocks, withRemains]);

  return { handleButtonClick, isLoading, buttonWasclicked, isError, reset };
};

const useShowEmptyCart = () => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const showEmptyCart = useMemo(() => {
    return cartItems.length <= 0;
  }, [cartItems]);

  return { showEmptyCart };
};

const useHandleGenerateBreadcrumbs = () => {
  const { root } = useLinkPath();
  const { t } = Localizator.translate();

  const handleGenerateBreadcrumbs = useCallback(() => {
    const result: { link: string; label: string }[] = [];

    result.push({
      label: t('Cart.Breadcrumbs.AllProducts'),
      link: `${root}/catalog` ?? '',
    });

    result.push({
      label: t('Cart.Breadcrumbs.Cart'),
      link: '',
    });

    return result;
  }, [root, t]);

  const breadcrumbs = useMemo(() => {
    return handleGenerateBreadcrumbs();
  }, [handleGenerateBreadcrumbs]);

  return { breadcrumbs };
};

const useGoodsCount = () => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const goodsCount = useMemo(() => {
    let counter = 0;

    for (let i = 0; i < cartItems.length; i++) {
      counter += cartItems[i].quantity;
    }

    return counter;
  }, [cartItems]);

  return { goodsCount };
};

const usePositionCount = () => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const positionsCount = useMemo(() => {
    let counter = 0;

    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].quantity > 0) {
        counter++;
      }
    }

    return counter;
  }, [cartItems]);

  return { positionsCount };
};

const useOverstock = () => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const { withRemains } = useLinkPath();

  const isOverstock = useMemo(() => {
    return Boolean(
      withRemains &&
        cartItems.some((product) => product.quantity > product.stock),
    );
  }, [withRemains, cartItems]);

  return { isOverstock };
};

const useHandleDeleteItem = () => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(REMOVE_FROM_CART, id);
    },
    [dispatch],
  );

  return { handleDelete };
};
