import React, { HTMLAttributes } from 'react';
import { useStyles } from './Spinner.styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: string;
  className?: string;
  testId?: string;
}

// eslint-disable-next-line react/no-multi-comp
export const Spinner = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ color, className, testId = '', ...rest }, ref) => {
      const { classes, cx } = useStyles({ color });

      return (
        <div
          data-testid={`${testId}-spinner-default`}
          className={cx(classes.ldsRing, 'lds-ring', className)}
          ref={ref}
          {...rest}
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      );
    },
  ),
);
