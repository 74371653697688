import { makeStyles } from 'tss-react/mui';
import { DARK_BLUE_TYPE_1 } from '@theme/theme';
import Logo from '@assets/Header/Title/logo.svg';

type Props = {
  isCart?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isCart }) => ({
  title: {
    color: DARK_BLUE_TYPE_1,
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '28px',
    maxWidth: isCart ? 'none' : 348,

    '& p': {
      textOverflow: isCart ? 'unset' : 'ellipsis',
      whiteSpace: isCart ? 'unset' : 'nowrap',
      overflow: isCart ? 'unset' : 'hidden',

      '@media (max-width: 1279px)': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',

        margin: '0 12px',
        lineHeight: '53px',
      },
    },

    '@media (max-width: 1279px)': {
      order: '2',
      fontSize: '18px',
      display: 'flex',
      justifyContent: 'center',
      height: 52,
      lineHeight: '52px',
      flex: '1 1',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'none',
      textAlign: 'center',

      '& > a': {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  },

  logo: {
    width: 24,
    height: 19,
    padding: 0,
    backgroundColor: 'initial',
    backgroundImage: `url(${Logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '24px 19px',
    border: 0,
    outline: 'none',
    marginLeft: 16,

    '@media (max-width: 1279px)': {
      marginLeft: 0,
    },
  },

  subTitle: {
    marginTop: 4,
  },
}));
