import { makeStyles } from 'tss-react/mui';
import { NEW_BOOTSTRAP_VALUE } from '@constants';
import { SECONDARY_BACKGROUND_COLOR, WHITE_DEFAULT } from '@theme/theme';

type Props = {
  showEmptyCart?: boolean;
  withWarning?: boolean;
};

export const useStyles = makeStyles<Props>()(
  (theme, { showEmptyCart, withWarning }) => ({
    wrapper: {
      '& .cart-title': {
        background: showEmptyCart ? SECONDARY_BACKGROUND_COLOR : WHITE_DEFAULT,
        zIndex: 1,
        position: 'relative',
      },

      '@media (max-width: 1279px)': {
        maxWidth: '100vw',
      },
    },

    pageTitle: {
      marginTop: 18,
      fontSize: 18,
    },

    cartWrapper: {
      display: 'flex',
      paddingTop: 24,

      '@media (max-width: 1279px)': {
        paddingTop: 0,
      },
    },

    cartShowcase: {
      maxWidth: 780,
      marginRight: 20,

      '@media (max-width: 1279px)': {
        maxWidth: '100%',
        marginRight: 0,
        width: '100%',
      },
    },

    withMarginBottom: {
      '@media (max-width: 1279px)': {
        marginBottom: withWarning ? 108 : 64,
      },
    },

    productsWrapper: {
      '@media (max-width: 1279px)': {
        marginTop: withWarning ? 0 : 24,
      },
    },

    footer: {
      position: 'fixed',
      bottom: '0',
      width: '100vw',
      transform: 'translate3d(0,0,0)',

      '& > div': {
        transform: 'translate3d(0,0,0)',
      },
    },

    newBootstrap: {
      width: NEW_BOOTSTRAP_VALUE,
      maxWidth: NEW_BOOTSTRAP_VALUE,
      minWidth: NEW_BOOTSTRAP_VALUE,
      margin: '0 auto',

      '@media (max-width: 1279px)': {
        width: 'auto',
        minWidth: 'unset',
        padding: '0px 16px',
        maxWidth: '100vw',
        marginLeft: '0px',
      },
    },

    cartTitle: {
      zIndex: 1,
      position: 'relative',
      background: WHITE_DEFAULT,
    },
  }),
);
