import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  message: {
    width: 600,
    margin: '20px auto',
    padding: '40px 40px 60px',
    textAlign: 'center',
    border: '1px solid rgba(151, 151, 151, 0.3)',
    borderRadius: '3px',

    '@media (max-width: 1279px)': {
      width: 'auto',
      margin: 'auto',
      padding: '0px',
      border: 'none',
    },
  },

  wrapper: {
    '@media (max-width: 1279px)': {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
    },
  },
}));
