import { MenuItem } from '@mui/material';
import React from 'react';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from '../../Categories.component';
import { useStyles } from '../CategoriesMenuContent.style';
import CategoriesBackArrow from '@assets/categories-back-icon.svg';
import { Localizator } from '@utils/Localizator';

type Props = {
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  currentCategory: TProductFolder | null | undefined;
};

export const CurrentGoodMenuItemView: React.FC<Props> = ({
  onMenuItemClick,
  currentCategory,
}) => {
  const { classes, cx } = useStyles();
  const { t } = Localizator.translate();

  return (
    <MenuItem
      disableRipple
      onClick={(evt) =>
        onMenuItemClick(evt, {
          id: currentCategory?.parentId,
          isWithSubcategory: false,
          toBack: true,
        })
      }
      className={cx(classes.menuItem, classes.menuFirstItem, {
        [classes.menuAllProductsItem]: !Boolean(currentCategory),
      })}
      title={
        currentCategory
          ? (t('Header.Catalog.Back') as string)
          : (t('Header.Catalog.AllProducts') as string)
      }
    >
      <div
        className={cx(classes.menuItemAllProducts, {
          [classes.verticalCenter]: Boolean(currentCategory),
        })}
      >
        {currentCategory && (
          <>
            <div className={classes.backArrowImageWrapper}>
              <img src={CategoriesBackArrow} alt={t('Other.BackAlt')} />
            </div>
            <p className={classes.menuBlueItem}>{t('Header.Catalog.Back')}</p>
          </>
        )}

        {!currentCategory && <p>{t('Header.Catalog.AllProducts')}</p>}
      </div>
    </MenuItem>
  );
};
