import React from 'react';
import { Label } from '../../shared/Label/Label.component';
import { NumberInput } from '@components/shared/NumberInput/NumberInput.component';
import { Typography } from '@components/shared/Typography/Typography.component';
import { useStyles } from './ProductRowCardPrice.style';
import { NumberUtils } from '@utils/NumberUtils';
import { TProduct } from '@apptypes/index';

type Props = {
  product: TProduct;
  uomLabel: string;
  testId?: string;
  currency: string;
  isFullWidth?: boolean;
  shouldCenterPrice?: boolean;

  // Input
  inputValue?: string;
  handleBlur?: () => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
  handleDecrement: () => void;
  handleIncrement: () => void;
  handlePaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  handleFocus?: () => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  borders?: boolean;
  showRemainsData?: boolean;
  isStocksOverflow?: boolean;
  onDelete?: () => void;
};

export const ProductRowCardPrice: React.FC<Props> = ({
  product,
  uomLabel,
  testId = '',
  currency,
  isFullWidth,
  shouldCenterPrice,

  // Input
  inputValue,
  handleBlur,
  handleChange,
  handleDecrement,
  handleIncrement,
  handlePaste,
  handleFocus,
  handleKeyDown,
  borders,
  showRemainsData,
  isStocksOverflow,
  onDelete,
}) => {
  const { classes } = useStyles({ isFullWidth, shouldCenterPrice });

  return (
    <div className={classes.priceAndButtonWrapper}>
      <div className={classes.priceInfoWrapper}>
        <div className={classes.priceWrapper}>
          <div
            title={NumberUtils.getNumberRepresentation(product.price)}
            className={classes.price}
          >
            <Typography fontWeight={700} fontSize={14} lineHeight="20px">
              {NumberUtils.getNumberRepresentation(product.price)}
            </Typography>
          </div>
          <div title={uomLabel} className={classes.uom}>
            <Label testId={testId} fontSize={12} lineHeight="16px">
              {uomLabel}
            </Label>
          </div>
        </div>
        {product.quantity > 0 && (
          <div className={classes.sum}>
            <Label
              title={`${NumberUtils.getNumberRepresentation(
                product.price * product.quantity,
                2,
                2,
              )} ${currency}`}
              fontSize={12}
              lineHeight="16px"
            >
              {`${NumberUtils.getNumberRepresentation(
                product.price * product.quantity,
                2,
                2,
              )} ${currency}`}
            </Label>
          </div>
        )}
      </div>
      <div className={classes.buttonWrapper}>
        <NumberInput
          value={inputValue}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          borders={borders}
          onPaste={handlePaste}
          isAddButtonDisabled={showRemainsData}
          isError={isStocksOverflow}
          title={`${product.stock} ${product.uom}`}
          showRemainsData={showRemainsData}
          isStocksOverflow={isStocksOverflow}
          onDelete={onDelete}
          testId={testId}
        />
      </div>
    </div>
  );
};
