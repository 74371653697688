import React, { HTMLAttributes } from 'react';
import { BackButton } from '@components/shared/BackButton/BackButton.component';
import { CrossButton } from '@components/shared/CrossButton/CrossButton.component';
import { useIsMobile } from '@hooks/useIsMobile';

interface Props extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void;
  className?: string;
  testId?: string;
}

export const ProductCardTopIcon: React.FC<Props> = ({
  className,
  testId = '',
  ...rest
}) => {
  const { isMobile } = useIsMobile();

  if (isMobile) {
    return <BackButton className={className} testId={testId} {...rest} />;
  }

  return <CrossButton className={className} testId={testId} {...rest} />;
};
