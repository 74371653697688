import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './SuccessAction.style';
import { Localizator } from '@utils/Localizator';

export const SuccessAction = () => {
  const { classes, cx } = useStyles();
  const { t } = Localizator.translate();

  return (
    <div className={classes.messageAction}>
      <Link className={cx(classes.btn, classes.btnPrimary)} to="../catalog">
        {t('Success.OrderMore')}
      </Link>
    </div>
  );
};
