import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  cartButtonWrapper: {
    marginTop: 20,

    '& > button': {
      width: 300,
    },

    '@media (max-width: 1279px)': {
      marginTop: 0,

      '& > button': {
        width: 'max-content',
      },
    },
  },
}));
