import { Localizator } from '@utils/Localizator';

export const useCallbacks = (positionsCount?: number, goodsCount?: number) => {
  const { t } = Localizator.translate();

  const positionsDeclination = t(
    'Cart.CheckoutDataPluralsPositions.ItemsPlural',
    {
      count: positionsCount,
    },
  );

  const goodsDeclination = t('Cart.CheckoutDataPluralsGoods.ItemsPlural', {
    count: goodsCount,
  });

  return { positionsDeclination, goodsDeclination };
};
