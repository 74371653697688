import { StoreonModule } from 'storeon';

export interface ICartUserInfoState {
  phone?: string | null;
  companyName?: string | null;
  comment?: string | null;
}

export const SET_CART_USER_INFO_RESET = 'cartUserInfo/reset';
export const SET_CART_USER_INFO_PHONE = 'cartUserInfo/setPhone';
export const SET_CART_USER_INFO_COMPANY_NAME = 'cartUserInfo/setCompanyName';
export const SET_CART_USER_INFO_COMMENT = 'cartUserInfo/setComment';

export interface ICartUserInfoEvents {
  [SET_CART_USER_INFO_RESET]: void;
  [SET_CART_USER_INFO_PHONE]: string | undefined | null;
  [SET_CART_USER_INFO_COMPANY_NAME]: string | undefined | null;
  [SET_CART_USER_INFO_COMMENT]: string | undefined | null;
}

export const cartUserInfo: StoreonModule<
  ICartUserInfoState,
  ICartUserInfoEvents
> = (store) => {
  store.on('@init', () => ({
    phone: '',
    companyName: '',
    comment: '',
  }));

  store.on(SET_CART_USER_INFO_RESET, (state) => {
    return {
      phone: '',
      companyName: '',
      comment: '',
    };
  });

  store.on(SET_CART_USER_INFO_PHONE, (state, phone) => {
    return {
      phone,
    };
  });

  store.on(SET_CART_USER_INFO_COMPANY_NAME, (state, companyName) => {
    return {
      companyName,
    };
  });

  store.on(SET_CART_USER_INFO_COMMENT, (state, comment) => {
    return {
      comment,
    };
  });
};
