import { makeStyles } from 'tss-react/mui';
import LeftArrowIcon from '@assets/Header/Back/left-arrow.svg';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  button: {
    width: 20,
    border: 0,
    cursor: 'pointer',
    height: '16px',
    outline: 'none',
    padding: 0,
    backgroundSize: '20px 16px',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${LeftArrowIcon})`,
  },
}));
