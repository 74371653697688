import { makeStyles } from 'tss-react/mui';
import { DARK_BLUE_TYPE_1 } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  messageTitle: {
    margin: '21px 0 0',
    fontWeight: 700,
    fontSize: 18,

    '@media (max-width: 1279px)': {
      fontFamily: 'Montserrat,serif',
      fontSize: '28px',
      fontWeight: 800,
      lineHeight: '34px',
      color: DARK_BLUE_TYPE_1,
      margin: '32px 0 0',
    },
  },
}));
