import {
  BLUE_DEFAULT,
  DARK_BLUE_TYPE_1,
  DARK_BLUE_TYPE_2,
  GRAY_LIGHT_TYPE_4,
  WHITE_DEFAULT,
} from '@theme/theme';
import { makeStyles } from 'tss-react/mui';

type Props = {
  withSearchIcon: boolean;
  isInputHovered: boolean;
  categoriesCount: number;
};

export const useStyles = makeStyles<Props>()(
  (theme, { withSearchIcon, isInputHovered, categoriesCount }) => ({
    searchWrapper: {
      width: '100%',
      flex: 1,
      color: GRAY_LIGHT_TYPE_4,
      position: 'relative',
      marginLeft: categoriesCount > 0 ? 8 : 16,

      '@media (max-width: 1279px)': {
        // TODO FIXME Обязательно убрать, когда будет полный адаптив ОЗ
        maxWidth: '100vw',
        width: '100%',
        flex: '0 0 100%',
        order: '4',
        marginTop: 16,
        marginLeft: 0,
      },
    },

    input: {
      width: '100%',
      paddingRight: 145,
      height: 44,
      minHeight: 44,
      maxHeight: 44,
      borderRadius: '8px',
      border: `1px solid ${GRAY_LIGHT_TYPE_4}`,
      outline: 'none',
      paddingLeft: withSearchIcon ? 48 : 18,
      transition: '0.2s all',
      fontWeight: 500,
      color: DARK_BLUE_TYPE_1,
      background: 'transparent',
      minWidth: 467,

      '&:hover': {
        border: `1px solid ${BLUE_DEFAULT}`,
      },

      '&:focus': {
        border: `1px solid ${BLUE_DEFAULT}`,
      },

      '@media (max-width: 1279px)': {
        minWidth: 'unset',
        width: '100%',
        paddingRight: 0,
        caretColor: 'transparent',

        '&:hover': {
          border: `1px solid ${GRAY_LIGHT_TYPE_4}`,
        },

        '&:focus': {
          border: `1px solid ${GRAY_LIGHT_TYPE_4}`,
        },
      },
    },

    searchIcon: {
      position: 'absolute',
      left: 16,
      top: '50%',
      transform: 'translate(0, -50%)',
      transition: '0.2s all',

      '& path': {
        transition: '0.2s all',
        fill: isInputHovered ? BLUE_DEFAULT : GRAY_LIGHT_TYPE_4,
      },

      '@media (max-width: 1279px)': {
        '& path': {
          transition: '0.2s all',
          fill: GRAY_LIGHT_TYPE_4,
        },
      },
    },

    crossIcon: {
      display: 'block',
      marginRight: 20,
      cursor: 'pointer',
    },

    searchButtonWrapper: {
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },

    searchButton: {
      display: 'block',
      height: '100%',
      width: '88px',
      minWidth: '88px',
      maxWidth: '88px',
      padding: '0 20px',
      background: BLUE_DEFAULT,
      color: WHITE_DEFAULT,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '22px',
      borderRadius: '8px',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      transition: '0.2s all',

      '&:hover': {
        background: DARK_BLUE_TYPE_2,
      },

      '@media (max-width: 1279px)': {
        display: 'none',

        '&:hover': {
          background: BLUE_DEFAULT,
        },
      },
    },
  }),
);
