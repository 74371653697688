import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { TProduct } from '@apptypes/index';

export const useGetProductFromStore = (productId: string, def: TProduct) => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const product = cartItems.find((el) => el.id === productId) || def;

  return { product };
};
