import { makeStyles } from 'tss-react/mui';

type Props = {
  imagesCount?: number;
};

export const useStyles = makeStyles<Props>()((theme, { imagesCount = 0 }) => ({
  nameWrapper: {
    marginTop: imagesCount > 1 ? 8 : 28,
  },
}));
