import { useCallback } from 'react';
import { TUseCalbacks, TUseGetName, TUseSum } from './SuccessDescription.types';

export const useCallbacks = ({ state }: TUseCalbacks) => {
  const { getSum } = useGetSum({ state });
  const { getName } = useGetName({ state });

  return { getName, getSum };
};

const useGetSum = ({ state }: TUseSum) => {
  const getSum = useCallback(() => {
    return state?.sum
      ? (state.sum / 100).toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '0,00';
  }, [state]);

  return { getSum };
};

const useGetName = ({ state }: TUseGetName) => {
  const getName = useCallback(() => {
    return state?.name ? state.name : '00000';
  }, [state]);

  return { getName };
};
