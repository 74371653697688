import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@components/shared/Button/Button.component';
import { ErrorDefault } from '@components/shared/Error/ErrorDefault.component';
import { useIsMobile } from '@hooks/useIsMobile';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { TFieldValues } from '../Confirm.types';
import { useStyles } from './ConfirmForm.style';
import { ConfirmFormItem } from './ConfirmFormItem/ConfirmFormItem.component';
import { Localizator } from '@utils/Localizator';

type Props = {
  isError: boolean;
  isLoading: boolean;
  message: string;
};

export const ConfirmForm: React.FC<Props> = ({
  isError,
  isLoading,
  message,
}) => {
  const { control } = useFormContext<TFieldValues>();
  const { t } = Localizator.translate();

  const { isPublicLink } = useLinkPath();

  const { isMobile } = useIsMobile();

  const { classes } = useStyles({ isPublicLink });

  return (
    <>
      <div className={classes.wrapper}>
        {isPublicLink && (
          <ConfirmFormItem
            name="phone"
            control={control}
            errorMessage={t('Confirmation.Form.ErrorMessage') as string}
            label={{
              text: t('Confirmation.Form.Phone.Title') as string,
              required: true,
              id: 'phone',
            }}
            additionalText={t('Confirmation.Form.Phone.Description') as string}
            type="input"
          />
        )}

        {isPublicLink && (
          <ConfirmFormItem
            name="companyName"
            control={control}
            errorMessage={t('Confirmation.Form.ErrorMessage') as string}
            label={{
              text: t('Confirmation.Form.Name.Title') as string,
              required: true,
              id: 'companyName',
            }}
            type="input"
          />
        )}

        <ConfirmFormItem
          name="comment"
          control={control}
          label={{
            text: t('Confirmation.Form.Comment.Title') as string,
            id: 'comment',
          }}
          additionalText={t('Confirmation.Form.Comment.Description') as string}
          type="textarea"
        />
      </div>

      {!isMobile && (
        <div className={classes.submitWrapper}>
          <div className={classes.buttonWrapper}>
            <Button type="submit" isLoading={isLoading}>
              {t('Confirmation.PlaceOrderButton')}
            </Button>

            {isError && (
              <div className={classes.errorInnerWrapper}>
                <ErrorDefault withIcon={navigator.onLine}>
                  {message}
                </ErrorDefault>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
