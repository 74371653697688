import React, { FC } from 'react';
import { Typography } from '@components/shared/Typography/Typography.component';
import { useIsMobile } from '@hooks/useIsMobile';
import { NumberUtils } from '@utils/NumberUtils';
import { useStyles } from './CartTotal.style';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

type Props = {
  price: string;
};

export const CartTotal: FC<Props> = (props) => {
  const { price } = props;
  const { currency } = useStoreon<TAppState, TAppEvents>('currency');

  const { classes } = useStyles();

  const { isMobile } = useIsMobile();

  return (
    <div
      title={`${NumberUtils.getNumberRepresentation(
        price,
      )} ${currency.symbol ? currency.symbol : currency.name}`}
      className={classes.checkoutTotal}
    >
      <Typography
        fontWeight={700}
        fontSize={isMobile ? 16 : 24}
        lineHeight={isMobile ? '18px' : '28px'}
      >
        {price}
        <span> {currency.symbol ? currency.symbol : currency.name}</span>
      </Typography>
    </div>
  );
};
