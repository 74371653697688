import { makeStyles } from 'tss-react/mui';
import { BLUE_ONCLICK_COLOR, SECONDARY_HOVER_TEXT_COLOR } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    minHeight: 40,
    minWidth: 40,
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    background: 'transparent',
    border: 'none',

    '&:hover': {
      '& path': {
        transition: '0.25s all',
        stroke: SECONDARY_HOVER_TEXT_COLOR,
      },
    },
    '&:active': {
      '& path': {
        stroke: BLUE_ONCLICK_COLOR,
      },
    },
  },

  icon: {
    minWidth: 16,
    minHeight: 16,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
