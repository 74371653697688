import lodash from 'lodash';
import { store } from '@store/index';

export class NumberUtils {
  static getNumberParts(value: string) {
    return value
      .replace(/[^0-9,.]/g, '')
      .replace(/,/g, '.')
      .split('.');
  }

  static getFloatValueFromString(value: string) {
    const valueParts = NumberUtils.getNumberParts(value);
    const isNegative = value.charAt(0) === '-';

    if (valueParts.length > 1) {
      const intPart = valueParts.slice(0, -1).join('');

      return Number(`${intPart}.${lodash.last(valueParts)}`);
    }

    return Number(valueParts[0]) * (isNegative ? -1 : 1);
  }

  static getPriceValueFromString(value: string) {
    const valueParts = NumberUtils.getNumberParts(value);

    if (valueParts.length > 1) {
      const intPart = valueParts.slice(0, -1).join('');

      return Number(`${intPart}.${lodash.last(valueParts)}`) * 100;
    }

    return Math.round(Number(`${valueParts[0]}.00`) * 100);
  }

  static ceil(value: number, fractionLength: number = 0) {
    return Math.ceil(value * 10 ** fractionLength) / 10 ** fractionLength;
  }

  static round(value: number, fractionLength: number = 0) {
    return Math.round(value * 10 ** fractionLength) / 10 ** fractionLength;
  }

  static roundQuantity(value: number) {
    return NumberUtils.round(value, 4);
  }

  static getNumberRepresentation(
    number: number | string,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 4,
  ) {
    const prepared = typeof number === 'string' ? parseFloat(number) : number;

    return new Intl.NumberFormat(store.get().locale || 'ru-RU', {
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(prepared);
  }
}
