import { Modal } from '@mui/material';
import React from 'react';
import { TProductFolder } from '@apptypes/index';
import { Container } from '@components/Container/Container.component';
import { TMenuItem } from '../../Categories.component';
import { CategoriesMenuContent } from '../../CategoriesMenuItems/CategoriesMenuContent.component';
import { useStyles } from './CategoriesMobileContent.style';

type Props = {
  open: boolean;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  isLoading: boolean;
  isError: boolean;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
  listWrapperRef: React.RefObject<HTMLDivElement>;
};

export const CategoriesMobileContent: React.FC<Props> = ({
  open,
  onMenuItemClick,
  isError,
  isLoading,
  currentCategory,
  categories,
  listWrapperRef,
}) => {
  const { classes } = useStyles();

  return (
    <Modal
      open={open}
      hideBackdrop
      disablePortal
      classes={{ root: classes.modalRoot }}
    >
      <div className={classes.mobileMenu}>
        <Container className="newBootstrap">
          <CategoriesMenuContent
            isError={isError}
            isLoading={isLoading}
            listWrapperRef={listWrapperRef}
            onMenuItemClick={onMenuItemClick}
            currentCategory={currentCategory}
            categories={categories}
          />
        </Container>
      </div>
    </Modal>
  );
};
