import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'absolute',
    top: '48%',
    width: '100%',
    transform: 'translate(0, -50%)',
    zIndex: 1,

    '@media (max-width: 1279px)': {
      top: '43.3%',
    },
  },

  header: {
    padding: '34px 32px 18px 32px',
    height: 68,
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 999,

    '@media (max-width: 1279px)': {
      height: 52,
      padding: '16px 0px',
    },
  },

  errorWrapperInner: {
    height: '100%',
    position: 'relative',
  },

  errorWrapper: {
    height: '100%',
    position: 'relative',
    flex: 1,
  },

  text: {
    color: '#091739',
    textAlign: 'center',
  },

  buttonWrapper: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },
}));
