import { makeStyles } from 'tss-react/mui';
import { GRAY_TYPE_1 } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  textBottom: {
    color: GRAY_TYPE_1,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
  },
}));
