import { makeStyles } from 'tss-react/mui';
import { WHITE_DEFAULT } from '@theme/theme';

type Props = {
  withShadow?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { withShadow }) => ({
  modalRoot: {
    background: 'transparent',
    width: 440,
    left: '100%',
    transform: 'translateX(-100%)',
    outline: 'none',
    overflow: 'auto',
    boxShadow: withShadow
      ? '0px 0px 96px rgba(9, 23, 57, 0.16), 0px 0px 2px rgba(9, 23, 57, 0.12)'
      : 'none',
    borderRadius: '12px 0px 0px 12px',
    transition: '0.05s all',
    overflowX: 'hidden',
    display: 'flex',

    '@media (max-width: 1279px)': {
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      borderRadius: 'unset',
    },
  },

  wrapper: {
    flex: 1,
    display: 'flex',
    height: 'max-content',
    minHeight: '100%',
    width: 440,

    '& .Toastify__toast-container--top-center': {
      top: '32px',
      margin: 0,
      padding: 0,
    },
  },

  innerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: WHITE_DEFAULT,
    position: 'relative',
  },

  header: {
    padding: '34px 32px 18px 32px',
    height: 68,
    position: 'sticky',
    top: 0,
    background: WHITE_DEFAULT,
    zIndex: 999,

    '@media (max-width: 1279px)': {
      height: 52,
      padding: '16px 0px',
    },
  },

  content: {
    padding: '0px 32px',
    background: WHITE_DEFAULT,

    '@media (max-width: 1279px)': {
      padding: '0px',
    },
  },

  displayNone: {
    display: 'none',
  },

  imgWrapper: {
    cursor: 'pointer',

    '& img': {
      width: '376px',
      height: '376px',
      objectFit: 'scale-down',
    },

    '@media (max-width: 1279px)': {
      '& img': {
        width: 600,
        height: 600,
      },
    },

    '@media (max-width: 601px)': {
      marginLeft: -16,
      marginRight: -16,

      '& img': {
        width: '100vw',
        height: '100vw',
      },
    },
  },

  labelWrapper: {
    marginTop: 8,
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 32px 32px 32px',
    background: WHITE_DEFAULT,

    '@media (max-width: 1279px)': {
      paddingTop: 12,
      paddingBottom: 32,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  description: {
    marginTop: 8,
  },

  toaster: {
    '& .Toastify__toast': {
      background: '#091739',
      textAlign: 'center',
      padding: '0px 16px',
      width: 'fit-content',
      borderRadius: '12px',
      height: 44,
      minHeight: 44,
      margin: '0 auto',
    },
  },
}));
