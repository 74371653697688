import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreon } from 'storeon/react';
import {
  copyPasteKeysRegEx,
  NumberType,
  numberTypeKeyCodeRegExMap,
  reservedCodes,
  reservedKeys,
} from '@constants';
import { TAppEvents, TAppState } from '@store/index';
import { REMOVE_FROM_CART, UPDATE_IN_CART } from '@store/cart.store';
import {
  TUseGetNumberInputLogicHandlers,
  TUseHandleBlur,
  TUseHandleChange,
  TUseHandleDecrement,
  TUseHandleIncrement,
  TUseHandleKeyDown,
  TUseHandlePaste,
} from '@apptypes/useNumberInputLogic.types';
import { NumberUtils } from '@utils/NumberUtils';
import { RegexCollectionMethods } from '@utils/RegexCollectionMethods';
import { useLinkPath } from './useLinkPath.hooks';

export const useHandleBlur = ({
  product,
  setBorders,
  setInputValue,
  inputValue,
}: TUseHandleBlur) => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const handleBlur = useCallback(() => {
    const parsed = NumberUtils.getFloatValueFromString(inputValue);

    if (!isNaN(parsed)) {
      setBorders && setBorders(false);

      const rounded = NumberUtils.round(parsed, 4);

      const formatted = NumberUtils.getNumberRepresentation(inputValue, 0, 4);

      if (setInputValue) {
        setInputValue(formatted);
      }

      if (rounded > 0) {
        dispatch(UPDATE_IN_CART, { ...product, quantity: rounded });
      }

      if (rounded <= 0) {
        dispatch(REMOVE_FROM_CART, product.id);
      }
    }
  }, [inputValue, dispatch, product, setInputValue, setBorders]);

  return { handleBlur };
};

export const useHandleIncrement = ({
  product,
  setInputValue,
}: TUseHandleIncrement) => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();
  const { withRemains } = useLinkPath();

  const increment = useCallback(
    (quantity: number) => {
      dispatch(UPDATE_IN_CART, { ...product, quantity });

      if (setInputValue) {
        setInputValue(quantity.toString());
      }
    },
    [dispatch, product, setInputValue],
  );

  const handleIncrement = useCallback(() => {
    const quantity = NumberUtils.roundQuantity(product?.quantity + 1);

    if (withRemains) {
      if (product?.quantity < product.stock) {
        increment(quantity);
      }
    } else {
      increment(quantity);
    }
  }, [product, increment, withRemains]);

  return { handleIncrement };
};

export const useHandleDecrement = ({
  product,
  setInputValue,
}: TUseHandleDecrement) => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const handleDecrement = useCallback(() => {
    const copy = JSON.parse(JSON.stringify(product));
    const quantity = NumberUtils.round(product.quantity - 1, 4);

    if (quantity < 0) {
      copy.quantity = 0;
    }

    if (quantity <= 0) {
      dispatch(REMOVE_FROM_CART, copy.id);
      setInputValue && setInputValue('0');
    } else {
      dispatch(UPDATE_IN_CART, { ...copy, quantity });
      setInputValue && setInputValue(quantity.toString());
    }
  }, [dispatch, product, setInputValue]);

  return { handleDecrement };
};

export const useHandleChange = ({
  setInputValue,
  product,
}: TUseHandleChange) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      const { value } = event.target;

      if (RegexCollectionMethods.isInputValueCanBeParsed(value)) {
        if (product.isKit) {
          setInputValue &&
            setInputValue(
              RegexCollectionMethods.removeAllCommas(
                RegexCollectionMethods.removeAllDots(event.target.value),
              ),
            );
        } else {
          setInputValue && setInputValue(event.target.value);
        }
      }
    },
    [setInputValue, product],
  );

  return { handleChange };
};

export const useHandlePaste = ({
  setBorders,
  setInputValue,
  product,
}: TUseHandlePaste) => {
  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const text = event.clipboardData.getData('text');

      const source = NumberUtils.getFloatValueFromString(text);

      if (source % 1 !== 0 && product.isKit) {
        setBorders && setBorders(true);
        if (NumberUtils.getNumberParts(text)[0]) {
          setInputValue && setInputValue(NumberUtils.getNumberParts(text)[0]);
        }
      } else {
        setInputValue && setInputValue(source.toString());
      }
    },
    [product, setBorders, setInputValue],
  );

  return { handlePaste };
};

const useHandleKeyDown = ({
  numberType = NumberType.INT,
}: TUseHandleKeyDown) => {
  const keyCodeRegEx = useMemo<RegExp>(
    () => numberTypeKeyCodeRegExMap[numberType],
    [numberType],
  );

  const getIsCopyPaste = (e: React.KeyboardEvent) =>
    (e.metaKey || e.ctrlKey) && copyPasteKeysRegEx.test(e.code);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      keyCodeRegEx.test(e.key) ||
      reservedKeys.includes(e.key) ||
      reservedCodes.includes(e.code) ||
      getIsCopyPaste(e)
    ) {
      return;
    }
    e.preventDefault();
  };

  return { handleKeyDown };
};

export const useGetNumberInputLogicHandlers = ({
  product,
  numberType = NumberType.FLOAT,
}: TUseGetNumberInputLogicHandlers) => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const item = useMemo(() => {
    return cartItems.find((el) => el.id === product?.id);
  }, [cartItems, product]);

  const [borders, setBorders] = useState(false);
  const [inputValue, setInputValue] = useState<string>(
    product?.quantity.toString() || '0',
  );

  const { handlePaste } = useHandlePaste({
    product,
    setBorders,
    setInputValue,
  });

  const { handleChange } = useHandleChange({
    setInputValue,
    product,
  });

  const { handleDecrement } = useHandleDecrement({
    product,
    setInputValue,
  });

  const { handleIncrement } = useHandleIncrement({
    product,
    setInputValue,
  });

  const { handleBlur } = useHandleBlur({
    setBorders,
    product,
    setInputValue,
    inputValue,
  });

  const { handleKeyDown } = useHandleKeyDown({ numberType });

  useEffect(() => {
    setInputValue(item?.quantity.toString() || '0');
  }, [item]);

  return {
    borders,
    inputValue,
    handlePaste,
    handleChange,
    handleDecrement,
    handleIncrement,
    handleBlur,
    handleKeyDown,
  };
};
