import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '@pages/Error/Error.page';
import { App } from '@components/App/App.component';

export const Router = () => {
  return (
    <Routes>
      <Route
        path="/public/:linkPathPublic/*"
        element={
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <App />
          </ErrorBoundary>
        }
      />
      <Route
        path="/:linkPathCounterparty/*"
        element={
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <App />
          </ErrorBoundary>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
