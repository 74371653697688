import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  emptyCartWrapper: {
    marginTop: 104 + 24, // 104 отступ контейнера и 24 внутренний padding-bottom самого контейнера
    textAlign: 'center',
  },

  buttonWrapper: {
    margin: '24px auto',
    width: 'max-content',
  },
}));
