export const INPUT_BORDER_DEFAULT = '#cbd9f9';

export const PRIMARY_DISABLED_COLOR = '#CBD0DB';
export const SECONDARY_DISABLED_COLOR = '#E5E5E5';

export const SECONDARY_HOVER_COLOR = '#5F6D79';
export const BLUE_HOVER_COLOR = '#005DC9';

export const BLUE_ONCLICK_COLOR = '#0B7CFF';

export const PRIMARY_TEXT_COLOR = '#091739';
export const SECONDARY_TEXT_COLOR = '#5F6D79';

export const PRIMARY_OUTLINE_COLOR = '#091739';

export const PRIMARY_IMAGE_PLACEHOLDER_COLOR = '#F4F4F4';

export const PRIMARY_BACKGROUND_COLOR = '#036CE5';
export const SECONDARY_BACKGROUND_COLOR = '#F1F4F9';

export const SECONDARY_HOVER_TEXT_COLOR = '#005DC9';

export const PRIMARY_LINK_COLOR = '#036CE5';

export const PRIMARY_INPUT_COLOR = '#036CE5';

export const PRIMARY_BORDER_COLOR = '#CBD0DB';
export const SECONDARY_BORDER_COLOR = '#D1D6DF';

export const PRIMARY_ERROR_COLOR = '#E50347';

export const PRIMARY_DIVIDER_COLOR = '#E7E8EC';

export const PRIMARY_PLACEHOLDER_COLOR = '#9196A5';

export const NORMAL_BACKGROUND_COLOR_PRIMARY_BUTTON = '#F5F5F5';

export const WHITE_DEFAULT = '#ffffff';
export const WHITE_TYPE_1 = '#F5F5F5';
export const RED_DEFAULT = '#ff5656';
export const RED_TYPE_1 = '#FF4B4B';
export const BLUE_DEFAULT = '#2855af';
export const BLUE_TYPE_1 = '#293b64';
export const BLUE_TYPE_2 = '#186999';
export const BLUE_TYPE_3 = '#234187';
export const DARK_BLUE_DEFAULT = '#34588d';
export const DARK_BLUE_TYPE_1 = '#232d4b';
export const DARK_BLUE_TYPE_2 = '#1E4592';
export const GRAY_DEFAULT = '#767676';
export const GRAY_TYPE_1 = '#757575';
export const GRAY_TYPE_2 = '#797979';
export const GRAY_LIGHT_DEFAULT = '#f0f0f0';
export const GRAY_LIGHT_TYPE_1 = '#e0e0e0';
export const GRAY_LIGHT_TYPE_2 = '#e1e6f0';
export const GRAY_LIGHT_TYPE_3 = '#dddddd';
export const GRAY_LIGHT_TYPE_4 = '#A1AFCA';
export const GRAY_LIGHT_TYPE_5 = '#F1F4F9';
export const GRAY_LIGHT_TYPE_6 = '#D1D6DF';
export const YELLOW_DEFAULT = '#fffabe';
export const YELLOW_TYPE_1 = '#fbf499';
export const YELLOW_TYPE_2 = '#FFF0D9';

export const BREADCRUMB = '#4372D0';

export const REFERRAL_BACKGROUND = '#20273D';
export const REFERRAL_TITLE = '#90939E';

export const TEXT_COLOR_DEFAULT = '#2d4471';
