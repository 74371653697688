import React from 'react';
import { useCallbacks } from './SearchModal.hooks';
import { SearchModalView } from './SearchModal.view';

type Props = {
  open: boolean;
  onClose: () => void;
  inputValue: string;
  onSearch: () => void;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  setIsModalSearchOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const SearchModal: React.FC<Props> = ({
  open,
  onClose,
  inputValue,
  onSearch,
  setIsModalSearchOpened,
  onChange,
  onEnter,
}) => {
  const {
    handleSearch,
    handleClear,
    isSearchHistoryOpened,
    searchHistory,
    handleSearchItemClick,
    handleHistoryItemClearIconClick,
    handleOnEnterPress,
  } = useCallbacks({
    onSearch,
    setIsModalSearchOpened,
    onChange,
    inputValue,
    onEnter,
  });

  return (
    <SearchModalView
      onEnter={handleOnEnterPress}
      open={open}
      onClose={onClose}
      inputValue={inputValue}
      onChange={onChange}
      onClear={handleClear}
      onSearch={handleSearch}
      isSearchHistoryOpened={isSearchHistoryOpened}
      searchHistory={searchHistory}
      handleSearchItemClick={handleSearchItemClick}
      handleHistoryItemClearIconClick={handleHistoryItemClearIconClick}
    />
  );
};
