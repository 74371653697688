import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { useTotalPrice } from '@hooks/useTotalPrice';
import { TAppEvents, TAppState } from '@store/index';

export const useCallbacks = () => {
  const { totalPrice } = useTotalPrice();
  const { totalPositionsCount } = useTotalPositionCount();
  const { handleClick } = useHandleClick();

  return {
    totalPrice,
    handleClick,
    totalPositionsCount,
  };
};

const useHandleClick = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate('../cart');
  }, [navigate]);

  return { handleClick };
};

const useTotalPositionCount = () => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const totalPositionsCount = useMemo(() => {
    let counter = 0;

    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].quantity >= 0) {
        counter++;
      }
    }

    return counter;
  }, [cartItems]);

  return { totalPositionsCount };
};
