import { SECONDARY_TEXT_COLOR } from '@theme/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 32px 32px 32px',
    background: '#fff',

    '@media (max-width: 1279px)': {
      paddingTop: 12,
      paddingBottom: 32,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  footerPriceText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 216,
    display: 'block',
  },

  footerPrice: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    maxWidth: 216,

    '& > p': {
      width: 'auto',
      marginRight: 4,
    },
  },

  footerSum: {
    marginTop: 2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 216,
    display: 'block',
    '& span': {
      color: SECONDARY_TEXT_COLOR,
    },
  },
}));
