import React from 'react';
import { ReactComponent as CopySvg } from './assets/copy.svg';
import { useStyles } from './CopyButton.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  toBeCopied: string;
  onClick?: () => void;
  className?: string;
};

const _CopyButton: React.FC<Props> = ({ toBeCopied, onClick, className }) => {
  const { classes, cx } = useStyles();
  const { t } = Localizator.translate();

  const handleClick = () => {
    navigator.clipboard.writeText(toBeCopied).then(() => {
      onClick && onClick();
    });
  };

  return (
    <div
      onClick={handleClick}
      className={cx(classes.wrapper, className)}
      title={t('ProductCard.CopyLink') as string}
    >
      <CopySvg className={classes.icon} />
    </div>
  );
};

export const CopyButton = React.memo(_CopyButton);
