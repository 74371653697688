import React from 'react';
import { useStyles } from './ImageGallery.style';
import { ReactComponent as PrevArrowIcon } from '@assets/Catalog/ProductCard/ImageGallery/prev-arrow.svg';
import { ReactComponent as NextArrowIcon } from '@assets/Catalog/ProductCard/ImageGallery/next-arrow.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import { Galllery } from './Gallery/Gallery.component';

type Props = {
  images?: string[];
  onClick: (imageIndex?: number) => void;
  children?: React.ReactNode;
};

export const ImageGallery: React.FC<Props> = ({
  images,
  onClick,
  children,
}) => {
  const { classes, cx } = useStyles();
  const { isMobile } = useIsMobile();

  if (images?.length) {
    return (
      <div className={classes.wrapper}>
        {children}

        {isMobile && (
          <Galllery
            images={images}
            onImageClick={onClick}
            allowTouchMove={Boolean(images?.length && images.length > 1)}
            noSwiping={Boolean(images?.length && images.length > 1)}
          />
        )}

        {!isMobile && (
          <Galllery
            images={images}
            onImageClick={onClick}
            allowTouchMove={false}
            noSwiping={false}
          />
        )}

        {images.length > 1 && (
          <>
            <div
              className={cx(
                classes.arrow,
                classes.prevArrow,
                'swiper-button-prev-unique',
              )}
            >
              <PrevArrowIcon />
            </div>
            <div
              className={cx(
                classes.arrow,
                classes.nextArrow,
                'swiper-button-next-unique',
              )}
            >
              <NextArrowIcon />
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
};
