import React from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

interface Props extends SwiperProps {
  images: string[];
  onImageClick: (imageIndex: number) => void;
}

const UnMemoGallery: React.FC<Props> = ({ images, onImageClick, ...rest }) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={5}
      navigation={
        images.length > 1
          ? {
              prevEl: '.swiper-button-prev-unique',
              nextEl: '.swiper-button-next-unique',
            }
          : false
      }
      pagination={images.length > 1}
      modules={[Navigation, Pagination]}
      loop={images.length > 1}
      {...rest}
    >
      {images.map((img, idx) => {
        return (
          <SwiperSlide key={img}>
            <div data-testid="product-card-image-item">
              <img
                draggable={false}
                data-testid="product-card-image-item-image"
                onClick={() => onImageClick(idx)}
                src={img}
                alt=""
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export const Galllery = React.memo(UnMemoGallery);
