import { TAppEvents, TAppState } from '@store/index';
import { Localizator } from '@utils/Localizator';
import React from 'react';
import { useStoreon } from 'storeon/react';
import { useStyles } from './CartTopText.style';

type Props = {
  totalPrice: string;
  totalPositionsCount: number;
};

export const CartTextView: React.FC<Props> = ({
  totalPrice,
  totalPositionsCount,
}) => {
  const { currency } = useStoreon<TAppState, TAppEvents>('currency');
  const { classes } = useStyles();
  const { t } = Localizator.translate();

  return (
    <div className={classes.textTop}>
      <div>
        <p
          title={`${totalPrice} ${currency.symbol ? `\u00A0${currency.symbol}` : `\u00A0${currency.name}`}`}
          className={classes.price}
        >
          {totalPositionsCount === 0 ? t('Header.Cart.TopText') : totalPrice}
        </p>
        {totalPositionsCount !== 0 && <p>{currency.symbol ? `\u00A0${currency.symbol}` : `\u00A0${currency.name}`}</p>}
      </div>
    </div>
  );
};
