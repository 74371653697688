import { makeStyles } from 'tss-react/mui';
import { GRAY_LIGHT_TYPE_5, WHITE_DEFAULT } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  checkoutResult: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '@media (max-width: 1279px)': {
      flexDirection: 'row',
      alignItems: 'center',
      height: '64px',
    },
  },

  checkout: {
    width: 380,
    borderRadius: 12,
    padding: '24px 40px 40px',
    flexDirection: 'column',
    alignItems: 'center',
    background: GRAY_LIGHT_TYPE_5,
    position: 'sticky',
    top: 16,
    height: 'fit-content',

    '@media (max-width: 1279px)': {
      width: 'auto',
      height: 64,
      padding: 0,
      background: WHITE_DEFAULT,
      boxShadow: '0px -4px 16px 0px #00000014',
      borderRadius: 0,
    },
  },

  checkoutResultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
}));
