import { useCallback, useMemo } from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import {
  TUseCallbacks,
  TUseGetIcon,
  TUseHandleClickButton,
} from './CategoriesCatalogButton.types';
import BurgerIconDesktop from '@assets/Header/Categories/CategoriesCatalogButton/menu-icon-desktop.svg';
import BurgerIconMobile from '@assets/Header/Categories/CategoriesCatalogButton/menu-icon-mobile.svg';
import CrossIconDesktop from '@assets/Header/Categories/CategoriesCatalogButton/cross-desktop.svg';
import CrossIconMobile from '@assets/Header/Categories/CategoriesCatalogButton/cross-mobile.svg';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

export const useCallbacks = ({ open, handleOpenMenu }: TUseCallbacks) => {
  const { getIcon } = useGetIcon({ open });
  const { isVisible } = useIsButtonVisible();
  const { onClick } = useHandleClickButton({ handleOpenMenu });

  return { getIcon, isVisible, onClick };
};

const useGetIcon = ({ open }: TUseGetIcon) => {
  const { isMobile } = useIsMobile();

  const getIcon = useCallback(() => {
    if (open && isMobile) {
      return CrossIconMobile;
    }

    if (open) {
      return CrossIconDesktop;
    }

    if (isMobile) {
      return BurgerIconMobile;
    }

    return BurgerIconDesktop;
  }, [isMobile, open]);

  return { getIcon };
};

const useHandleClickButton = ({ handleOpenMenu }: TUseHandleClickButton) => {
  const { isMobile } = useIsMobile();
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const onClick = useMemo(() => {
    if (isMobile) {
      if (allCategories?.length > 0) {
        return handleOpenMenu;
      }

      return undefined;
    }

    return handleOpenMenu;
  }, [isMobile, allCategories, handleOpenMenu]);

  return { onClick };
};

const useIsButtonVisible = () => {
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const isVisible = useMemo(() => {
    return allCategories?.length > 0;
  }, [allCategories]);

  return { isVisible };
};
