import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { useIsMobile } from '@hooks/useIsMobile';
import { TAppEvents, TAppState } from '@store/index';
import {
  SEARCH_HISTORY_ADD_ITEM,
  SEARCH_HISTORY_DELETE_ITEM,
} from '@store/searchHistory.store';
import {
  TUseCallbacks,
  TUseHandleBlur,
  TUseHandleClear,
  TUseHandleClick,
  TUseHandleCloseSearchModal,
  TUseHandleEnterPress,
  TUseHandleFocus,
  TUseHandleInputChange,
  TUseHandleMouseOut,
  TUseHandleMouseOver,
  TUseHandleSearch,
  TUseHandleSearchItemClick,
} from './Search.types';

export const useCallbacks = ({
  refetchOnSearchCallback,
  findByCallback,
}: TUseCallbacks) => {
  const { searchHistory } = useStoreon<TAppState, TAppEvents>('searchHistory');

  const { isMobile } = useIsMobile();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputHovered, setIsInputHovered] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isModalSearchOpened, setIsModalSearchOpened] = useState(false);

  const [isSearchHistoryOpened, setIsSearchHistoryOpened] = useState(false);

  const [searchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState(
    searchParams.get('searchBy') || '',
  );

  useEffect(() => {
    if (isInputFocused && !inputValue) {
      setIsSearchHistoryOpened(true);
    }

    if (inputValue) {
      setIsSearchHistoryOpened(false);
    }
  }, [inputValue, isInputFocused]);

  useEffect(() => {
    if (!searchHistory.length) {
      setIsSearchHistoryOpened(false);
    }
  }, [searchHistory]);

  useEffect(() => {
    setIsModalSearchOpened(false);
    setIsSearchHistoryOpened(false);
    setIsInputFocused(false);
    inputRef.current?.blur();
  }, [isMobile]);

  const withSearchIcon = useMemo(() => {
    return !isInputFocused && !Boolean(inputValue);
  }, [isInputFocused, inputValue]);

  const { handleSearch } = useHandleSearch({
    inputRef,
    findByCallback,
    refetchOnSearchCallback,
    inputValue,
  });

  const { handleEnterPress } = useHandleEnterPress({ handleSearch });

  const { handleBlur } = useHandleBlur({
    setIsInputFocused,
    setIsSearchHistoryOpened,
  });

  const { handleFocus } = useHandleFocus({
    setIsInputFocused,
  });

  const { handleClick } = useHandleClick({ setIsModalSearchOpened });

  const { handleClose } = useHandleCloseSearchModal({ setIsModalSearchOpened });

  const { handleInputChange } = useHandleInputChange({ setInputValue });

  const { handleClear } = useHandleClear({ setInputValue });

  const { handleMouseOver } = useHandleMouseOver({ setIsInputHovered });

  const { handleMouseOut } = useHandleMouseOut({ setIsInputHovered });

  const { handleSearchItemClick } = useHandleSearchItemClick({
    refetchOnSearchCallback,
    inputRef,
    inputValue,
  });

  const { handleSearchItemIconClick } = useHandleSearchItemIconClick();

  return {
    isSearchHistoryOpened: isSearchHistoryOpened && searchHistory.length > 0,
    withSearchIcon,

    inputValue,
    setInputValue,

    inputRef,

    isInputHovered,
    setIsInputHovered,
    isInputFocused,
    setIsInputFocused,
    isModalSearchOpened,
    setIsModalSearchOpened,

    handleBlur,
    handleFocus,
    handleInputChange,
    handleSearch,
    handleEnterPress,
    handleClear,
    handleMouseOver,
    handleMouseOut,
    handleSearchItemClick,
    handleSearchItemIconClick,
    handleClick,
    handleClose,
  };
};

export const useHandleSearch = ({
  inputRef,
  findByCallback,
  inputValue,
  refetchOnSearchCallback,
}: TUseHandleSearch) => {
  const { isMobile } = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const handleSearch = useCallback(() => {
    if (isMobile && inputRef) {
      inputRef.current?.blur();
    }

    findByCallback && findByCallback();

    const searchBy = searchParams.get('searchBy') || '';

    if (searchBy === inputValue) {
      refetchOnSearchCallback && refetchOnSearchCallback();
    }

    searchParams.set('searchBy', inputValue);

    setSearchParams(searchParams);

    if (inputValue) {
      dispatch(SEARCH_HISTORY_ADD_ITEM, inputValue);
    }
  }, [
    findByCallback,
    inputValue,
    refetchOnSearchCallback,
    searchParams,
    setSearchParams,
    dispatch,
    inputRef,
    isMobile,
  ]);

  return { handleSearch };
};

export const useHandleEnterPress = ({ handleSearch }: TUseHandleEnterPress) => {
  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  return { handleEnterPress };
};

export const useHandleBlur = ({
  setIsInputFocused,
  setIsSearchHistoryOpened,
}: TUseHandleBlur) => {
  const handleBlur = useCallback(() => {
    setIsSearchHistoryOpened(false);
    setIsInputFocused(false);
  }, [setIsInputFocused, setIsSearchHistoryOpened]);

  return { handleBlur };
};

export const useHandleFocus = ({ setIsInputFocused }: TUseHandleFocus) => {
  const handleFocus = useCallback(() => {
    setIsInputFocused(true);
  }, [setIsInputFocused]);

  return { handleFocus };
};

export const useHandleInputChange = ({
  setInputValue,
}: TUseHandleInputChange) => {
  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(evt.target.value);
    },
    [setInputValue],
  );

  return { handleInputChange };
};

export const useHandleClear = ({ setInputValue }: TUseHandleClear) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const handleClear = useCallback(() => {
    setInputValue('');

    queryClient.removeQueries('price-list');

    searchParams.delete('searchBy');
    setSearchParams(searchParams);
  }, [setSearchParams, searchParams, setInputValue, queryClient]);

  return { handleClear };
};

export const useHandleMouseOver = ({
  setIsInputHovered,
}: TUseHandleMouseOver) => {
  const handleMouseOver = useCallback(() => {
    setIsInputHovered(true);
  }, [setIsInputHovered]);

  return { handleMouseOver };
};

export const useHandleMouseOut = ({
  setIsInputHovered,
}: TUseHandleMouseOut) => {
  const handleMouseOut = useCallback(() => {
    setIsInputHovered(false);
  }, [setIsInputHovered]);

  return { handleMouseOut };
};

export const useHandleSearchItemClick = ({
  refetchOnSearchCallback,
  inputValue,
  inputRef,
}: TUseHandleSearchItemClick) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchItemClick = useCallback(
    (item: string) => {
      if (item === inputValue) {
        refetchOnSearchCallback && refetchOnSearchCallback();
      }

      searchParams.set('searchBy', item);
      setSearchParams(searchParams);

      inputRef.current?.blur();
    },
    [
      searchParams,
      setSearchParams,
      inputValue,
      refetchOnSearchCallback,
      inputRef,
    ],
  );

  return { handleSearchItemClick };
};

export const useHandleSearchItemIconClick = () => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const handleSearchItemIconClick = useCallback(
    (item: string) => {
      dispatch(SEARCH_HISTORY_DELETE_ITEM, item);
    },
    [dispatch],
  );

  return { handleSearchItemIconClick };
};

const useHandleClick = ({ setIsModalSearchOpened }: TUseHandleClick) => {
  const { isMobile } = useIsMobile();

  const handleClick = useCallback(() => {
    if (isMobile) {
      setIsModalSearchOpened(true);
    }
  }, [isMobile, setIsModalSearchOpened]);

  return { handleClick };
};

const useHandleCloseSearchModal = ({
  setIsModalSearchOpened,
}: TUseHandleCloseSearchModal) => {
  const handleClose = useCallback(() => {
    setIsModalSearchOpened(false);
  }, [setIsModalSearchOpened]);

  return { handleClose };
};
