import { Localizator } from '@utils/Localizator';
import React from 'react';

export const CategoriesEmptyError = () => {
  const { t } = Localizator.translate();

  return (
    <div>
      <p>{t('Header.Catalog.Error')}</p>
    </div>
  );
};
