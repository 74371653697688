import React from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import { useStyles } from './CartIconWrapper.style';
import CartIconDesktop from '@assets/Header/Cart/cart-icon-desktop.svg';
import CartIconMobile from '@assets/Header/Cart/cart-icon-mobile.svg';
import { Localizator } from '@utils/Localizator';

type Props = {
  totalPositionsCount: number;
};

export const CartIconWrapperView: React.FC<Props> = ({
  totalPositionsCount,
}) => {
  const { classes, cx } = useStyles();

  const { isMobile } = useIsMobile();

  const { t } = Localizator.translate();

  return (
    <div className={classes.iconWrapper}>
      {totalPositionsCount > 0 && (
        <div className={cx('eclipse', classes.eclipse)}>
          {isMobile && (
            <div className={classes.eclipseText}>{totalPositionsCount}</div>
          )}
        </div>
      )}
      <img
        className={classes.cartImage}
        src={isMobile ? CartIconMobile : CartIconDesktop}
        alt={t('Other.CartAlt')}
      />
    </div>
  );
};
