import { makeStyles } from 'tss-react/mui';
import { DARK_BLUE_TYPE_1, WHITE_DEFAULT, WHITE_TYPE_1 } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'relative',
    fontFamily: 'Montserrat, sans-serif',
    color: DARK_BLUE_TYPE_1,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '6px 12px',
    cursor: 'pointer',
    borderRadius: '8px',
    background: WHITE_DEFAULT,
    transition: '0.05s all',

    '&:hover': {
      background: WHITE_TYPE_1,
    },

    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingRight: '20px',
      display: 'block',

      '@media (max-width: 1279px)': {
        color: 'black',
      },
    },

    '@media (max-width: 1279px)': {
      padding: '14px 16px',
      width: '100%',

      '&:hover': {
        background: WHITE_DEFAULT,
      },
    },
  },

  icon: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translate(-12px, -50%)',
    cursor: 'pointer',

    '@media (max-width: 1279px)': {
      transform: 'translate(0px, -50%)',
    },
  },
}));
