import { TProduct } from '@apptypes/index';
import React from 'react';
import { useStyles } from './ProductCardBreadcrumbs.style';
import { CategoryTag } from '@components/shared/CategoryTag/CategoryTag.component';
import { CopyButton } from '@components/shared/CopyButton/CopyButton.component';
import {
  useGetPreparedProductCardCategoriesBreadcrumbs,
  useOnCategoryTagClick,
  useShowToaster,
} from './ProductCardBreadcrumbs.hooks';
import {
  useGetProductCardCategoriesBreadcrumbs,
  useSendLinkToProductCopyEventMutation,
} from '@hooks/useApi.hooks';
import { useOnTouchStartCapture } from '../ProductCard.hooks';
import { useLinkPath } from '@hooks/useLinkPath.hooks';

type Props = {
  product: TProduct;
};

export const ProductCardBreadcrumbs: React.FC<Props> = ({ product }) => {
  const { classes } = useStyles();

  const {
    query: {
      data: productCardCategoriesBreadcrumbsData,
      isError: productCardCategoriesBreadcrumbsIsError,
    },
  } = useGetProductCardCategoriesBreadcrumbs({
    productId: product?.id || '',
    enabled: true,
  });

  const { isPublicLink, linkPath } = useLinkPath();

  const {
    mutation: { mutate },
  } = useSendLinkToProductCopyEventMutation({
    isPublic: isPublicLink,
    productId: product.id || '',
    link: linkPath || '',
    onError: () => {
      console.error('cannot send copy event');
    },
  });

  const { handleShowToaster } = useShowToaster(classes.toasterText);
  const { onTouchStartCapture } = useOnTouchStartCapture();
  const { handleCategoryTagClick } = useOnCategoryTagClick();

  const handleOnCopyButtonClick = () => {
    handleShowToaster();
    mutate();
  };

  const { preparedCategories: categories } =
    useGetPreparedProductCardCategoriesBreadcrumbs({
      categories: [...(productCardCategoriesBreadcrumbsData || [])],
    });

  if (productCardCategoriesBreadcrumbsIsError) {
    return null;
  }

  return (
    <div className={classes.tagsWrapper}>
      <div className={classes.tagsInnerWrapper}>
        {categories.map((cat) => {
          return (
            <CategoryTag
              label={cat.name}
              id={cat.id}
              onClick={handleCategoryTagClick}
              className={classes.tagsItem}
              key={cat.id}
              // Нужно, иначе не будет работать анимация
              onTouchStartCapture={onTouchStartCapture}
            />
          );
        })}
      </div>

      <div>
        <CopyButton
          toBeCopied={window.location.href}
          onClick={handleOnCopyButtonClick}
        />
      </div>
    </div>
  );
};
