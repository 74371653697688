import { makeStyles } from 'tss-react/mui';
import {
  PRIMARY_ERROR_COLOR,
  PRIMARY_TEXT_COLOR,
  RED_TYPE_1,
} from '../../../../theme/theme';

type Props = {
  isError?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isError }) => ({
  input: {
    border: 'none',
    width: 64,
    height: 36,
    textAlign: 'center',
    outline: 'none',
    color: isError ? PRIMARY_ERROR_COLOR : PRIMARY_TEXT_COLOR,
    fontFamily: 'ALSHauss,sans-serif',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  redBorder: {
    border: `1px solid ${RED_TYPE_1} !important`,
  },
}));
