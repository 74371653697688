import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  menu: {
    overflow: 'auto',

    '& .MuiPaper-root': {
      marginTop: 8,
    },

    '& .MuiList-root': {
      maxHeight: 576,
      height: '70vh',
      maxWidth: 360,
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: 0,

      '@media (max-width:1279px)': {
        height: '100%',
      },

      '@media (max-height:493px)': {
        height: '50vh',
      },

      '& p': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },

  menuLoading: {
    '& .MuiList-root': {
      minWidth: 360,
      minHeight: 200,
    },
  },

  menuWithError: {
    '& .MuiList-root': {
      minWidth: 360,
      minHeight: 200,

      '& p': {
        textOverflow: 'unset',
        overflow: 'auto',
        whiteSpace: 'unset',
        fontSize: '18px',
        padding: '20px',
      },
    },
  },
}));
