import React from 'react';
import { Label } from '@components/shared/Label/Label.component';
import { ShowcaseWarning } from '@components/shared/ShowcaseWarning/ShowcaseWarning.component';
import { PRIMARY_ERROR_COLOR } from '@theme/theme';
import { ReactComponent as WarningSvg } from '@assets/Cart/ShowcaseWarning/showcase-warning.svg';
import { useStyles } from './CartShowcaseWarning.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  animatedClassName: string;
  isError: boolean;
  isOverstock: boolean;
};

export const CartShowcaseWarning: React.FC<Props> = ({
  animatedClassName,
  isError,
  isOverstock,
}) => {
  const { classes, cx } = useStyles();
  const { t } = Localizator.translate();

  return (
    <ShowcaseWarning className={cx(animatedClassName, classes.warning)}>
      <WarningSvg className={classes.warningImage} />

      <Label color={PRIMARY_ERROR_COLOR}>
        {isError && isOverstock && t('Cart.Errors.OverstockError')}
        {isError && !isOverstock && t('Cart.Errors.CommonError')}
      </Label>
    </ShowcaseWarning>
  );
};
