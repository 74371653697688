import {
  GRAY_TYPE_2,
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_DISABLED_COLOR,
} from '@theme/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'relative',

    '& .swiper-slide': {
      width: 'auto',
    },

    '& .swiper-pagination-bullet': {
      transition: '0.3s all',
      background: PRIMARY_DISABLED_COLOR,
      opacity: 1,
      cursor: 'default',
    },

    '& .swiper-pagination-bullet-active': {
      width: 16,
      borderRadius: 8,
      background: PRIMARY_BACKGROUND_COLOR,
    },

    '& .swiper-button-prev': {
      left: 16,
    },

    '& .swiper-button-next': {
      right: 16,
    },

    '& .swiper-pagination': {
      marginTop: '12px',
      lineHeight: 0,
      position: 'static',
      cursor: 'default',
    },
  },

  arrow: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    background: GRAY_TYPE_2,
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    transform: 'translateY(calc(-50% - 10px))',
    userSelect: 'none',

    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },

  prevArrow: {
    left: 16,
  },

  nextArrow: {
    right: 16,
  },
}));
