import { makeStyles } from 'tss-react/mui';
import { BLUE_TYPE_1 } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  textTop: {
    color: BLUE_TYPE_1,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    whiteSpace: 'nowrap',

    '& > div': {
      display: 'flex',
    },
  },

  price: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 130,
  },
}));
