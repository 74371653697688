import React, { FC } from 'react';
import { useCallbacks } from './CartCheckoutData.hooks';
import { Label } from '@components/shared/Label/Label.component';
import { useStyles } from './CartCheckoutData.style';
import { useIsMobile } from '@hooks/useIsMobile';
import { NumberUtils } from '@utils/NumberUtils';

type Props = {
  goodsCount: number;
  positionsCount?: number;
};

export const CartCheckoutData: FC<Props> = (props) => {
  const { positionsCount, goodsCount } = props;
  const { classes } = useStyles();
  const { positionsDeclination, goodsDeclination } = useCallbacks(
    positionsCount,
    goodsCount,
  );

  const { isMobile } = useIsMobile();

  return (
    <div className={classes.checkoutData}>
      <Label
        fontSize={isMobile ? 12 : 14}
        lineHeight={isMobile ? '16px' : '20px'}
      >
        {Boolean(goodsCount) &&
          NumberUtils.getNumberRepresentation(
            parseFloat(goodsCount?.toFixed(4)),
            0,
          )}
        <span> {goodsDeclination ?? ''}, </span>
        {NumberUtils.getNumberRepresentation(positionsCount || 0, 0)}
        <span> {positionsDeclination ?? ''}</span>
      </Label>
    </div>
  );
};
