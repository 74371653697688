import { useMemo } from 'react';
import { TProduct } from '@apptypes/index';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { RegexCollectionMethods } from '@utils/RegexCollectionMethods';

type TUseIsStocksOverflow = {
  inputValue: string;
  preparedProduct: TProduct | undefined;
};

export const useIsStocksOverflow = ({
  inputValue,
  preparedProduct,
}: TUseIsStocksOverflow) => {
  const { withRemains } = useLinkPath();

  const isStocksOverflow = useMemo(() => {
    if (withRemains) {
      const parsed = parseFloat(
        RegexCollectionMethods.replaceAllCommasForDots(inputValue),
      );

      if (
        inputValue &&
        !isNaN(parsed) &&
        typeof preparedProduct?.stock === 'number'
      ) {
        if (parsed <= preparedProduct?.stock) {
          return false;
        }

        return true;
      }
    }

    return false;
  }, [inputValue, preparedProduct, withRemains]);

  return { isStocksOverflow };
};
