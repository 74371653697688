import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  secondaryText: {
    marginTop: 4,
  },

  counterpartyText: {
    marginTop: 4,
  },

  gst: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
