import { makeStyles } from 'tss-react/mui';
import { REFERRAL_BACKGROUND, WHITE_DEFAULT } from '@theme/theme';

type Props = {
  withSearch?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { withSearch }) => ({
  wrapper: {
    background: REFERRAL_BACKGROUND,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    userSelect: 'none',
    zIndex: 2,
    position: 'relative',

    '& a': {
      textDecoration: 'none',
    },

    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },

  headerContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0',
    zIndex: 1,
    background: WHITE_DEFAULT,

    '@media (max-width: 1279px)': {
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0px',
      position: 'relative',
      paddingBottom: withSearch ? 16 : 0,
    },
  },

  header: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: WHITE_DEFAULT,

    '@media (max-width: 1279px)': {
      // TODO FIXME Обязательно убрать, когда будет полный адаптив ОЗ
      maxWidth: '100vw',

      borderBottom: '1px solid #e7e8ec',

      '& .container': {
        height: '100%',
      },
    },
  },

  breadcrumbsNoHeaderWrapper: {
    marginTop: 20,
  },

  thereshold: {
    height: 16,
    zIndex: 2,
    background: 'transparent',
    position: 'relative',
    backgroundColor: WHITE_DEFAULT,

    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },

  cartThereshold: {
    backgroundColor: 'transparent',
  },

  breadcrumbsNoMargin: {
    '& .breadcrumbs': {
      marginTop: 0,
    },
  },
}));
