import React, { useCallback } from 'react';
import {
  TUseCallbacks,
  TUseHandleIconClick,
  TUseHandleItemClick,
} from './SearchHistoryItem.types';

export const useCallbacks = ({
  onIconClick,
  onItemClick,
  item,
}: TUseCallbacks) => {
  const { handleIconClick } = useHandleIconClick({ onIconClick, item });

  const { handleItemClick } = useHandleItemClick({ onItemClick, item });

  return { handleIconClick, handleItemClick };
};

const useHandleIconClick = ({ onIconClick, item }: TUseHandleIconClick) => {
  const handleIconClick = useCallback(
    (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      onIconClick(item);
    },
    [item, onIconClick],
  );

  return { handleIconClick };
};

const useHandleItemClick = ({ onItemClick, item }: TUseHandleItemClick) => {
  const handleItemClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onItemClick(item);
    },
    [item, onItemClick],
  );

  return { handleItemClick };
};
