import React, { HTMLAttributes } from 'react';
import { useStyles } from './ShowcaseWarning.style';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  dataTestId?: string;
  children: React.ReactNode;
  testId?: string;
}

// eslint-disable-next-line react/no-multi-comp
export const ShowcaseWarning = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ className, children, testId = '', ...rest }, ref) => {
      const { classes, cx } = useStyles();

      return (
        <div
          className={cx(classes.warningWrapper, 'animate__animated', className)}
          data-testid={`${testId}-warning-default`}
          {...rest}
          ref={ref}
        >
          {children}
        </div>
      );
    },
  ),
);
