import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '@utils/index';
import querystring from 'query-string';
import {
  ICategory,
  ICurrency,
  ICustomerOrder,
  ILinkInfo,
  IProductsResponse,
  TProduct,
  TProductCardCategoriesBreadcrumb,
} from '@apptypes/index';
import { Localizator } from '@utils/Localizator';

class APIService {
  // eslint-disable-next-line
  isLocalBuild: boolean = process.env.NODE_ENV === 'test' ? true : false; // todo сделать конфиг?

  getBaseLinkUrl(isPublicLink?: boolean, link?: string): String {
    return getBaseUrl(this.isLocalBuild, isPublicLink, link);
  }

  getLinkInfo(isPublicLink: boolean, link?: string): Promise<ILinkInfo> {
    const { t } = Localizator.translate();

    const url = `${this.getBaseLinkUrl(isPublicLink, link)}/linkInfo`;

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        const errObject = JSON.parse(JSON.stringify(err));

        if (errObject.status === 404) {
          const error = {
            errorCode: 404,
            name: t('Errors.LinkNotFound'),
            description: t('Errors.ContactYourSupplier'),
          };

          throw new Error(JSON.stringify(error));
        } else {
          const error = {
            errorCode: 503,
            name: t('Errors.StoreTemporarilyClosed'),
            description: t('Errors.ContactTheSeller'),
          };

          throw new Error(JSON.stringify(error));
        }
      });
  }

  getCategories(isPublicLink?: boolean, link?: string): Promise<ICategory[]> {
    const url = `${this.getBaseLinkUrl(isPublicLink, link)}/categories.json`;

    const { t } = Localizator.translate();

    return axios
      .get(url)
      .then((response) => response.data)
      .catch((e) => {
        const error = {
          errorCode: 503,
          name: t('Errors.StoreTemporarilyClosed'),
          description: t('Errors.ContactTheSeller'),
        };

        throw new Error(JSON.stringify(error));
      });
  }

  async getLocale(isPublicLink?: boolean, link?: string): Promise<string> {
    const url = `${this.getBaseLinkUrl(isPublicLink, link)}/getLocale`;
    const { data } = await axios.get(url, {
      withCredentials: true,
    });

    return data;
  }

  async validateStocks({
    isPublicLink,
    link,
    products,
  }: {
    products: {
      productId: string;
      quantity: number;
    }[];
    isPublicLink?: boolean;
    link?: string;
  }): Promise<{ productId: string; stock: number }[]> {
    const response = await axios.post(
      `${this.getBaseLinkUrl(isPublicLink, link)}/validate-stock`,
      products,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  }

  // eslint-disable-next-line max-params
  async getPriceList(
    isPublicLink: boolean,
    link?: string,
    offset?: number,
    limit?: number,
    search?: string,
    categoryName?: string,
    categoryId?: string,
  ): Promise<IProductsResponse> {
    const url = `${this.getBaseLinkUrl(isPublicLink, link)}/products.json`;

    const { t } = Localizator.translate();

    try {
      const response = await axios.get(url, {
        params: {
          limit: limit ? limit : 25,
          offset: offset ? offset : 0,
          search: search ? search : '',
          category_id: categoryId,
          category: categoryName,
        },
        paramsSerializer: (params_1) => querystring.stringify(params_1),
      });

      return response.data;
    } catch (e: any) {
      const error = {
        errorCode: 503,
        name: t('Errors.StoreTemporarilyClosed'),
        description: t('Errors.ContactTheSeller'),
      };

      throw new Error(JSON.stringify(error));
    }
  }

  async getProduct(
    productId: string,
    isPublicLink: boolean,
    link?: string,
  ): Promise<TProduct> {
    const url = `${this.getBaseLinkUrl(
      isPublicLink,
      link,
    )}/product/${productId}`;

    const { t } = Localizator.translate();

    try {
      const response = await axios.get(url);

      return response.data;
    } catch (e: any) {
      const error = {
        errorCode: 503,
        name: t('Errors.StoreTemporarilyClosed'),
        description: t('Errors.ContactTheSeller'),
      };

      throw new Error(JSON.stringify(error));
    }
  }

  getFullImages(
    isPublicLink?: boolean,
    link?: string,
    product?: TProduct | null,
  ): Promise<string[]> {
    const url = `${this.getBaseLinkUrl(isPublicLink, link)}/getFullImages`;

    const { t } = Localizator.translate();

    return axios
      .put(url, product)
      .then((response) => response.data)
      .catch((e) => {
        const error = {
          errorCode: 503,
          name: t('Errors.StoreTemporarilyClosed'),
          description: t('Errors.ContactTheSeller'),
        };

        throw new Error(JSON.stringify(error));
      });
  }

  async createOrder(
    isPublicLink: boolean,
    link: string,
    products: TProduct[],
    phone: string,
    name: string,
    comment: string,
  ): Promise<ICustomerOrder> {
    const { t } = Localizator.translate();

    const response = await axios
      .post(`${this.getBaseLinkUrl(isPublicLink, link)}/create-order`, {
        products,
        phone,
        name,
        comment,
      })
      .then((response) => {
        if (response.status >= 300) {
          const error = {
            errorCode: 500,
            name: t('Errors.CommonErrorName'),
            description: t('Errors.CommonErrorDescription'),
          };

          throw JSON.stringify(error);
        }

        return response;
      })
      .catch((e) => {
        let errorCode = e.response.status;
        let name;
        let description;

        switch (e.response?.status) {
          case 404: {
            errorCode = 404;
            name = t('Errors.LinkNotFound');
            description = t('Errors.OrderCannotBePlaced');
            break;
          }

          case 503: {
            errorCode = 503;
            name = t('Errors.StoreTemporarilyClosed');
            description = t('Errors.ContactTheSeller');
            break;
          }

          case 400: {
            errorCode = 400;
            name = t('Errors.Error');
            description = t('Errors.ErrorOccured');
            break;
          }

          default: {
            errorCode = 500;
            name = t('Errors.CommonErrorName');
            description = t('Errors.CommonErrorDescription');
            break;
          }
        }

        const error = {
          errorCode,
          name,
          description,
        };

        throw JSON.stringify(error);
      });

    return response.data;
  }

  async getProductCardCategoriesBreadcrumbs(
    productId: string,
    isPublicLink: boolean,
    link?: string,
  ): Promise<TProductCardCategoriesBreadcrumb[]> {
    const url = `${this.getBaseLinkUrl(
      isPublicLink,
      link,
    )}/product/${productId}/categories`;
    const response = await axios.get(url);

    return response.data;
  }

  async sendLinkToCopyProductEvent(
    productId: string,
    isPublicLink: boolean,
    link: string,
  ): Promise<AxiosResponse<unknown>> {
    const url = `${this.getBaseLinkUrl(
      isPublicLink,
      link,
    )}/product/${productId}/sendLinkToProductCopyEvent`;

    const response = await axios.post<unknown>(url);

    return response;
  }

  async getDefaultCurrency(isPublicLink: boolean, link?: string): Promise<ICurrency>{
    const url = `${this.getBaseLinkUrl(
      isPublicLink, link,
    )}/defaultCurrency`;

    const response = await axios.get(url);

    return response.data;
  }
}

export const apiService = new APIService();
