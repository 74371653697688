import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  checkoutTotal: {
    minWidth: 200,

    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));
