import { makeStyles } from 'tss-react/mui';
import { BLUE_DEFAULT, WHITE_DEFAULT } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    background: WHITE_DEFAULT,
    padding: 4,
    border: `1px solid ${BLUE_DEFAULT}`,
    borderRadius: '8px',
    position: 'absolute',
    marginTop: '4px',
    overflow: 'auto',
    maxHeight: '80vh',

    '@media (max-width: 1279px)': {
      border: 'none',
      position: 'relative',
      maxHeight: 'none',
      padding: 0,
      marginTop: 0,
    },
  },
}));
