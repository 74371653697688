import { makeStyles } from 'tss-react/mui';
import {
  BLUE_DEFAULT,
  DARK_BLUE_TYPE_1,
  GRAY_TYPE_1,
  PRIMARY_DIVIDER_COLOR,
} from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  menuItem: {
    padding: '12px 18px 12px 20px',

    '@media (max-width:1279px)': {
      padding: '12px 18px 12px 0px',
      borderBottom: `1px solid ${PRIMARY_DIVIDER_COLOR}`,
    },

    '& p': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
  },

  listWrapper: {
    minWidth: 360,
    overflow: 'auto',

    '@media (max-width:1279px)': {
      // Удалить при адаптиве
      maxWidth: '100vw',
      minWidth: 'auto',
    },
  },

  menuFirstItem: {
    fontFamily: 'Montserrat, sans-serif',
  },

  menuAllProductsItem: {
    '& p': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
    },
  },

  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },

  menuItemAllProducts: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      display: 'block',
      marginRight: 8,
    },
  },

  backArrowImageWrapper: {
    width: 24,
    height: 24,
    marginRight: 8,
  },

  menuBlueItem: {
    color: BLUE_DEFAULT,
  },

  menuCurrentCategoryItem: {
    padding: '12px 18px 12px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    '@media (max-width:1279px)': {
      borderBottom: `1px solid ${PRIMARY_DIVIDER_COLOR}`,
    },
  },

  menuCurrentCategoryItemTopText: {
    width: '100%',

    '& p': {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: DARK_BLUE_TYPE_1,
      textAlign: 'left',

      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },

  menuCurrentCategoryItemBottomText: {
    width: '100%',

    '& p': {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '20px',
      color: GRAY_TYPE_1,
      textAlign: 'left',

      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },

  menuItemText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width:1279px)': {
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
    },

    '& p': {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
  },
}));
