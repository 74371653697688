import React from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import SuccessDesktop from '@assets/Success/SuccessIcon/success-desktop.svg';
import SuccessMobile from '@assets/Success/SuccessIcon/success-mobile.svg';
import { Localizator } from '@utils/Localizator';

export const SuccessIcon = () => {
  const { isMobile } = useIsMobile();

  const { t } = Localizator.translate();

  const SUCCESS_ICON_WIDTH = isMobile ? 72 : 60;
  const SUCCESS_ICON_HEIGHT = isMobile ? 72 : 60;

  return (
    <img
      src={isMobile ? SuccessMobile : SuccessDesktop}
      width={SUCCESS_ICON_WIDTH}
      height={SUCCESS_ICON_HEIGHT}
      alt={t('Other.SuccessAlt')}
    />
  );
};
