import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCallbacks = () => {
  const { handleClick } = useHandleClick();

  return { handleClick };
};

const useHandleClick = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (
      _: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      crumb: { label: string; link: string },
    ) => {
      navigate(crumb.link);
    },
    [navigate],
  );

  return { handleClick };
};
