import { makeStyles } from 'tss-react/mui';
import { REFERRAL_TITLE } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    padding: '10px 0px',

    '& a': {
      display: 'flex',
    },
  },

  logo: {
    width: 92,
    height: 14,
  },

  logoTitle: {
    paddingLeft: 6,
    color: REFERRAL_TITLE,
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
  },
}));
