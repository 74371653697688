import { makeStyles } from 'tss-react/mui';
import { WHITE_DEFAULT } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  mobileMenu: {
    outline: 'none',
    background: WHITE_DEFAULT,

    // 52px - height of sticky header
    height: 'calc(100% - 52px)',
    overflow: 'auto',
  },

  modalRoot: {
    outline: 'none',
    overflow: 'auto',
    zIndex: 1,
    marginTop: 52,
    height: '100%',
    background: WHITE_DEFAULT,
  },
}));
