import React, { HTMLAttributes } from 'react';
import { useStyles } from './Typography.style';

interface Props extends HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  className?: string;
  testId?: string;
}

// eslint-disable-next-line react/no-multi-comp
export const Typography = React.memo(
  React.forwardRef<HTMLParagraphElement, Props>(
    (
      {
        fontSize,
        fontWeight,
        color,
        lineHeight,
        children,
        className,
        testId = '',
        ...rest
      },
      ref,
    ) => {
      const { classes, cx } = useStyles({
        fontSize,
        fontWeight,
        lineHeight,
        color,
      });

      return (
        <p
          data-testid={`${testId}-typography-deafult`}
          className={cx(classes.text, className)}
          {...rest}
          ref={ref}
        >
          {children}
        </p>
      );
    },
  ),
);
