import React, { HTMLAttributes, useCallback } from 'react';
import {
  PRIMARY_LINK_COLOR,
  SECONDARY_DISABLED_COLOR,
  WHITE_DEFAULT,
} from '@theme/theme';
import { Spinner } from '../Spinner/Spinner.component';
import { useStyles } from './Button.style';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  isLoading?: boolean;
  type?: 'button' | 'submit';
  className?: string;
  testId?: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
}

const stylesVariants = {
  spinnerColor: {
    disabled: SECONDARY_DISABLED_COLOR,
    primary: WHITE_DEFAULT,
    secondary: PRIMARY_LINK_COLOR,
  },
};

// eslint-disable-next-line react/no-multi-comp
export const Button = React.memo(
  React.forwardRef<HTMLButtonElement, Props>(
    (
      {
        children,
        isLoading,
        type,
        className,
        disabled,
        testId = '',
        variant = 'primary',
        ...rest
      },
      ref,
    ) => {
      const { cx, classes } = useStyles({ variant, isLoading });

      const preventClickIfLoading = useCallback(
        (
          e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          cb?: React.MouseEventHandler<HTMLButtonElement>,
        ) => {
          if (isLoading) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            cb && cb(e);
          }
        },
        [isLoading],
      );

      const spinnerColor = disabled
        ? stylesVariants.spinnerColor.disabled
        : stylesVariants.spinnerColor[variant];

      return (
        <button
          data-testid={`${testId}-button-default`}
          type={type}
          disabled={disabled}
          className={cx(classes.button, className, {
            [classes.loading]: isLoading,
          })}
          {...rest}
          onClick={(e) => preventClickIfLoading(e, rest.onClick)}
          ref={ref}
        >
          <span className={classes.text}>{children}</span>

          {isLoading && <Spinner color={spinnerColor} testId={testId} />}
        </button>
      );
    },
  ),
);
