import React from 'react';
import { useStyles } from './CategoryTag.style';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { BodyText } from '@lognex/ui-typography';

type Props = {
  label: string;
  id: string;
  onClick?: (id: string) => void;
  className?: string;
  onTouchStartCapture: (e: React.TouchEvent<HTMLDivElement>) => void;
};

const _CategoryTag: React.FC<Props> = ({
  label,
  id,
  className,
  onClick,
  onTouchStartCapture,
}) => {
  const { classes, cx } = useStyles();

  const handleClick = () => {
    onClick && onClick(id);
  };

  return (
    <div
      className={cx(classes.wrapper, className)}
      onClick={handleClick}
      onTouchStartCapture={onTouchStartCapture}
    >
      <div title={label}>
        <BodyText className={classes.text}>{label}</BodyText>
      </div>
      <ArrowIcon className={classes.icon} />
    </div>
  );
};

export const CategoryTag = React.memo(_CategoryTag);
