import React from 'react';
import {
  PRIMARY_ERROR_COLOR,
  SECONDARY_HOVER_COLOR,
} from '../../../theme/theme';
import { Label } from '../Label/Label.component';
import { AddButton } from './AddButton/AddButton.component';
import { DeleteButton } from './DeleteButton/DeleteButton.component';
import { Input } from './Input/Input.component';
import { useCallbacks } from './NumberInput.hooks';
import { useStyles } from './NumberInput.style';
import { MinusButton } from './MinusButton/MinusButton.component';
import { Trans } from 'react-i18next';
import { Localizator } from '@utils/Localizator';

type Props = {
  value?: string;
  onIncrement: () => void;
  onDecrement: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onDelete?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  borders?: boolean;
  isError?: boolean;
  isAddButtonDisabled?: boolean;
  showRemainsData?: boolean;
  isStocksOverflow?: boolean;
  title?: string;
  testId?: string;
};

export const NumberInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onIncrement,
      onDecrement,
      onPaste,
      onChange,
      onBlur,
      onFocus,
      onDelete,
      onKeyDown,
      borders,
      isError,
      isAddButtonDisabled,
      showRemainsData,
      isStocksOverflow,
      title,
      testId = '',
    },
    ref,
  ) => {
    const { t } = Localizator.translate();

    const { handleBlur, handleFocus, isFocused, showFullControls } =
      useCallbacks({ onBlur, onFocus, value });

    const { classes } = useStyles({ showDeleteButton: Boolean(onDelete) });

    return (
      <div className={classes.wrapper}>
        {Boolean(showFullControls || isFocused) && (
          <div>
            <div className={classes.deleteAndInputAndButtons}>
              {onDelete && <DeleteButton onClick={onDelete} testId={testId} />}
              <div
                data-testid={`${testId}-number-input-with-buttons`}
                className={classes.inputAndButtons}
              >
                <MinusButton onClick={onDecrement} testId={testId} />
                <Input
                  borders={borders}
                  onBlur={handleBlur}
                  onPaste={onPaste}
                  value={value}
                  onChange={onChange}
                  isError={isError}
                  onFocus={handleFocus}
                  onKeyDown={onKeyDown}
                  ref={ref}
                  testId={testId}
                />
                <AddButton
                  isDisabled={isAddButtonDisabled}
                  onClick={onIncrement}
                  testId={testId}
                />
              </div>
            </div>
            {showRemainsData && (
              <div data-testid={`${testId}-number-input-remains`}>
                <div
                  title={
                    t('Inputs.StocksInfo', {
                      title,
                    }) as string
                  }
                  className={classes.remains}
                >
                  <Label
                    fontSize={12}
                    lineHeight="16px"
                    fontWeight={400}
                    color={
                      isStocksOverflow
                        ? PRIMARY_ERROR_COLOR
                        : SECONDARY_HOVER_COLOR
                    }
                    testId={testId}
                  >
                    <Trans
                      // @ts-ignore
                      t={t}
                      i18nKey="Inputs.StocksInfo"
                      values={{
                        title,
                      }}
                      defaults="в наличии {{title}}"
                    />
                  </Label>
                </div>
              </div>
            )}
          </div>
        )}
        {!Boolean(showFullControls || isFocused) && (
          <AddButton onClick={onIncrement} testId={testId} />
        )}
      </div>
    );
  },
);
