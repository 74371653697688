import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

export const useGetCurrentCategory = () => {
  const [searchParams] = useSearchParams();
  const { allCategories } = useStoreon<TAppState, TAppEvents>('allCategories');

  const categoryId = searchParams.get('categoryId');

  const currentCategory = useMemo(() => {
    return allCategories?.find((cat) => cat.id === categoryId) || null;
  }, [allCategories, categoryId]);

  return { currentCategory };
};
