import React, { FC } from 'react';
import { TBreadcrumb } from '@apptypes/index';
import { useCallbacks } from './Header.hooks';
import { HeaderView } from './Header.view';
import { Localizator } from '@utils/Localizator';

type Props = {
  withReferral?: boolean;
  withCaregories?: boolean;
  withSearch?: boolean;
  withCart?: boolean;
  withHeader?: boolean;
  withBackButton?: boolean;

  withTitle?: boolean;
  titlePrefix?: string;

  subTitle?: string;

  withBreadcrumbs?: boolean;
  breadcrumbs?: TBreadcrumb[] | null;

  isCart?: boolean;

  totalPrice?: string;

  findByCallback?: () => void;
  refetchOnSearchCallback?: () => void;

  __containerClassName?: string;
};

export const Header: FC<Props> = (props) => {
  const {
    withReferral,
    withCaregories,
    withSearch,
    withTitle,
    titlePrefix,
    withCart,
    withBackButton,
    findByCallback,
    subTitle,
    refetchOnSearchCallback,
    withBreadcrumbs,
    breadcrumbs,
    withHeader,
    isCart,
    __containerClassName,
  } = props;

  const { t } = Localizator.translate();

  const { title, open, setAnchorEl, anchorEl, withLogo } = useCallbacks({
    titlePrefix,
  });

  return (
    <HeaderView
      withLogo={withLogo}
      withReferral={withReferral}
      withHeader={withHeader}
      withTitle={withTitle}
      withCaregories={withCaregories}
      withSearch={withSearch}
      withCart={withCart}
      withBreadcrumbs={withBreadcrumbs}
      withBackButton={withBackButton}
      findByCallback={findByCallback}
      refetchOnSearchCallback={refetchOnSearchCallback}
      breadcrumbs={breadcrumbs}
      isCart={isCart}
      title={title}
      subTitle={subTitle}
      isCategoriesOpened={open}
      catetoriesAnchorEl={anchorEl}
      setCategoriesAnchorEl={setAnchorEl}
      __containerClassName={__containerClassName}
      referralLink={t('Other.REFERRAL_LINK') as string}
    />
  );
};
