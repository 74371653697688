import { NEW_BOOTSTRAP_VALUE, OLD_BOOTSTRAP_VALUE } from './../../constants';
import { makeStyles } from 'tss-react/mui';

type Props = {
  withNewBootstrap?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { withNewBootstrap }) => ({
  content: {
    width: withNewBootstrap ? NEW_BOOTSTRAP_VALUE : OLD_BOOTSTRAP_VALUE,
    maxWidth: withNewBootstrap ? NEW_BOOTSTRAP_VALUE : OLD_BOOTSTRAP_VALUE,
    minWidth: withNewBootstrap ? NEW_BOOTSTRAP_VALUE : OLD_BOOTSTRAP_VALUE,
    margin: '0 auto',

    '@media (max-width: 1279px)': {
      width: 'auto',
      minWidth: 'unset',
      padding: '0px 16px',
      // TODO FIXME Обязательно убрать, когда будет полный адаптив ОЗ
      maxWidth: '100vw',

      marginLeft: 0,
    },
  },
}));
