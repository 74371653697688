import { useMemo } from 'react';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { NumberUtils } from '@utils/NumberUtils';

export const useTotalPrice = () => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const totalPrice = useMemo(() => {
    let price = 0;

    for (let i = 0; i < cartItems.length; i++) {
      price += cartItems[i].price * cartItems[i].quantity;
    }

    return NumberUtils.getNumberRepresentation(
      NumberUtils.round(price, 2),
      2,
      2,
    );
  }, [cartItems]);

  return { totalPrice };
};
