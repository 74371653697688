import { MenuItem } from '@mui/material';
import React from 'react';
import { TProductFolder } from '@apptypes/index';
import { TMenuItem } from '../../Categories.component';
import { useStyles } from '../CategoriesMenuContent.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  currentCategory: TProductFolder;
};

export const AllGoodMenuItemView: React.FC<Props> = ({
  onMenuItemClick,
  currentCategory,
}) => {
  const { classes } = useStyles();
  const { t } = Localizator.translate();

  return (
    <MenuItem
      onClick={(evt) =>
        onMenuItemClick(evt, {
          id: currentCategory?.id,
          isWithSubcategory: false,
          toBack: false,
        })
      }
      disableRipple
      className={classes.menuCurrentCategoryItem}
      title={currentCategory.name}
    >
      <div className={classes.menuCurrentCategoryItemTopText}>
        <p>{currentCategory.name}</p>
      </div>
      <div className={classes.menuCurrentCategoryItemBottomText}>
        <p>{t('Header.Catalog.AllProductsOfThisCategory')}</p>
      </div>
    </MenuItem>
  );
};
