import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    borderRadius: '8px',
    background: '#F5F5F5',
    padding: '4px 6px',
    color: '#5F6D79',
    display: 'flex',
    alignItems: 'center',
    maxHeight: 24,
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      background: '#E7EAF0',

      '&:active': {
        background: '#F1F4F9',
      },
    },
  },

  icon: {
    display: 'block',
    minWidth: 8,
    maxWidth: 8,

    minHeight: 8,
    maxHeight: 8,

    marginLeft: 4,
  },

  text: {
    fontSize: '12px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '16px !important',
  },
}));
