import React, { FC, ReactNode } from 'react';
import { useStyles } from './Container.style';

type Props = {
  children: ReactNode;
  className?: string;
  // Когда контейнер приминить только для мобильной версии
  onlyMobile?: boolean;
  // Когда не применять контейнер
  skipContainer?: boolean;
};

export const Container: FC<Props> = ({
  children,
  className,
  onlyMobile,
  skipContainer,
}) => {
  // Эту логику надо убрать когда везде будет одинаковая сетка
  const withNewBootstrap = Boolean(className);

  const { classes, cx } = useStyles({ withNewBootstrap });

  if (skipContainer) {
    return <>{children}</>;
  }

  return (
    <div
      className={cx('container', className, {
        [classes.content]: Boolean(!className && !onlyMobile),
      })}
    >
      {children}
    </div>
  );
};
