import { useGetFullImages } from '@hooks/useApi.hooks';
import { TProduct } from '@apptypes/index';
import { useCallback } from 'react';
import Viewer from 'viewerjs';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { GALLERY_SET_ITEMS, GALLERY_SET_OPENED_ID } from '@store/gallery.store';

export type TUseImageGallery = {
  product: TProduct;
  enabled: boolean;
};

export const useImageGallery = ({ product, enabled }: TUseImageGallery) => {
  const { dispatch, galleryItems } = useStoreon<TAppState, TAppEvents>(
    'galleryItems',
  );

  const {
    query: { data: images, isFetching, refetch },
  } = useGetFullImages({
    id: product?.id,
    enabled: Boolean(enabled && !product?.isKit),
    product,
    cacheTime: Infinity,
    staleTime: Infinity,

    onSuccess: (response) => {
      dispatch(GALLERY_SET_ITEMS, { key: product.id, value: response });
    },
  });

  const getViewer = useCallback(() => {
    const gallery = document.getElementById(
      '__Viewerjs_gallery_images',
    ) as HTMLDivElement;

    // @ts-ignore
    // https://github.com/fengyuanchen/viewerjs/issues/549
    const viewer = new Viewer(gallery.cloneNode(true), {
      url: 'src',
      title: false,
      fullscreen: false,
      movable: false,
      rotatable: false,
      toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        oneToOne: 0,
        reset: 1,
        prev: 1,
        play: {
          show: 0,
        },
        next: 1,
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
      hide: () => {
        dispatch(GALLERY_SET_OPENED_ID, undefined);
      },
    });

    return viewer;
  }, [dispatch]);

  const handleClick = useCallback(
    (imageIndex?: number) => {
      dispatch(GALLERY_SET_OPENED_ID, product.id);

      const view = () => {
        setTimeout(() => {
          const viewer = getViewer();

          viewer?.view(imageIndex || 0);
        }, 100);
      };

      if (galleryItems.get(product.id)) {
        view();
      } else {
        refetch().then(() => {
          view();
        });
      }
    },
    [getViewer, dispatch, product, refetch, galleryItems],
  );

  return { handleClick, isFetching, images };
};
