import { makeStyles } from 'tss-react/mui';

type Props = {
  isPublicLink?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isPublicLink }) => ({
  wrapper: {
    marginTop: 24,
    marginBottom: 40,
    height: isPublicLink ? '312px' : '120px',
    width: 320,

    '@media (max-width: 601px)': {
      width: '100%',
    },
  },

  submitWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  buttonWrapper: {
    width: 'max-content',
    position: 'relative',
  },

  errorInnerWrapper: {
    position: 'absolute',
    left: '100%',
    marginLeft: 24,
    top: -4,
    width: 'max-content',
  },
}));
