import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Textarea } from '@components/shared/Textarea/Textarea.component';
import { TextInput } from '@components/shared/TextInput/TextInput.component';
import { TFieldValues } from '../../Confirm.types';
import { useStyles } from './ConfirmFormItem.style';

type Props = {
  name: keyof TFieldValues;
  control: Control<TFieldValues, any>;
  errorMessage?: string;
  label: { text: string; required?: boolean; id: string };
  additionalText?: string;
  handleBlur: (onBlur: () => void, value: string, name: string) => void;
  handleFocus: () => void;
  isFocused: boolean;
  type: 'input' | 'textarea';
};

export const ConfirmFormItemView: React.FC<Props> = ({
  name,
  control,
  errorMessage,
  label,
  additionalText,
  handleBlur,
  handleFocus,
  isFocused,
  type,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.inputWrapper, {
        [classes.textInputWrapper]: type === 'input',
        [classes.teatareaWrapper]: type === 'textarea',
      })}
    >
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const isError = !isFocused && Boolean(fieldState.error);

          const onBlur = () => {
            handleBlur(field.onBlur, field.value, field.name);
          };

          if (type === 'input') {
            return (
              <TextInput
                bottomText={{
                  error: isError,
                  text: isError ? errorMessage : additionalText,
                }}
                label={label}
                {...field}
                onBlur={onBlur}
                onFocus={handleFocus}
              />
            );
          }

          return (
            <Textarea
              bottomText={{
                error: isError,
                text: isError ? errorMessage : additionalText,
              }}
              label={label}
              {...field}
              onBlur={onBlur}
              onFocus={handleFocus}
            />
          );
        }}
      />
    </div>
  );
};
