import { makeStyles } from 'tss-react/mui';
import {
  PRIMARY_ERROR_COLOR,
  PRIMARY_OUTLINE_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_BORDER_COLOR,
  SECONDARY_TEXT_COLOR,
  WHITE_DEFAULT,
} from '@theme/theme';

type Props = {
  withError: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { withError }) => ({
  label: {
    marginBottom: 4,
    height: 20,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    fontFeatureSettings: '"tnum" on, "lnum" on',
    color: PRIMARY_TEXT_COLOR,
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'ALSHauss,sans-serif',
  },

  asterisk: {
    color: PRIMARY_ERROR_COLOR,
  },

  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 16px 10px 12px',
    boxSizing: 'border-box',
    background: WHITE_DEFAULT,
    border: `1px solid ${
      withError ? PRIMARY_ERROR_COLOR : SECONDARY_BORDER_COLOR
    }`,
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    transition: '0.15s all',
    color: PRIMARY_TEXT_COLOR,
    height: 40,

    '&:hover': {
      color: withError ? PRIMARY_TEXT_COLOR : SECONDARY_TEXT_COLOR,

      border: `1px solid ${
        withError ? PRIMARY_ERROR_COLOR : PRIMARY_OUTLINE_COLOR
      }`,
    },

    '&:focus': {
      outline: 'none',

      color: `${PRIMARY_TEXT_COLOR} !important`,

      border: `1px solid ${
        withError ? PRIMARY_ERROR_COLOR : PRIMARY_OUTLINE_COLOR
      }`,
    },
  },

  errorWrapper: {
    marginTop: 4,
  },
}));
