import React, { useEffect, useRef } from 'react';
import { TBreadcrumb } from '@apptypes/index';
import { useStyles } from './Breaccrumbs.style';
import { ReactComponent as BreadcrumbIcon } from '@assets/breadcrumb-arrow.svg';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

type Props = {
  breadcrumbs: TBreadcrumb[];
  onClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    item: TBreadcrumb,
  ) => void;
};

export const BreadcrumbsView: React.FC<Props> = ({ breadcrumbs, onClick }) => {
  const { classes, cx } = useStyles();
  const ref = useRef<SwiperRef>(null);

  useEffect(() => {
    ref.current?.swiper.slideTo(breadcrumbs.length - 1);
  }, [breadcrumbs]);

  return (
    <div>
      <ul className={cx(classes.list, 'breadcrumbs')}>
        <Swiper
          modules={[FreeMode]}
          slidesPerView="auto"
          freeMode={{
            enabled: true,
            momentum: false,
          }}
          initialSlide={breadcrumbs.length - 1}
          resistanceRatio={0}
          speed={0}
          grabCursor
          ref={ref}
        >
          {breadcrumbs.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <li
                  className={cx(classes.item, {
                    [classes.withTextDecoration]:
                      index !== breadcrumbs.length - 1,
                  })}
                >
                  <span onClick={(e) => onClick(e, item)}>{item.label}</span>

                  {Boolean(index !== breadcrumbs.length - 1) && (
                    <BreadcrumbIcon className={classes.icon} />
                  )}
                </li>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ul>
    </div>
  );
};
