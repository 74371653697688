import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { Container } from '@components/Container/Container.component';
import { Header } from '@components/Header/Header.component';
import { Button } from '@components/shared/Button/Button.component';
import { ErrorDefault } from '@components/shared/Error/ErrorDefault.component';
import { useIsMobile } from '@hooks/useIsMobile';
import { TAppEvents, TAppState } from '@store/index';
import { useConfirm } from './Confirm.hooks';
import { useStyles } from './Confirm.style';
import { ConfirmForm } from './ConfirmForm/ConfirmForm.component';
import { ConfirmMobileTitle } from './ConfirmMobileTitle/ConfirmMobileTitle.component';
import { Localizator } from '@utils/Localizator';

export const ConfirmPage = () => {
  const { breadcrumbs, form, onSubmit, isError, isLoading, message } =
    useConfirm();

  const { t } = Localizator.translate();

  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const navigate = useNavigate();
  const productsMapLength = cartItems.length;

  useEffect(() => {
    if (productsMapLength <= 0) {
      navigate('../catalog');
    }
  }, [navigate, productsMapLength]);

  const { classes, cx } = useStyles();

  const { isMobile } = useIsMobile();

  if (productsMapLength <= 0) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <div>
        <Header
          withBackButton
          withHeader={isMobile}
          withReferral
          withTitle
          withBreadcrumbs
          breadcrumbs={breadcrumbs}
          isCart
          titlePrefix={t('Confirmation.TitlePrefix') as string}
          __containerClassName={'newBootstrap'}
        />

        <form onSubmit={onSubmit}>
          <Container className={cx('newBootstrap', classes.wrapper)}>
            {isMobile && <ConfirmMobileTitle />}

            <ConfirmForm
              isError={isError}
              isLoading={isLoading}
              message={message}
            />
          </Container>

          {isMobile && (
            <div className={classes.footer}>
              <div className={classes.footerContent}>
                {isError && (
                  <div>
                    <ErrorDefault
                      withIcon={navigator.onLine}
                      className={classes.errorMessage}
                    >
                      {message}
                    </ErrorDefault>
                  </div>
                )}

                <div className={classes.footerButtonWrapper}>
                  <Button type="submit" isLoading={isLoading}>
                    {t('Confirmation.PlaceOrderButton')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </FormProvider>
  );
};
