import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { SEARCH_HISTORY_DELETE_ITEM } from '@store/searchHistory.store';
import {
  TUseCallbacks,
  TUseHandleClear,
  TUseHandleSearch,
  TUseHandleSearchItemClick,
  TUseIsSearchHistoryOpened,
  TUseOnEnterPress,
} from './SearchModal.types';

export const useCallbacks = ({
  onSearch,
  setIsModalSearchOpened,
  onChange,
  inputValue,
  onEnter,
}: TUseCallbacks) => {
  const { searchHistory } = useStoreon<TAppState, TAppEvents>('searchHistory');

  const { handleSearch } = useHandleSearch({
    setIsModalSearchOpened,
    onSearch,
  });

  const { handleClear } = useHandleClear({ onChange });
  const { handleHistoryItemClearIconClick } =
    useHandleHistoryItemClearIconClick();
  const { isSearchHistoryOpened } = useIsSearchHistoryOpened({ inputValue });
  const { handleSearchItemClick } = useHandleSearchItemClick({
    setIsModalSearchOpened,
    onChange,
    onSearch,
  });
  const { handleOnEnterPress } = useOnEnterPress({
    setIsModalSearchOpened,
    onEnter,
  });

  return {
    handleSearch,
    handleClear,
    handleHistoryItemClearIconClick,
    isSearchHistoryOpened,
    searchHistory,
    handleSearchItemClick,
    handleOnEnterPress,
  };
};

const useHandleSearchItemClick = ({
  onChange,
  onSearch,
  setIsModalSearchOpened,
}: TUseHandleSearchItemClick) => {
  const [doSearch, setDoSearch] = useState(false);

  const handleSearchItemClick = useCallback(
    (value: string) => {
      const mockEvent = {
        target: {
          value,
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onChange(mockEvent);
      setDoSearch(true);
    },
    [onChange],
  );

  useEffect(() => {
    if (doSearch) {
      onSearch();
      setIsModalSearchOpened(false);
    }
  }, [doSearch, onSearch, setIsModalSearchOpened]);

  return { handleSearchItemClick };
};

const useHandleClear = ({ onChange }: TUseHandleClear) => {
  const handleClear = useCallback(() => {
    const mockEvent = {
      target: {
        value: '',
      },
    } as React.ChangeEvent<HTMLInputElement>;

    onChange(mockEvent);
  }, [onChange]);

  return { handleClear };
};

const useHandleSearch = ({
  setIsModalSearchOpened,
  onSearch,
}: TUseHandleSearch) => {
  const handleSearch = useCallback(() => {
    onSearch();
    setIsModalSearchOpened(false);
  }, [onSearch, setIsModalSearchOpened]);

  return { handleSearch };
};

const useIsSearchHistoryOpened = ({
  inputValue,
}: TUseIsSearchHistoryOpened) => {
  const { searchHistory } = useStoreon<TAppState, TAppEvents>('searchHistory');

  const isSearchHistoryOpened = useMemo(() => {
    return Boolean(
      searchHistory?.length && searchHistory.length > 0 && !inputValue,
    );
  }, [inputValue, searchHistory]);

  return { isSearchHistoryOpened };
};

const useHandleHistoryItemClearIconClick = () => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const handleHistoryItemClearIconClick = useCallback(
    (value: string) => {
      dispatch(SEARCH_HISTORY_DELETE_ITEM, value);
    },
    [dispatch],
  );

  return { handleHistoryItemClearIconClick };
};

const useOnEnterPress = ({
  onEnter,
  setIsModalSearchOpened,
}: TUseOnEnterPress) => {
  const handleOnEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') {
        onEnter(e);
        setIsModalSearchOpened(false);
      }
    },
    [onEnter, setIsModalSearchOpened],
  );

  return { handleOnEnterPress };
};
