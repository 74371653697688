import { createStoreon } from 'storeon';
import { storeonDevtools } from 'storeon/devtools';
import {
  ICartUserInfoEvents,
  ICartUserInfoState,
  cartUserInfo,
} from './cartUserInfo.store';
import {
  categories,
  ICategoriesState,
  ICategoryEvents,
} from './categories.store';
import {
  searchHistory,
  ISearchHistoryEvents,
  ISearchHistoryState,
} from './searchHistory.store';
import { gallery, IGalleryEvents, IGalleryState } from './gallery.store';
import { cart, ICartEvents, ICartState } from './cart.store';
import { locale, ILocaleEvents, ILocaleState } from './locale.store';
import { currency, ICurrencyEvents, ICurrencyState } from './currency.store';

export type TAppState = ICategoriesState &
  ICartUserInfoState &
  ISearchHistoryState &
  ICartState &
  ILocaleState &
  IGalleryState &
  ICurrencyState &
  ICurrencyEvents;

export type TAppEvents = ICategoryEvents &
  ICartUserInfoEvents &
  ISearchHistoryEvents &
  ICartEvents &
  ILocaleEvents &
  IGalleryEvents &
  ICurrencyEvents;

export const store = createStoreon<TAppState, TAppEvents>([
  categories,
  cartUserInfo,
  searchHistory,
  cart,
  locale,
  gallery,
  currency,
  process.env.NODE_ENV !== 'production' && storeonDevtools,
]);
