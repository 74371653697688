import React from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import { TProduct } from '@apptypes/index';
import { Label } from '@components/shared/Label/Label.component';
import { Typography } from '@components/shared/Typography/Typography.component';
import { ImageGallery } from './ImageGallery/ImageGallery.component';
import { useProductRowCard } from './ProductRowCard.hooks';
import { useStyles } from './ProductRowCard.style';
import { ProductRowCardPrice } from './ProductRowCardPrice/ProductRowCardPrice.component';

type Props = {
  product: TProduct;
  onDelete?: () => void;
  isFullWidth?: boolean;
  className?: string;
  currency: string;
  imageUrl?: string;
  imageIsLoading?: boolean;
  onTitleClick?: () => void;
  onGalleryClick?: () => void;
  useImageGallery?: boolean;
  images?: string[];

  // Input
  inputValue?: string;
  handleBlur?: () => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
  handleDecrement: () => void;
  handleIncrement: () => void;
  handlePaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  handleFocus?: () => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  borders?: boolean;
  showRemainsData?: boolean;
  isStocksOverflow?: boolean;

  // Testids
  testId?: string;
};

export const ProductRowCard: React.FC<Props> = ({
  product,
  onDelete,
  isFullWidth,
  className,
  currency,
  imageIsLoading,
  imageUrl,
  onTitleClick,
  images,
  onGalleryClick,
  useImageGallery,

  // Input
  inputValue,
  handleBlur,
  handleChange,
  handleDecrement,
  handleIncrement,
  handlePaste,
  handleFocus,
  handleKeyDown,
  borders,
  showRemainsData,
  isStocksOverflow,

  testId = '',
  ...rest
}) => {
  const shouldCenterPrice = product.quantity <= 0;

  const { classes, cx } = useStyles({
    isFullWidth,
    titlePointer: Boolean(onTitleClick),
    withImage: Boolean(imageUrl),
  });

  const { isMobile } = useIsMobile();

  // TODO написать тесты на хуки
  const { uomLabel, articleAndCode } = useProductRowCard({
    product,
    currency,
  });

  return (
    <div
      data-testid={`${testId}-productRowCard-default`}
      className={cx(classes.productRowCardWrapper, className)}
      {...rest}
    >
      <div
        className={cx(classes.imageWrapper, {
          [classes.fullWidthImage]: isFullWidth && !isMobile,
          [classes.shortWidthImage]: !isFullWidth && !isMobile,
          [classes.mobileWidthImage]: isMobile,
        })}
      >
        <ImageGallery
          imageUrl={imageUrl}
          isFullWidth={isFullWidth}
          product={product}
          imageIsLoading={imageIsLoading}
          onGalleryClick={onGalleryClick}
          useImageGallery={useImageGallery}
          testId={testId}
        />
      </div>
      <div className={classes.rightSideBlock}>
        <div className={classes.nameAndArticleWrapper}>
          <div
            onClick={onTitleClick}
            title={product.name}
            className={classes.nameWrapper}
          >
            <Typography
              fontWeight={400}
              fontSize={14}
              lineHeight="20px"
              testId={testId}
            >
              {product.name}
            </Typography>
          </div>
          <div className={classes.articleAndCode}>
            <Label
              title={articleAndCode}
              fontSize={12}
              lineHeight="16px"
              testId={testId}
            >
              {articleAndCode}
            </Label>
          </div>
        </div>
        {!isMobile && (
          <ProductRowCardPrice
            shouldCenterPrice={shouldCenterPrice}
            isFullWidth={isFullWidth}
            currency={currency}
            testId={testId}
            uomLabel={uomLabel}
            product={product}
            inputValue={inputValue}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            handlePaste={handlePaste}
            handleFocus={handleFocus}
            handleKeyDown={handleKeyDown}
            borders={borders}
            showRemainsData={showRemainsData}
            isStocksOverflow={isStocksOverflow}
            onDelete={onDelete}
          />
        )}
      </div>
      {isMobile && (
        <ProductRowCardPrice
          shouldCenterPrice={shouldCenterPrice}
          isFullWidth={isFullWidth}
          currency={currency}
          testId={testId}
          uomLabel={uomLabel}
          product={product}
          inputValue={inputValue}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handlePaste={handlePaste}
          handleFocus={handleFocus}
          handleKeyDown={handleKeyDown}
          borders={borders}
          showRemainsData={showRemainsData}
          isStocksOverflow={isStocksOverflow}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};
