import { TProductCardCategoriesBreadcrumb } from '@apptypes/index';
import { BodyText } from '@lognex/ui-typography';
import { Localizator } from '@utils/Localizator';
import React, { useCallback, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';

export const useShowToaster = (className: string) => {
  const { t } = Localizator.translate();

  const toastId = useRef<React.ReactText | null>(null);

  const handleShowToaster = useCallback(() => {
    const TOASTER_SETTINGS: ToastOptions = {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
      theme: 'light',
      draggableDirection: 'y',
    };

    if (!toast.isActive(toastId.current || '')) {
      toastId.current = toast(
        <div>
          <BodyText className={className}>
            {t('ProductCard.LinkCopied')}
          </BodyText>
        </div>,
        TOASTER_SETTINGS,
      );
    }
  }, [t, className]);

  return { handleShowToaster };
};

export const useOnCategoryTagClick = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCategoryTagClick = useCallback(
    (id: string) => {
      if (Boolean(id)) {
        searchParams.set('categoryId', id);
      } else {
        searchParams.delete('categoryId');
      }

      searchParams.delete('productId');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return { handleCategoryTagClick };
};

export const useGetPreparedProductCardCategoriesBreadcrumbs = ({
  categories,
}: {
  categories: TProductCardCategoriesBreadcrumb[];
}) => {
  const { t } = Localizator.translate();

  const preparedCategories = useMemo(() => {
    const prepared: Omit<TProductCardCategoriesBreadcrumb, 'code'>[] = [
      ...categories,
    ];

    switch (prepared.length) {
      case 0: {
        prepared.push({
          id: '',
          name: t('ProductCard.AllProducts'),
        });
        break;
      }

      case 1: {
        prepared.unshift({
          id: '',
          name: t('ProductCard.AllProducts'),
        });
        break;
      }

      // Хочу чтобы в коде было явно прописано это условие для понятности
      case 2: {
        break;
      }

      default: {
        break;
      }
    }

    return prepared;
  }, [categories, t]);

  return { preparedCategories };
};
