import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useHandleCloseProductCardPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCloseProductCardPage = useCallback(() => {
    if (searchParams.get('productId')) {
      searchParams.delete('productId');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return { handleCloseProductCardPage };
};
