import React, { HTMLAttributes } from 'react';
import { useStyles } from './BackButton.style';
import { ReactComponent as BackIcon } from './assets/back-icon.svg';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  testId?: string;
}

export const BackButton: React.FC<Props> = ({
  className,
  testId = '',
  ...rest
}) => {
  const { classes, cx } = useStyles();

  return (
    <div
      data-testid={`${testId}-back-button`}
      {...rest}
      className={cx(classes.wrapper, className)}
    >
      <BackIcon />
    </div>
  );
};
