import { TProductFolder } from '@apptypes/index';

export const getAllChildCategoriesByParentId = (
  categories: TProductFolder[],
  id?: string | null,
) => {
  if (!Boolean(id)) {
    return categories;
  }

  const result: TProductFolder[] = [];

  categories.forEach((item) => {
    if (item.parentId === id) {
      result.push(item);
    }
  });

  return result;
};
