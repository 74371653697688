import React from 'react';
import { buildTextFor } from './CartBottom.utils';
import { useStyles } from './CartBottomText.style';

type Props = {
  totalPositionsCount: number;
};

export const CartBottomText: React.FC<Props> = ({ totalPositionsCount }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.textBottom}>
      {buildTextFor(totalPositionsCount)}
    </div>
  );
};
