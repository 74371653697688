export class RegexCollectionMethods {
  static removeAllWhitespaces(arg: string) {
    const regex = /\s/g;

    return arg.replace(regex, '');
  }

  static replaceAllCommasForDots(arg: string | number) {
    const str = typeof arg === 'number' ? arg.toString() : arg;

    const regex = /,/g;

    return str.replace(regex, '.');
  }

  static replaceAllDotsForCommas(arg: string | number) {
    const str = typeof arg === 'number' ? arg.toString() : arg;

    const regex = /\./g;

    return str.replace(regex, '.');
  }

  static isInputValueCanBeParsed(arg: string) {
    return arg.match(/^[0-9.,]+$/) || arg === '';
  }

  static removeAllDots(arg: string | number) {
    const str = typeof arg === 'number' ? arg.toString() : arg;
    const regex = /\./g;

    return str.replace(regex, '');
  }

  static removeAllCommas(arg: string | number) {
    const str = typeof arg === 'number' ? arg.toString() : arg;
    const regex = /,/g;

    return str.replace(regex, '');
  }
}
