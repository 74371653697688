import { StoreonModule } from 'storeon';
import { TProductFolder } from '@apptypes/index';

export interface ICategoriesState {
  allCategories: TProductFolder[];
}

export const SET_ALL_CATEGORIES = 'categories/setAll';

export interface ICategoryEvents {
  [SET_ALL_CATEGORIES]: TProductFolder[];
}

export const categories: StoreonModule<ICategoriesState, ICategoryEvents> = (
  store,
) => {
  store.on('@init', () => ({
    allCategories: [],
  }));

  store.on(SET_ALL_CATEGORIES, (state, categories) => {
    return {
      allCategories: categories,
    };
  });
};
