import React from 'react';
import { BLUE_DEFAULT } from '@theme/theme';
import { TProductFolder } from '@apptypes/index';
import { Spinner } from '@components/shared/Spinner/Spinner.component';
import { TMenuItem } from '../Categories.component';
import { CategoriesEmptyError } from '../CategoriesEmptyError/CategoriesEmptyError.component';
import { CategoriesMenuContentView } from './CategoriesMenuItems.view';

type Props = {
  isError: boolean;
  isLoading: boolean;
  listWrapperRef: React.RefObject<HTMLDivElement>;
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  currentCategory: TProductFolder | null | undefined;
  categories: TProductFolder[];
};

export const CategoriesMenuContent: React.FC<Props> = ({
  isError,
  isLoading,
  listWrapperRef,
  onMenuItemClick,
  currentCategory,
  categories,
}) => {
  if (isLoading && !isError) {
    return <Spinner color={BLUE_DEFAULT} />;
  }

  if (isError) {
    return <CategoriesEmptyError />;
  }

  if (!isLoading && !isError) {
    return (
      <CategoriesMenuContentView
        listWrapperRef={listWrapperRef}
        onMenuItemClick={onMenuItemClick}
        currentCategory={currentCategory}
        categories={categories}
      />
    );
  }

  return null;
};
