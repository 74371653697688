import { StoreonModule } from 'storeon';

export interface ISearchHistoryState {
  searchHistory: string[];
}

export const SEARCH_HISTORY_RESET = 'searchHistory/reset';
export const SEARCH_HISTORY_DELETE_ITEM = 'searchHistory/deleteItem';
export const SEARCH_HISTORY_ADD_ITEM = 'searchHistory/addItem';

export interface ISearchHistoryEvents {
  [SEARCH_HISTORY_RESET]: void;
  [SEARCH_HISTORY_DELETE_ITEM]: string;
  [SEARCH_HISTORY_ADD_ITEM]: string;
}

export const searchHistory: StoreonModule<
  ISearchHistoryState,
  ISearchHistoryEvents
> = (store) => {
  store.on('@init', () => ({
    searchHistory: [],
  }));

  store.on(SEARCH_HISTORY_RESET, (state) => {
    return {
      searchHistory: [],
    };
  });

  store.on(SEARCH_HISTORY_DELETE_ITEM, (state, item) => {
    const copy = [...state.searchHistory];

    const filtered = copy.filter((historyItem) => historyItem !== item);

    return {
      searchHistory: filtered,
    };
  });

  store.on(SEARCH_HISTORY_ADD_ITEM, (state, item) => {
    const copy: string[] = [...state.searchHistory];

    const isAlreadyExist = Boolean(
      copy.find((historyItem) => historyItem === item),
    );

    if (!isAlreadyExist) {
      if (copy.length === 10) {
        copy.shift();
      }

      copy.push(item);
    }

    return {
      searchHistory: copy,
    };
  });
};
