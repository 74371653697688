import React from 'react';
import { TBreadcrumb } from '@apptypes/index';
import { Container } from '@components/Container/Container.component';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs.component';
import { useStyles } from './Header.style';
import { Referral } from './Referral/Referral.component';
import { StickyHeader } from './StickyHeader/StickHeader.view';
import { Title } from './Title/Title.component';

type Props = {
  withLogo?: boolean;
  withReferral?: boolean;
  withHeader?: boolean;
  withTitle?: boolean;
  withCaregories?: boolean;
  withSearch?: boolean;
  withCart?: boolean;
  withBreadcrumbs?: boolean;
  withBackButton?: boolean;
  findByCallback?: () => void;
  refetchOnSearchCallback?: () => void;
  isCart?: boolean;
  breadcrumbs?: TBreadcrumb[] | null;
  title: string;
  referralLink: string;

  subTitle?: string;

  isCategoriesOpened: boolean;
  catetoriesAnchorEl: HTMLElement | null;
  setCategoriesAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;

  __containerClassName?: string;
};

export const HeaderView: React.FC<Props> = ({
  withLogo,
  withReferral,
  withHeader,
  withTitle,
  withCaregories,
  withSearch,
  withCart,
  withBreadcrumbs,
  withBackButton,
  findByCallback,
  refetchOnSearchCallback,
  breadcrumbs,
  isCart,
  title,
  subTitle,
  isCategoriesOpened,
  catetoriesAnchorEl,
  setCategoriesAnchorEl,
  referralLink,
  __containerClassName,
}) => {
  const { classes, cx } = useStyles({ withSearch });

  return (
    <>
      {withReferral && (
        <>
          <div className={classes.wrapper}>
            <Container className={__containerClassName}>
              <Referral referralLink={referralLink} />
            </Container>
          </div>
          <div
            className={cx(classes.thereshold, {
              [classes.cartThereshold]: isCart,
            })}
          />
        </>
      )}

      {withHeader && (
        <StickyHeader
          withSearch={withSearch}
          withTitle={withTitle}
          withLogo={withLogo}
          withCaregories={withCaregories}
          withBackButton={withBackButton}
          withCart={withCart}
          isCart={isCart}
          title={title}
          catetoriesAnchorEl={catetoriesAnchorEl}
          isCategoriesOpened={isCategoriesOpened}
          setCategoriesAnchorEl={setCategoriesAnchorEl}
          findByCallback={findByCallback}
          refetchOnSearchCallback={refetchOnSearchCallback}
          referralLink={referralLink}
          __containerClassName={__containerClassName}
        />
      )}

      {Boolean(withBreadcrumbs) && Boolean(breadcrumbs) && (
        <Container className={__containerClassName}>
          <div
            className={cx({
              [classes.breadcrumbsNoHeaderWrapper]: Boolean(!withHeader),
              [classes.breadcrumbsNoMargin]: Boolean(withSearch),
            })}
          >
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </Container>
      )}

      {!withHeader && withTitle && (
        <Container
          className={cx(__containerClassName, {
            'cart-title': isCart,
          })}
        >
          <Title
            isCart={isCart}
            title={title}
            referralLink={referralLink}
            subTitle={subTitle}
          />
        </Container>
      )}
    </>
  );
};
