import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { ILinkInfo } from '@apptypes/index';

export const useLinkPath = () => {
  const { linkPathPublic } = useParams();
  const { linkPathCounterparty } = useParams();

  const queryClient = useQueryClient();

  const linkPath =
    linkPathPublic === undefined ? linkPathCounterparty : linkPathPublic;

  const isPublicLink = linkPathPublic !== undefined;

  const linkInfo = queryClient.getQueryData('link-info') as ILinkInfo;

  const root = isPublicLink
    ? `/public/${linkPathPublic}`
    : `/${linkPathCounterparty}`;

  const withRemains = linkInfo?.withRemains;

  return {
    linkPath,
    isPublicLink,
    linkInfo,
    withRemains,
    root,
  };
};
