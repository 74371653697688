import React, { HTMLAttributes } from 'react';
import { useStyles } from './CrossButton.style';
import { ReactComponent as CrossIcon } from './assets/cross-icon.svg';

interface Props extends HTMLAttributes<HTMLDivElement> {
  testId?: string;
  className?: string;
}

// eslint-disable-next-line react/no-multi-comp
export const CrossButton = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ className, testId = '', ...rest }, ref) => {
      const { classes, cx } = useStyles();

      return (
        <div
          data-testid={`${testId}-cross-button`}
          className={cx(classes.wrapper, className)}
          {...rest}
          ref={ref}
        >
          <CrossIcon className={classes.icon} />
        </div>
      );
    },
  ),
);
