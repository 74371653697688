import React from 'react';
import { useStyles } from './ProductCardError.style';
import { Localizator } from '@utils/Localizator';
import { H2 } from '@lognex/ui-typography';
import { useIsMobile } from '@hooks/useIsMobile';
import { Button } from '@lognex/ui-control';
import { useSearchParams } from 'react-router-dom';
import { ProductCardTopIcon } from '../ProductCardTopIcon/ProductCardTopIcon.component';
import { Container } from '@components/Container/Container.component';

type Props = {
  handleIconClick: () => void;
};

export const ProductCardError: React.FC<Props> = ({ handleIconClick }) => {
  const { classes, cx } = useStyles();

  const { t } = Localizator.translate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isMobile } = useIsMobile();

  const handleClickGoCatalog = () => {
    searchParams.delete('categoryId');
    searchParams.delete('searchBy');
    searchParams.delete('productId');
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.errorWrapper}>
      <Container
        className={
          isMobile ? cx('newBootstrap', classes.errorWrapperInner) : ''
        }
        skipContainer={!isMobile}
      >
        <div className={classes.header}>
          <ProductCardTopIcon onClick={handleIconClick} />
        </div>
        <div data-testid="product-page-error" className={classes.wrapper}>
          <div>
            <H2 className={classes.text}>{t('ProductCard.ItemNotFound')}</H2>
            {isMobile && (
              <div
                className={classes.buttonWrapper}
                onClick={handleClickGoCatalog}
              >
                <Button size="xl">{t('ProductCard.GoToCatalog')}</Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
