import React from 'react';
import { useStyles } from './SearchHistoryItem.style';
import CrossIconDesktop from '@assets/Header/Search/SearchHistoryItem/cross-icon-desktop.svg';
import CrossIconMobile from '@assets/Header/Search/SearchHistoryItem/cross-icon-mobile.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import { Localizator } from '@utils/Localizator';

type Props = {
  item: string;
  handleIconClick: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => void;
  handleItemClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
};

export const SearchHistoryItemView: React.FC<Props> = ({
  handleIconClick,
  handleItemClick,
  item,
}) => {
  const { classes } = useStyles();
  const { isMobile } = useIsMobile();

  const { t } = Localizator.translate();

  return (
    <div
      onClick={handleItemClick}
      onMouseDown={(e) => e.preventDefault()}
      className={classes.wrapper}
    >
      <p title={item}>{item}</p>
      <img
        onClick={handleIconClick}
        onMouseDown={(e) => e.preventDefault()}
        className={classes.icon}
        src={isMobile ? CrossIconMobile : CrossIconDesktop}
        alt={t('Other.CloseAlt')}
      />
    </div>
  );
};
