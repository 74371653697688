import React from 'react';
import { useStyles } from './Input.style';

type Props = {
  value?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  isError?: boolean;
  borders?: boolean;
  testId?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

// eslint-disable-next-line react/no-multi-comp
export const Input = React.memo(
  React.forwardRef<HTMLInputElement, Props>(
    (
      {
        value,
        onBlur,
        isError,
        onPaste,
        borders,
        onChange,
        onFocus,
        onKeyDown,
        testId = '',
      },
      ref,
    ) => {
      const { classes, cx } = useStyles({ isError });

      return (
        <div data-testid={`${testId}-number-input`}>
          <input
            className={cx(classes.input, { [classes.redBorder]: borders })}
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            onPaste={onPaste}
            value={value}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            title={value}
            ref={ref}
          />
        </div>
      );
    },
  ),
);
