import React from 'react';
import { Typography } from '@components/shared/Typography/Typography.component';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { useStyles } from './ConfirmMobileTitle.style';
import { Localizator } from '@utils/Localizator';

export const ConfirmMobileTitle = () => {
  const { classes } = useStyles();
  const { t } = Localizator.translate();

  const {
    linkInfo: { organizationName },
  } = useLinkPath();

  return (
    <div className={classes.wrapper}>
      <Typography fontWeight={700} fontSize={24} lineHeight="28px">
        {t('Confirmation.TitlePrefix')} {organizationName}
      </Typography>
    </div>
  );
};
