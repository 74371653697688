export const NEW_BOOTSTRAP_VALUE = 1180;
export const OLD_BOOTSTRAP_VALUE = 1232;

export enum NumberType {
  INT = 'int',
  INT_POSITIVE = 'intPositive',
  FLOAT = 'float',
  FLOAT_POSITIVE = 'floatPositive',
}

export const intKeyCodeRegEx = /[\d-]/;
export const intPositiveKeyCodeRegEx = /\d/;
export const floatKeyCodeRegEx = /[\d.,-]/;
export const floatPositiveKeyCodeRegEx = /[\d.,]/;
export const copyPasteKeysRegEx = /Key[ACFRVXYZ]/;
export const reservedKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowDown',
  'ArrowUp',
  'Backspace',
  'Tab',
  'Delete',
  'Insert',
  'Home',
  'End',
];
export const reservedCodes = ['NumpadDecimal'];

export const numberTypeKeyCodeRegExMap: Record<NumberType, RegExp> = {
  [NumberType.INT]: intKeyCodeRegEx,
  [NumberType.INT_POSITIVE]: intPositiveKeyCodeRegEx,
  [NumberType.FLOAT]: floatKeyCodeRegEx,
  [NumberType.FLOAT_POSITIVE]: floatPositiveKeyCodeRegEx,
};
