import { StoreonModule } from 'storeon';
import { TProduct } from '@apptypes/index';

export interface ICartState {
  cartItems: TProduct[];
}

export const UPDATE_IN_CART = 'cart/update';
export const REMOVE_FROM_CART = 'cart/remove';
export const FORCE_SET_CART_ITEMS = 'cart/forseSet';
export const CLEAR_CART = 'cart/clear';

export interface ICartEvents {
  [UPDATE_IN_CART]: TProduct;
  [REMOVE_FROM_CART]: string;
  [FORCE_SET_CART_ITEMS]: TProduct[];
  [CLEAR_CART]: undefined;
}

export const cart: StoreonModule<ICartState, ICartEvents> = (store) => {
  store.on('@init', () => ({
    cartItems: [],
  }));

  store.on(REMOVE_FROM_CART, (state, id) => {
    return {
      cartItems: [...state.cartItems.filter((item) => item.id !== id)],
    };
  });

  store.on(UPDATE_IN_CART, (state, payload) => {
    const copy = [...state.cartItems];
    const idx = copy.map((item) => item.id).indexOf(payload.id);

    switch (idx) {
      case -1: {
        copy.push(payload);
        break;
      }

      default: {
        copy[idx] = payload;
      }
    }

    return {
      cartItems: copy,
    };
  });

  store.on(FORCE_SET_CART_ITEMS, (state, payload) => {
    return {
      cartItems: payload,
    };
  });

  store.on(CLEAR_CART, (state, _) => {
    return {
      cartItems: [],
    };
  });
};
