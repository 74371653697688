import React from 'react';
import { Control, Noop } from 'react-hook-form';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import {
  SET_CART_USER_INFO_COMMENT,
  SET_CART_USER_INFO_COMPANY_NAME,
  SET_CART_USER_INFO_PHONE,
} from '@store/cartUserInfo.store';
import { TFieldValues } from '../../Confirm.types';
import { useCallbacks } from './ConfirmFormItem.hooks';
import { ConfirmFormItemView } from './ConfirmFormItem.view';

type Props = {
  name: keyof TFieldValues;
  control: Control<TFieldValues, any>;
  errorMessage?: string;
  label: { text: string; required?: boolean; id: string };
  additionalText?: string;
  type: 'input' | 'textarea';
};

export const ConfirmFormItem: React.FC<Props> = ({
  name,
  control,
  errorMessage,
  label,
  additionalText,
  type,
}) => {
  const { handleBlur, handleFocus, isFocused } = useCallbacks();
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const composeBlurHandlers = (blur: Noop, value: string, name: string) => {
    switch (name) {
      case 'phone': {
        dispatch(SET_CART_USER_INFO_PHONE, value);
        break;
      }
      case 'companyName': {
        dispatch(SET_CART_USER_INFO_COMPANY_NAME, value);
        break;
      }
      case 'comment': {
        dispatch(SET_CART_USER_INFO_COMMENT, value);
        break;
      }
      default: {
        break;
      }
    }

    handleBlur(blur);
  };

  return (
    <ConfirmFormItemView
      name={name}
      control={control}
      errorMessage={errorMessage}
      label={label}
      additionalText={additionalText}
      handleBlur={composeBlurHandlers}
      handleFocus={handleFocus}
      isFocused={isFocused}
      type={type}
    />
  );
};
