import React from 'react';
import { useStyles } from './DeleteButton.style';
import { ReactComponent as DeleteIcon } from './../assets/delete-icon.svg';

type Props = {
  onClick: () => void;
  testId?: string;
};

export const DeleteButton: React.FC<Props> = React.memo(
  ({ onClick, testId = '' }) => {
    const { classes } = useStyles();

    return (
      <div
        data-testid={`${testId}-delete-button`}
        onClick={onClick}
        className={classes.wrapper}
      >
        <DeleteIcon className={classes.icon} />
      </div>
    );
  },
);
