import { makeStyles } from 'tss-react/mui';
import {
  PRIMARY_BACKGROUND_COLOR,
  WHITE_DEFAULT,
  SECONDARY_HOVER_TEXT_COLOR,
  BLUE_ONCLICK_COLOR,
  WHITE_TYPE_1,
  GRAY_TYPE_2,
  PRIMARY_LINK_COLOR,
  SECONDARY_DISABLED_COLOR,
} from '@theme/theme';

type Props = {
  variant?: 'primary' | 'secondary';
  isLoading?: boolean;
};

const stylesVariants = {
  buttonBackground: {
    primary: PRIMARY_BACKGROUND_COLOR,
    secondary: WHITE_DEFAULT,
    hover: {
      primary: SECONDARY_HOVER_TEXT_COLOR,
      secondary: WHITE_DEFAULT,
    },
    active: {
      primary: BLUE_ONCLICK_COLOR,
      secondary: WHITE_DEFAULT,
    },
    disabled: {
      primary: WHITE_TYPE_1,
      secondary: WHITE_DEFAULT,
    },
    loading: {
      primary: PRIMARY_BACKGROUND_COLOR,
      secondary: WHITE_DEFAULT,
      hover: {
        primary: PRIMARY_BACKGROUND_COLOR,
        secondary: WHITE_DEFAULT,
      },
      active: {
        primary: PRIMARY_BACKGROUND_COLOR,
        secondary: WHITE_DEFAULT,
      },
    },
  },
  buttonColor: {
    primary: WHITE_DEFAULT,
    secondary: PRIMARY_LINK_COLOR,
    hover: {
      primary: WHITE_DEFAULT,
      secondary: SECONDARY_HOVER_TEXT_COLOR,
    },
    active: {
      primary: WHITE_DEFAULT,
      secondary: BLUE_ONCLICK_COLOR,
    },
  },
  buttonBorder: {
    primary: 'none',
    secondary: PRIMARY_LINK_COLOR,
    disabled: {
      primary: 'none',
      secondary: SECONDARY_DISABLED_COLOR,
    },
  },
};

export const useStyles = makeStyles<Props>()(
  (theme, { variant = 'primary', isLoading }) => ({
    button: {
      position: 'relative',
      background: stylesVariants.buttonBackground[variant],
      color: stylesVariants.buttonColor[variant],
      lineHeight: '20px',
      fontFamily: 'ALSHauss,sans-serif',
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
      fontFeatureSettings: '"tnum" on, "lnum" on',
      border:
        variant === 'primary'
          ? 'none'
          : `1px solid ${stylesVariants.buttonBorder[variant]}`,
      borderRadius: 8,
      padding: '10px 16px',
      width: 'calc(100% + 2px)',
      height: 40,
      cursor: 'pointer',

      '&:hover': {
        transition: '0.25s all',
        background: stylesVariants.buttonBackground.hover[variant],
        color: stylesVariants.buttonColor.hover[variant],
      },

      '&:active': {
        background: stylesVariants.buttonBackground.active[variant],
        color: stylesVariants.buttonColor.active[variant],
      },

      '&:disabled': {
        background: stylesVariants.buttonBackground.disabled[variant],
        color: GRAY_TYPE_2,
        cursor: 'default',
        border: `1px solid ${stylesVariants.buttonBorder.disabled[variant]}`,
      },
    },

    loading: {
      background: stylesVariants.buttonBackground.loading[variant],

      '&:hover': {
        background: stylesVariants.buttonBackground.loading.hover[variant],
      },

      '&:active': {
        background: stylesVariants.buttonBackground.loading.active[variant],
      },
    },

    text: {
      visibility: isLoading ? 'hidden' : 'visible',
    },
  }),
);
