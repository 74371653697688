import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductRowCard } from '@components/ProductRowCard/ProductRowCard.component';
import { ProdctGroupName } from '@components/shared/ProductGroupName/ProductGroupName.component';
import { useGetProductFromStore } from '@hooks/useGetProductFromStore';
import { useNumberInputWithRemains } from '@hooks/useNumberInputsWithRemains';
import { TProduct } from '@apptypes/index';
import { useStyles } from './ProductCardItemAndGroup.style';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { useImageGallery } from '@hooks/useImageGallery';

type Props = {
  needRenderCategory: boolean;
  onCategoryClick: () => void;
  product: TProduct;
  categoryName?: string;
};

export const ProductCardItemAndGroup: React.FC<Props> = React.memo(
  ({ needRenderCategory, onCategoryClick, product, categoryName }) => {
    const { classes } = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();

    const { currency } = useStoreon<TAppState, TAppEvents>('currency');

    const { product: productFromStore } = useGetProductFromStore(
      product.id,
      product,
    );

    const {
      inputValue,
      handleBlur,
      handleChange,
      handleDecrement,
      handleIncrement,
      handlePaste,
      handleFocus,
      borders,
      showRemainsData,
      isStocksOverflow,
    } = useNumberInputWithRemains({ product: productFromStore });

    const onTitleClick = useCallback(() => {
      searchParams.set('productId', product.id);
      setSearchParams(searchParams);
    }, [product, searchParams, setSearchParams]);

    const { handleClick } = useImageGallery({
      product: productFromStore,
      enabled: false,
    });

    return (
      <>
        {needRenderCategory && (
          <ProdctGroupName
            onClick={onCategoryClick}
            className={classes.productGroupNameWrapper}
          >
            {categoryName}
          </ProdctGroupName>
        )}
        <ProductRowCard
          product={productFromStore}
          currency={currency.symbol ? currency.symbol : currency.name}
          isFullWidth
          inputValue={inputValue}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handlePaste={handlePaste}
          handleFocus={handleFocus}
          borders={borders}
          showRemainsData={showRemainsData}
          isStocksOverflow={isStocksOverflow}
          imageUrl={
            productFromStore.imageURLMiniature ?? productFromStore.imageURL
          }
          onTitleClick={onTitleClick}
          onGalleryClick={handleClick}
          useImageGallery
        />
      </>
    );
  },
);
