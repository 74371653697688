import React from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import { Typography } from '@components/shared/Typography/Typography.component';
import { useStyles } from './Title.style';

type Props = {
  title: string;
  referralLink: string;
  isCart?: boolean;
  withLogo?: boolean;
  subTitle?: string;
};

export const TitleView: React.FC<Props> = ({
  title,
  isCart,
  withLogo,
  referralLink,
  subTitle,
}) => {
  const { classes } = useStyles({ isCart });

  const { isMobile } = useIsMobile();

  if (isCart && !isMobile) {
    return (
      <>
        <Typography fontSize={32} fontWeight={700} lineHeight={'38px'}>
          {title}
        </Typography>
        {Boolean(subTitle) && (
          <div className={classes.subTitle}>
            <Typography fontSize={14} lineHeight="20px" fontWeight={400}>
              {subTitle}
            </Typography>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={classes.title} id="company-name">
      {isMobile && withLogo && (
        <a target="_blank" rel="noopener noreferrer" href={referralLink}>
          <div className={classes.logo} />
        </a>
      )}
      <p title={title}>{title}</p>
    </div>
  );
};
