import { MenuItem } from '@mui/material';
import { Localizator } from '@utils/Localizator';
import React from 'react';
import { TProductFolder } from '../../../../../types';
import { TMenuItem } from '../../Categories.component';
import { useStyles } from '../CategoriesMenuContent.style';
import RightArrowIcon from './../../../../../assets/right-arrow.svg';

type Props = {
  onMenuItemClick: (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item?: TMenuItem | null,
  ) => void;
  category: TProductFolder;
};

export const MenuItemView: React.FC<Props> = ({
  onMenuItemClick,
  category,
}) => {
  const { classes } = useStyles();

  const { t } = Localizator.translate();

  return (
    <MenuItem
      disableRipple
      onClick={(evt) =>
        onMenuItemClick(evt, {
          id: category.id,
          isWithSubcategory: category.isWithSubcategory,
          toBack: false,
        })
      }
      className={classes.menuItem}
      title={category.name}
    >
      <div className={classes.menuItemText}>
        <p>{category.name}</p>
        {category.isWithSubcategory && (
          <img src={RightArrowIcon} alt={t('Other.NextAlt')} />
        )}
      </div>
    </MenuItem>
  );
};
