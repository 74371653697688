import { makeStyles } from 'tss-react/mui';
import {
  PRIMARY_ERROR_COLOR,
  NORMAL_BACKGROUND_COLOR_PRIMARY_BUTTON,
} from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  warningWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    height: 44,
    borderRadius: 8,
    background: NORMAL_BACKGROUND_COLOR_PRIMARY_BUTTON,
    color: PRIMARY_ERROR_COLOR,

    '@media (max-width: 1279px)': {
      marginBottom: 16,
      minHeight: 44,
      height: 'auto',
    },
  },
}));
