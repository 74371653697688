import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  opacityBackground: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    background: '#000',
    zIndex: 1,
    opacity: '0.5',
  },
}));
