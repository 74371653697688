/* eslint-disable lines-around-comment */
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import { StoreContext } from 'storeon/react';
import { Router } from '@components/Router/Router.component';
import { config } from './config';
import { store } from '@store/index';
import { stopReportingRuntimeErrors } from 'react-error-overlay';

// Styles
// Animations pack lib
import 'animate.css';
// Import Swiper styles
import 'swiper/swiper.min.css';
import '@styles/pagination.css';
import '@styles/navigation.css';
// Import Image Gallery styles
import 'viewerjs/dist/viewer.css';


import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors(); // disables error overlays
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

const init = () => {
  return Promise.all([
    import(
      /* webpackChunkName: "font-1" */
      /* webpackPrefetch: true */
      '@lognex/font-hauss/src/hauss.css'
    ),
    import(
      /* webpackChunkName: "font-2" */
      /* webpackPrefetch: true */
      '@styles/fonts.style.css'
    ),
    import(
      /* webpackChunkName: "style-1" */
      /* webpackPrefetch: true */
      '@styles/common.style.css'
    ),
    import(
      /* webpackChunkName: "style-2" */
      /* webpackPrefetch: true */
      '@lognex/ui-typography/dist/styles.css'
    ),
    import(
      /* webpackChunkName: "style-3" */
      /* webpackPrefetch: true */
      '@lognex/ui-control/dist/styles.css'
    ),
    import(
      /* webpackChunkName: "style-4" */
      /* webpackPrefetch: true */
      'react-toastify/dist/ReactToastify.min.css'
    ),
  ]).then(() => {
    root.render(
      <QueryClientProvider client={queryClient}>
        <StoreContext.Provider value={store}>
          <CacheProvider value={muiCache}>
            {process.env.NODE_ENV === 'production' && (
              <YMInitializer
                accounts={[config.YANDEX_METRICA_ID_COUNTER]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                }}
              />
            )}

            <BrowserRouter>
              {process.env.NODE_ENV !== 'production' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
              <Router />
            </BrowserRouter>
          </CacheProvider>
        </StoreContext.Provider>
      </QueryClientProvider>,
    );
  });
};

init();
