import { StoreonModule } from 'storeon';
import { ICurrency } from '@apptypes/index';

export interface ICurrencyState {
  currency: ICurrency;
}

export const SET_CURRENCY = 'currency/set';

export interface ICurrencyEvents {
  [SET_CURRENCY]: ICurrency;
}

export const currency: StoreonModule<ICurrencyState, ICurrencyEvents> = (
  store,
) => {
  store.on('@init', () => ({
    currency: { code: '', name: '', fullName: '', isoCode: '' },
  }));

  store.on(SET_CURRENCY, (state, currency) => {
    return {
      currency,
    };
  });
};
