import React from 'react';
import { useCallbacks } from './SearchHistory.hooks';
import { SearchHistoryView } from './SearchHistory.view';

type Props = {
  isOpen: boolean;
  searchHistory: string[];
  onIconClick: (item: string) => void;
  onItemClick: (item: string) => void;
};

export const SearchHistory: React.FC<Props> = ({
  isOpen,
  searchHistory,
  onIconClick,
  onItemClick,
}) => {
  const { reversed } = useCallbacks({ searchHistory });

  if (!isOpen) {
    return null;
  }

  return (
    <SearchHistoryView
      reversed={reversed}
      onIconClick={onIconClick}
      onItemClick={onItemClick}
    />
  );
};
