import React from 'react';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { useStyles } from './SuccessDescription.style';
import { useCallbacks } from './SuccessDescription.hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';

type Props = {
  state: {
    name: string;
    sum: number;
  };
};

export const SuccessDescription: React.FC<Props> = ({ state }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const { currency } = useStoreon<TAppState, TAppEvents>('currency');

  const { getName, getSum } = useCallbacks({ state });

  const {
    linkInfo: { organizationName },
  } = useLinkPath();

  return (
    <p className={classes.messageDescription}>
      <Trans
        t={t}
        i18nKey="Success.Description"
        values={{
          orderName: getName(),
          sum: getSum(),
          currency: currency.symbol ? currency.symbol : currency.name,
          company: organizationName,
        }}
        defaults={
        'Ваш заказ <n>№{{orderName}}</n> на сумму <s>{{sum}}\u00A0{{currency}}</s> отправлен в <c>{{company}}</c>'
      }
        components={{
          n: <span />,
          s: <span className={cx(classes.customerCurrency, classes.strong)} />,
          c: <span />,
        }}
      />
    </p>
  );
};
