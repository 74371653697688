import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { TProduct } from '@apptypes/index';
import { useIsStocksOverflow } from '@hooks/useIsStocksOverflow';
import { useLinkPath } from '@hooks/useLinkPath.hooks';
import { useGetNumberInputLogicHandlers } from '@hooks/useNumberInputLogic';
import { useShowRemainsData } from '@hooks/useShowRemainsData';

export type TUseCallbacks = {
  product: TProduct;
};

export const useNumberInputWithRemains = ({ product }: TUseCallbacks) => {
  const { cartItems } = useStoreon<TAppState, TAppEvents>('cartItems');

  const { withRemains } = useLinkPath();

  const preparedProduct =
    cartItems.find((el) => el.id === product?.id) || product;

  const {
    inputValue,
    handleBlur,
    handleChange,
    handleDecrement,
    handleIncrement,
    handlePaste,
    handleKeyDown,
    borders,
  } = useGetNumberInputLogicHandlers({
    product: preparedProduct,
  });

  const { isStocksOverflow } = useIsStocksOverflow({
    inputValue,
    preparedProduct,
  });

  const { showRemainsData } = useShowRemainsData({
    withRemains,
    preparedProduct,
    inputValue,
  });

  return {
    inputValue,
    handleBlur,
    handleChange,
    handleDecrement,
    handleIncrement,
    handlePaste,
    borders,
    showRemainsData,
    preparedProduct,
    isStocksOverflow,
    handleKeyDown,
    handleFocus: () => {},
  };
};
