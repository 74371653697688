import { useMemo } from 'react';
import {
  TUseArticlaAndCode,
  TUseProductRowCard,
  TUseUomLabel,
} from './ProductRowCard.types';

export const useProductRowCard = ({
  product,
  currency,
}: TUseProductRowCard) => {
  const { articleAndCode } = useArticlaAndCode({ product });
  const { uomLabel } = useUomLabel({ product, currency });

  return { articleAndCode, uomLabel };
};

const useArticlaAndCode = ({ product }: TUseArticlaAndCode) => {
  const articleAndCode = useMemo(() => {
    let result = '';

    if (product.article) {
      result += product.article;
    }

    if (product.article && product.code) {
      result += ', ';
    }

    if (product.code) {
      result += product.code;
    }

    return result;
  }, [product]);

  return { articleAndCode };
};

const useUomLabel = ({ product, currency }: TUseUomLabel) => {
  const uomLabel = useMemo(() => {
    let result = currency;

    if (product.uom) {
      result += `/${product.uom}`;
    }

    return result;
  }, [product, currency]);

  return { uomLabel };
};
