import { makeStyles } from 'tss-react/mui';
import {
  BLUE_HOVER_COLOR,
  BLUE_ONCLICK_COLOR,
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_DISABLED_COLOR,
} from '@theme/theme';

type Props = {
  isClicked?: boolean;
  isDisabled?: boolean;
};

export const useStyles = makeStyles<Props>()(
  (theme, { isClicked, isDisabled }) => {
    let backgroundColor;
    let backgroundColorHover;

    if (isDisabled) {
      backgroundColor = PRIMARY_DISABLED_COLOR;
      backgroundColorHover = PRIMARY_DISABLED_COLOR;
    } else {
      if (isClicked) {
        backgroundColor = BLUE_ONCLICK_COLOR;
        backgroundColorHover = BLUE_ONCLICK_COLOR;
      } else {
        backgroundColor = PRIMARY_BACKGROUND_COLOR;
        backgroundColorHover = BLUE_HOVER_COLOR;
      }
    }

    return {
      wrapper: {
        minHeight: 40,
        minWidth: 40,
        padding: 0,
        borderRadius: '8px',
        background: backgroundColor,
        position: 'relative',
        cursor: 'pointer',
        transition: '0.25s all',
        userSelect: 'none',
        border: 'none',

        '&:hover': {
          transition: '0.25s all',
          background: backgroundColorHover,
        },

        '& img': {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
    };
  },
);
