import { StoreonModule } from 'storeon';

export const SET_LOCALE = 'locale/set';

export interface ILocaleEvents {
  [SET_LOCALE]: string;
}

export interface ILocaleState {
  locale: string | null;
}

const initialState = {
  locale: null,
};

export const locale: StoreonModule<ILocaleState, ILocaleEvents> = (store) => {
  store.on('@init', () => initialState);

  store.on(SET_LOCALE, (state, locale) => {
    return { locale };
  });
};
