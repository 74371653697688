import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAsyncError } from '@hooks/useAsyncError';
import { CartPage } from '@pages/Cart/Cart.page';
import { CatalogPage } from '@pages/Catalog/Catalog.page';
import { ConfirmPage } from '@pages/Confirm/Confirm.page';
import { SuccessPage } from '@pages/Success/Success.page';
import { Preloader } from '@components/Preloader/Preloader.component';
import { Redirector } from '@components/Redirector/Redirector.component';
import { useWaitUntilLoaded } from './App.hooks';
import { Helmet } from 'react-helmet';
import { useStoreon } from 'storeon/react';
import { TAppEvents, TAppState } from '@store/index';
import { Localizator } from '@utils/Localizator';
import { useStyles } from './App.style';

export const App = () => {
  const throwError = useAsyncError();
  const { isLoading } = useWaitUntilLoaded({ onError: throwError });
  const { locale, galleryItems, galleryOpenedId } = useStoreon<
    TAppState,
    TAppEvents
  >('locale', 'galleryItems', 'galleryOpenedId');
  const { t } = Localizator.translate();

  const { classes } = useStyles();

  const title = useMemo(() => {
    if (locale) {
      return t('Other.SiteTitle');
    }

    return 'Online order';
  }, [locale, t]);

  if (isLoading) {
    return (
      <div>
        <Preloader />
      </div>
    );
  }

  const galleryEntries = galleryItems.get(galleryOpenedId || '');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* Gallery future items */}
      <div id="__Viewerjs_gallery_images">
        <ul className={classes.gallery}>
          {galleryEntries?.map((item) => {
            return (
              <li key={item}>
                <img src={item} alt="" />
              </li>
            );
          })}
        </ul>
      </div>

      <Routes>
        <Route path="catalog/*" element={<CatalogPage />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="confirm" element={<ConfirmPage />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="*" element={<Redirector />} />
      </Routes>
    </>
  );
};
