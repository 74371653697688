import { useNavigate, useParams } from 'react-router-dom';

export const useNavigateToRoot = () => {
  const navigate = useNavigate();
  const { linkPathPublic } = useParams();
  const { linkPathCounterparty } = useParams();

  const isPublicLink = linkPathPublic !== undefined;
  const linkPath = isPublicLink ? linkPathPublic : linkPathCounterparty;

  const navigateToRoot = () => {
    const path = (isPublicLink ? '/public/' : '/') + linkPath;

    navigate(path);
  };

  return { navigateToRoot };
};
