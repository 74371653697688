import { Container } from '@components/Container/Container.component';
import React from 'react';
import { useStyles } from './ProductCardFooter.style';
import { useIsMobile } from '@hooks/useIsMobile';
import { Typography } from '@components/shared/Typography/Typography.component';
import { NumberUtils } from '@utils/NumberUtils';
import { Label } from '@components/shared/Label/Label.component';
import { TProduct } from '@apptypes/index';
import { PRIMARY_LINK_COLOR } from '@theme/theme';
import { NumberInput } from '@components/shared/NumberInput/NumberInput.component';
import { useNumberInputWithRemains } from '@hooks/useNumberInputsWithRemains';

type Props = {
  product: TProduct;
  currency: string;
  sum: string;
};

export const ProductCardFooter: React.FC<Props> = ({
  product,
  currency,
  sum,
}) => {
  const {
    inputValue,
    handleBlur,
    handleChange,
    handleDecrement,
    handleIncrement,
    handlePaste,
    handleFocus,
    borders,
    preparedProduct,
    showRemainsData,
    isStocksOverflow,
  } = useNumberInputWithRemains({ product });

  const { classes, cx } = useStyles();
  const { isMobile } = useIsMobile();

  return (
    <Container
      onlyMobile
      className={cx(classes.footer, {
        newBootstrap: isMobile,
      })}
    >
      <div>
        <div id="assortment-sidebar-price" className={classes.footerPrice}>
          <Typography fontSize={14} lineHeight="20px" fontWeight={700}>
            <span
              id="assortment-sidebar-price-text"
              title={NumberUtils.getNumberRepresentation(product?.price || 0)}
              className={classes.footerPriceText}
            >
              {NumberUtils.getNumberRepresentation(product?.price || 0)}
            </span>
          </Typography>
          <Label fontSize={12} lineHeight="16px" fontWeight={400}>
            <span
              id="assortment-sidebar-price-uom"
              title={`${currency}${product?.uom ? `/${product?.uom}` : ''}`}
              className={classes.footerPriceText}
            >
              {currency}
              <span data-testid="product-card-page-uom-label">
                {product?.uom ? `/${product?.uom}` : ''}
              </span>
            </span>
          </Label>
        </div>
        {product && product?.quantity > 0 && (
          <div id="assortment-sidebar-footer-sum" className={classes.footerSum}>
            <Label
              fontSize={12}
              lineHeight="16px"
              fontWeight={400}
              color={PRIMARY_LINK_COLOR}
            >
              <span
                title={`${sum} ${currency}`}
                data-testid="product-card-page-sum"
              >
                {sum} {currency}
              </span>
            </Label>
          </div>
        )}
      </div>
      {product && (
        <div
          id="assortment-sidebar-quantity"
          data-testid="product-card-page-with-input"
        >
          <NumberInput
            value={inputValue}
            onIncrement={handleIncrement}
            onDecrement={handleDecrement}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            borders={borders}
            onPaste={handlePaste}
            isAddButtonDisabled={showRemainsData}
            isError={isStocksOverflow}
            title={`${preparedProduct.stock} ${preparedProduct.uom}`}
            showRemainsData={showRemainsData}
            isStocksOverflow={isStocksOverflow}
          />
        </div>
      )}
    </Container>
  );
};
