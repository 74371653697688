import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from '@components/Container/Container.component';
import { SearchHistory } from '../SearchHistory/SearchHistory.component';
import { useStyles } from './SearchModal.style';
import { Localizator } from '@utils/Localizator';

type Props = {
  open: boolean;
  onClose: () => void;
  inputValue: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onSearch: () => void;
  isSearchHistoryOpened: boolean;
  searchHistory: string[];
  handleSearchItemClick: (value: string) => void;
  handleHistoryItemClearIconClick: (value: string) => void;
  onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const SearchModalView: React.FC<Props> = ({
  open,
  onClose,
  inputValue,
  onChange,
  onClear,
  onSearch,
  isSearchHistoryOpened,
  searchHistory,
  handleSearchItemClick,
  handleHistoryItemClearIconClick,
  onEnter,
}) => {
  const [element, setElement] = useState<HTMLInputElement | null>(null);
  const { t } = Localizator.translate();

  useEffect(() => {
    if (element) {
      element.focus();
    }
  }, [element]);

  const { classes, cx } = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      hideBackdrop
      classes={{ root: classes.root }}
    >
      <>
        <Container className="newBootstrap">
          <div className={classes.wrapper}>
            <button onClick={onClose} className={classes.backButton} />
            <input
              className={classes.input}
              type="text"
              placeholder={t('Header.SearchInputPlaceholder') as string}
              value={inputValue}
              ref={setElement}
              onChange={onChange}
              onKeyDown={onEnter}
            />
            {Boolean(inputValue) && (
              <button onClick={onClear} className={classes.close} />
            )}
            <button onClick={onSearch} type="submit" className={classes.button}>
              {t('Header.SearchInputButton')}
            </button>
          </div>

          <div className={cx(classes.wrapper, classes.searchHistoryWrapper)}>
            <SearchHistory
              isOpen={isSearchHistoryOpened}
              onIconClick={handleHistoryItemClearIconClick}
              onItemClick={handleSearchItemClick}
              searchHistory={searchHistory}
            />
          </div>
        </Container>
      </>
    </Modal>
  );
};
