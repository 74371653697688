import { makeStyles } from 'tss-react/mui';
import { BLUE_DEFAULT, BLUE_TYPE_3, WHITE_DEFAULT } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  messageAction: {
    marginTop: 30,
  },

  btn: {
    display: 'inline-block',
    padding: '12px 38px',
    fontWeight: 700,
    fontSize: 14,
    textDecoration: 'none',
    border: 0,
    borderRadius: '8px',
    outline: 0,
    cursor: 'pointer',
    transition: '0.2s ease background-color',

    '@media (max-width: 1279px)': {
      padding: '9px 20px',
      lineHeight: '22px',
    },
  },

  btnPrimary: {
    color: WHITE_DEFAULT,
    backgroundColor: BLUE_DEFAULT,

    '&:focus': {
      backgroundColor: BLUE_TYPE_3,
    },

    '&:hover': {
      backgroundColor: BLUE_TYPE_3,
    },

    '&:active': {
      opacity: 0.8,
    },
  },
}));
