import { makeStyles } from 'tss-react/mui';
import {
  PRIMARY_TEXT_COLOR,
  SECONDARY_BACKGROUND_COLOR,
} from '../../../theme/theme';

type Props = {
  withIcon?: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { withIcon }) => ({
  wrapper: {
    padding: '12px 16px 16px 16px',
    display: 'flex',
    alignItems: 'start',
    borderRadius: '4px',
    background: SECONDARY_BACKGROUND_COLOR,
    width: '100%',
  },

  iconWrapper: {
    width: 16,
    height: 16,
  },

  text: {
    fontFamily: 'ALSHauss,sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    fontFeatureSettings: '"tnum" on, "lnum" on',
    color: PRIMARY_TEXT_COLOR,
    marginLeft: withIcon ? 8 : 0,
  },
}));
