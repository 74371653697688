import React, { FC } from 'react';
import { TitleView } from './Title.view';

type Props = {
  title: string;
  referralLink: string;
  isCart?: boolean;
  withLogo?: boolean;
  subTitle?: string;
};

export const Title: FC<Props> = (props) => {
  const { title, isCart, withLogo, referralLink, subTitle } = props;

  return (
    <TitleView
      title={title}
      isCart={isCart}
      withLogo={withLogo}
      referralLink={referralLink}
      subTitle={subTitle}
    />
  );
};
