import { WHITE_DEFAULT, WHITE_TYPE_1 } from '@theme/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    minWidth: 204,
    maxWidth: 204,
    width: 204,
    display: 'flex',
    justifyContent: 'flex-end',

    '@media (max-width: 1279px)': {
      order: '3',
      width: 'auto',
      minWidth: 'unset',
      maxWidth: 'unset',
    },
  },

  innerWrapper: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 'fit-content',
    borderRadius: 8,
    transition: '0.2s all',
    background: WHITE_DEFAULT,
    cursor: 'pointer',

    '&:hover': {
      background: WHITE_TYPE_1,

      '& .eclipse': {
        border: '2px solid #f5f5f5',
      },
    },

    '@media (max-width: 1279px)': {
      '&:hover': {
        background: 'transparent',

        '& .eclipse': {
          border: '2px solid transparent',
        },
      },
    },
  },

  text: {
    fontFamily: 'Montserrat, sans-serif',
    marginLeft: 12,

    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },
}));
