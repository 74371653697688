import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCallbacks = () => {
  const { handleClick } = useHandleClick();

  return { handleClick };
};

const useHandleClick = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return { handleClick };
};
