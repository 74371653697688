import { makeStyles } from 'tss-react/mui';
import { RED_TYPE_1, WHITE_DEFAULT } from '@theme/theme';

export const useStyles = makeStyles()((theme) => ({
  iconWrapper: {
    position: 'relative',
  },

  eclipse: {
    position: 'absolute',
    top: 0,
    right: -2,
    width: 12,
    height: 12,
    backgroundColor: RED_TYPE_1,
    borderRadius: '100%',
    border: `2px solid ${WHITE_DEFAULT}`,

    '@media (max-width: 1279px)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      position: 'absolute',
      width: 21,
      height: 12,
      left: 12,
      top: -2,
      background: RED_TYPE_1,
      borderRadius: '16px',
      border: 'none',
    },
  },

  eclipseText: {
    margin: 'auto',
    fontFamily: 'Montserrat,serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '8px',
    textAlign: 'center',
    color: '#fff',
  },

  cartImage: {
    maxWidth: 'none',
  },
}));
