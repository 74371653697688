import { useStoreon } from 'storeon/react';
import { useGetCategories, useGetCurrency, useGetLinkInfo } from '@hooks/useApi.hooks';
import { store, TAppEvents, TAppState } from '@store/index';
import { SET_ALL_CATEGORIES } from '@store/categories.store';
import { usePrefetchLocale } from '@hooks/usePrefetch.hooks';
import { SET_CURRENCY } from '@store/currency.store';

type Props = {
  onError: (e: any) => void;
};

export const useWaitUntilLoaded = ({ onError }: Props) => {
  const { dispatch } = useStoreon<TAppState, TAppEvents>();

  const {
    query: { isFetching: isLocaleFetching },
  } = usePrefetchLocale<typeof store>(store, true);

  const {
    query: { isFetching: isGetUserLinkInfoFetching },
  } = useGetLinkInfo({
    enabled: !isLocaleFetching,
    onError: (err) => {
      onError(err);
    },
  });

  const {
    query: { isFetching: isGetCategoriesFetching },
  } = useGetCategories({
    enabled: !isLocaleFetching,
    onSuccess: (response) => {
      const withRemovedNullCategories = response?.filter(
        (item) => item.id !== '00000000-0000-0000-0000-000000000000',
      );

      dispatch(SET_ALL_CATEGORIES, withRemovedNullCategories);
    },
    onError: (e) => {
      onError(e);
    },
  });

  const {
    query: { isFetching: isGetCurrencyFetching },
  } = useGetCurrency({
    enabled: !isLocaleFetching,
    onSuccess: (response) => {
      dispatch(SET_CURRENCY, response);
    },
    onError: (e) => {
      onError(e);
    },

  });

  const isLoading =
    isGetUserLinkInfoFetching || isGetCategoriesFetching || isLocaleFetching || isGetCurrencyFetching;

  return { isLoading };
};
