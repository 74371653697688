import { NumberUtils } from '@utils/NumberUtils';
import { Localizator } from '@utils/Localizator';

export const buildTextFor = (totalPositionsCount: number) => {
  const { t } = Localizator.translate();

  if (totalPositionsCount == 0) {
    return t('Header.Cart.BottomTextNoItems');
  }

  const value = NumberUtils.getNumberRepresentation(totalPositionsCount, 0);

  const declination = t('Header.Cart.ItemsPlural', {
    count: totalPositionsCount,
  });

  return `${value} ${declination}`;
};
