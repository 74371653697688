import React from 'react';
import { useStyles } from './SearchHistory.style';
import { SearchHistoryItem } from './SearchHistoryItem/SearchHistoryItem.component';

type Props = {
  reversed: string[];
  onIconClick: (item: string) => void;
  onItemClick: (item: string) => void;
};

export const SearchHistoryView: React.FC<Props> = ({
  reversed,
  onIconClick,
  onItemClick,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      {reversed.map((item) => {
        return (
          <SearchHistoryItem
            onIconClick={onIconClick}
            onItemClick={onItemClick}
            key={item}
            item={item}
          />
        );
      })}
    </div>
  );
};
