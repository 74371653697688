import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigateToRoot } from '@hooks/useNavigateToRoot';

export const useCallbacks = () => {
  const { state } = useGetState();

  return { state };
};

const useGetState = () => {
  const location = useLocation();

  const { navigateToRoot } = useNavigateToRoot();

  const state = location.state as {
    name: string;
    sum: number;
  } | null;

  useEffect(() => {
    if (state === null) {
      navigateToRoot();
    }
  }, [state, navigateToRoot]);

  return { state };
};
